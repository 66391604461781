import { useRoute, ref, useContext } from '@nuxtjs/composition-api'
import menuItem from '@/components/homeHeader/components/menuItem.vue'
import { homeBarMenus } from '@/utils/menu'
import hot from '@/assets/img/hot.gif'

export default {
  __name: 'menuBar',
  setup(__props) {

const context = useContext()
const { $config } = context
const route = useRoute()

const wz = homeBarMenus.find((_) => _.name == '循环物资')
if (wz) {
  wz.link = $config.wzUrl
}
const routePath = ref(homeBarMenus)

return { __sfc: true,context, $config, route, wz, routePath, menuItem, hot }
}

}