import ruleTemplate from '@/components/helpcenter/ruleTemplate.vue'

export default {
  __name: 'c2mRule',
  setup(__props) {


return { __sfc: true,ruleTemplate }
}

}