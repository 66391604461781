
import { defineComponent, reactive, watch, useRoute, onMounted, computed, useStore, useContext, toRefs } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import searchBarItem from '~/components/spotMall/searchBar/searchBarItem.vue'
import searchTag from '~/components/spotMall/searchBar/searchTag.vue'
import searchRange from '~/components/spotMall/searchBar/searchRange.vue'
import selectItem from '~/components/basic/SelectItem.vue'

export default defineComponent({
  components: {
    searchBarItem,
    searchTag,
    searchRange,
    selectItem
  },
  props: {
    condition: {
      type: Object,
      default: () => {}
    },
    isOther: {
      type: Boolean,
      default: true
    },
    otherTypes: {
      type: Array,
      default: () => ['质量信息', '尺寸信息', '计量方式', '是否特价', '资源类型', '入库日期']
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const store = useStore()
    const route = useRoute()
    const { userInfo } = useLoginInfo()
    const { replace } = useLink()
    const state = reactive({
      isMore: '',
      searchCategoryId: false,
      searchOrigin: false,
      searchMaterial: false,
      searchSpec: false,
      initMaterial: computed(() => store.getters.material),
      initRepoDtos: computed(() => store.getters.condition.repositorySimpleDtos),
      warehouseDate: ''
    })
    const getInitSearchForm = () => {
      return {
        variety: '',
        purpose: '',
        categoryId: '',
        material: '',
        quality: '',
        repositoryId: '',
        origin: '',
        deliveryPlace: '',
        spec: '',
        widthStart: '',
        widthEnd: '',
        lengthStart: '',
        lengthEnd: '',
        thicknessStart: '',
        thicknessEnd: '',
        measure: '',
        qualityInfo: '',
        sizeInfo: '',
        specialPrice: '',
        invoiceList: '',
        type: '',
        sortName: '',
        sortType: '',
        repositoryName: '',
        materialLike: '',
        designation: '',
        sv: '',
        sk: '',
        sr: '',
        keyWord: '',
        matchList: '',
        listedPriceStart: '',
        listedPriceEnd: '',
        warehouseDateEnd: '',
        warehouseDateStart: ''
      }
    }

    const searchForm = reactive(getInitSearchForm())

    const searchItems = reactive({
      varieties: computed(() => store.getters.varieties),
      category: computed(() => store.getters.categories),
      materialList: computed(() => store.getters.material),
      oiginDescs: computed(() => store.getters.oiginDescs), // 产地
      repositorySimpleDtos: computed(() => store.getters.repoDtos), // 仓库
      deliveryPlaces: computed(() => store.getters.deliPlaces), // 交货地
      measure: computed(() => store.getters.measure),
      qualityInfo: computed(() => store.getters.qualityInfo),
      sizeInfo: computed(() => store.getters.sizeInfo),
      specialPrice: computed(() => store.getters.specialPrice),
      source: computed(() => store.getters.source),
      types: computed(() => store.getters.types),
      purpose: computed(() => store.getters.purpose)
    })

    const getFilterQualityInfo = (data) => {
      const item = data
      const array = ['正品', '分割正品', '船板', '分割船板']
      return searchForm.purpose ? item.filter((res) => array.includes(res.value)) : item
    }

    const showMore = () => {
      state.isMore = state.isMore ? '' : '1'
    }

    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }

    const changeBarItem = (refresh = false) => {
      const params = transParams(searchForm)
      delete params.orderFields
      params.page = 1
      replace(route.value.path, { ...params })
      emit('change', { ...params })
      if (refresh) {
        emit('refresh-search')
      }
    }
    const changeVariety = () => {
      changeBarItem(true)
    }
    const changeCategory = () => {
      changeBarItem()
    }
    const changeMaterial = (data) => {
      searchForm.materialLike = ''
      searchForm.material = data
      changeBarItem(true)
    }
    const changeRepository = (data) => {
      searchForm.repositoryId = data
      searchForm.repositoryName = ''
      changeBarItem(true)
    }
    const changeDeliveryPlace = () => {
      changeBarItem()
      filterRepoDtos()
    }

    const changeOrigin = () => {
      changeBarItem(true)
    }

    const changePurpose = () => {
      searchForm.qualityInfo = ''
      changeBarItem(true)
    }

    const filterRepoDtos = () => {
      const deliveryPlace = searchForm.deliveryPlace.split(',')
      const fitRepoDtos = []
      state.initRepoDtos?.forEach((dtos) => {
        if (deliveryPlace.includes(dtos.regionCode)) {
          fitRepoDtos.push(dtos)
        }
      })
      store.commit('spotMall/SET_REPODTOS', fitRepoDtos.length ? fitRepoDtos : state.initRepoDtos)
    }

    const handleInitRepoDtos = () => {
      store.commit('spotMall/SET_REPODTOS', state.initRepoDtos)
    }

    const likeSearch = ({ searchKey, value, labelKey }) => {
      searchForm[searchKey] = value
      if (labelKey) searchForm[labelKey] = ''
      changeBarItem(true)
    }

    watch(
      () => [searchForm.deliveryPlace],
      ([newDeliveryPlace]) => {
        if (newDeliveryPlace) {
          filterRepoDtos()
        } else {
          handleInitRepoDtos()
        }
      }
    )

    watch(
      () => state.initRepoDtos,
      (newVal) => {
        filterRepoDtos()
      }
    )

    const hanleSearchMatchList = () => {
      const { matchList = '' } = props.condition
      if (matchList) {
        const _matchList = JSON.parse(matchList)
        const { categoryId = '', origin = '', material = '', spec = '' } = _matchList
        state.searchCategoryId = !!categoryId
        state.searchOrigin = !!origin
        state.searchMaterial = !!material
        state.searchSpec = !!spec
      } else {
        state.searchCategoryId = false
        state.searchOrigin = false
        state.searchMaterial = false
        state.searchSpec = false
      }
    }

    const changeSpec = (obj) => {
      Object.assign(searchForm, getInitSearchForm(), { ...obj })
      changeBarItem(true)
    }

    const handleTagChange = (obj) => {
      Object.assign(searchForm, getInitSearchForm(), { ...obj })
      changeBarItem(true)
    }
    watch(
      () => props.condition,
      (newQuery) => {
        Object.assign(searchForm, getInitSearchForm(), { ...newQuery })
        const { warehouseDateStart = '', warehouseDateEnd = '' } = searchForm
        state.warehouseDate = [warehouseDateStart, warehouseDateEnd]
        hanleSearchMatchList()
      },
      { deep: true }
    )
    watch(
      () => state.warehouseDate,
      (value) => {
        const [warehouseDateStart = '', warehouseDateEnd = ''] = value ?? []
        Object.assign(searchForm, {
          warehouseDateStart,
          warehouseDateEnd
        })
      }
    )
    const handleDateChange = () => {
      if (!state.warehouseDate) {
        Object.assign(searchForm, {
          warehouseDateStart: '',
          warehouseDateEnd: ''
        })
      }
      changeBarItem()
    }

    onMounted(() => {
      Object.assign(searchForm, getInitSearchForm(), { ...props.condition })
      const { warehouseDateStart = '', warehouseDateEnd = '' } = searchForm
      state.warehouseDate = [warehouseDateStart, warehouseDateEnd]
      hanleSearchMatchList()
    })
    return {
      ...toRefs(state),
      searchItems,
      searchForm,
      changeBarItem,
      userInfo,
      showMore,
      changeVariety,
      changeCategory,
      changeMaterial,
      changeRepository,
      changeDeliveryPlace,
      changeOrigin,
      likeSearch,
      handleTagChange,
      handleDateChange,
      changeSpec,
      changePurpose,
      getFilterQualityInfo
    }
  }
})
