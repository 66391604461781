import { onMounted, computed } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { constantCorpMenus } from '@/utils/menu'


export default {
  __name: 'headerCorpInfo',
  props: {
  nowPath: {
    type: String,
    default: ''
  }
},
  emits: 'change-corp',
  setup(__props, { emit }) {

const props = __props;



const { userInfo, loginCorpInfo, dropMenus } = useLoginInfo()
const { push } = useLink()
const { showErrorTip } = useMsgDialog()

const corpName = computed(() => {
  return loginCorpInfo.value?.corpName || ''
})

const corpMenus = computed(() => {
  const menus = constantCorpMenus
  const accountMenus = dropMenus.value.find((_) => _.name == '账号中心')?.children || []
  const moneyMenus = dropMenus.value.find((_) => _.name == '资金中心')?.children || []
  for (const child of accountMenus) {
    for (const child1 of child.children) {
      menus.forEach((menu) => {
        if (menu.name == child1.name) {
          menu.isAuth = true
          menu.childrenPaths = [menu.path]
        }
      })
    }
  }
  if (moneyMenus.length) {
    menus[2] = {
      name: '资金中心',
      path: moneyMenus[0].path,
      icon: 'icon-menu-money',
      isAuth: true,
      childrenPaths: moneyMenus[0].children.map((_) => _.path)
    }
  }
  return menus
})

const handleCorpMenu = (item) => {
  if (!item.isAuth) {
    return showErrorTip('', 1)
  } else {
    push(item.path)
  }
}



const showCorpInfo = () => {
  emit('change-corp')
}

onMounted(() => {})

return { __sfc: true,props, userInfo, loginCorpInfo, dropMenus, push, showErrorTip, corpName, corpMenus, handleCorpMenu, emit, showCorpInfo }
}

}