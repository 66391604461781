
import { defineComponent, onMounted, useContext, computed, useRoute, ref } from '@nuxtjs/composition-api'
import { HOT_LINE } from '@/constant'
import { useLink } from '@/hooks/useLink'
import orderNumberDisplay from '~/components/shopCashier/orderNumberDisplay.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
export default defineComponent({
  components: {
    orderNumberDisplay
  },
  props: {},
  setup(props, { emit }) {
    const context = useContext()
    const route = useRoute()
    const orderNumbers = ref([])
    const orderNo = computed(() => route.value.query.orderNo?.split(','))
    const orderType = computed(() => route.value.query.orderType)
    const payNumber = route.value.query.payNumber
    const { replace } = useLink()
    const payInfo = ref(null)
    const getPayInfo = async () => {
      const { data, statusCode } = await context.$api.pay.getPayInfo({ payNumber })
      if (statusCode == 200) {
        if (data) {
          payInfo.value = data
          if (orderType.value == 0 || orderType.value == 2 || orderType.value == 8) {
            orderNumbers.value = data.orderNos
          }
        } else {
          payInfo.value = {
            payStatus: 5
          }
        }
      }
    }

    const repay = () => {
      replace(`/shop/cashier?orderNo=${orderNo.value?.join(',')}&orderType=${orderType.value}`)
    }

    const getOrderInfo = async () => {
      const { data, statusCode } = await context.$api.pay.getOrderInfo({
        orderNos: orderNo.value,
        orderType: orderType.value
      })
      if (statusCode == 200) {
        if (orderType.value == 0) {
          orderNumbers.value = data.map((res) => res.orderNo)
        } else if (orderType.value == 2 || orderType.value == 8) {
          orderNumbers.value = data.map((res) => res.abstractGoodsId)
        }
      }
    }

    const { loginCorpId } = useLoginInfo()

    onMounted(() => {
      if (loginCorpId.value) {
        getPayInfo()
      }
      // if (orderType.value == 0 || orderType.value == 2 || orderType.value == 8) {
      //   getOrderInfo()
      // }
    })
    return {
      HOT_LINE,
      orderNo,
      replace,
      repay,
      payInfo,
      orderType,
      orderNumbers
    }
  }
})
