
import { computed, defineComponent, useStore } from '@nuxtjs/composition-api'
import { formatNumber } from '@/utils/tool'

export default defineComponent({
  props: {
    orderInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const store = useStore()

    const spotOrderPayTime = computed(() => store.getters.transactionParams?.entity?.spotOrderPayTime)
    const penaltyMoney = computed(() => store.getters.transactionParams?.entity?.penaltyMoney)
    const deliveryTolerance = computed(() => store.getters.transactionParams?.entity?.deliveryTolerance)
    const taxRate = computed(() => store.getters.transactionParams?.entity?.taxRate)
    return {
      spotOrderPayTime,
      penaltyMoney,
      deliveryTolerance,
      taxRate,
      formatNumber
    }
  }
})
