
import { defineComponent, useStore, onUnmounted } from '@nuxtjs/composition-api'
import cartHeader from '~/components/homeHeader/cartHeader.vue'

export default defineComponent({
  components: {
    cartHeader
  },
  setup() {
    const store = useStore()
    onUnmounted(() => {
      store.commit('loginDialog/SET_DIALOGVISIBLE', false)
      store.commit('tipMsgDialog/SET_TIPMSGDIALOGVISIBLE', false)
    })
  }
})
