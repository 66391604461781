
import { defineComponent, reactive, ref, useContext, watch } from '@nuxtjs/composition-api'
export default defineComponent({
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: Array,
    showLevel: {
      type: Number,
      validator: (val) => {
        return [0, 1, 2].includes(val)
      },
      default: 2
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const region = ref([])
    const cascaderProps = reactive({
      lazy: true,
      async lazyLoad(node, resolve) {
        const { level } = node
        const regionType = level + 1 + ''
        const { data = [] } = await context.$api.region.getRegion(
          level >= 1
            ? {
                regionType,
                father: node.data.regionCode
              }
            : {
                regionType
              }
        )
        const nodes = data.map((res) => {
          return {
            regionCode: res.regionCode,
            value: res.regionName,
            label: res.regionName,
            leaf: props.showLevel == level && level <= 2
          }
        })
        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        resolve(nodes)
      }
    })
    const change = () => {
      emit('change', region.value)
    }
    watch(
      () => props.value,
      () => {
        region.value = props.value
      },
      { immediate: true }
    )
    return {
      region,
      cascaderProps,
      change
    }
  }
})
