
import { defineComponent, ref, useContext, inject, computed, useStore, watch, reactive, toRefs, useRoute, onMounted } from '@nuxtjs/composition-api'
import { Loading } from 'element-ui'
import { useLink } from '@/hooks/useLink'
import { payChannelMap, orderTypeMap } from '@/constant/orders'
import { daojishi } from '@/utils/daojishi'
import { openBankUrl } from '@/utils/openBankUrl'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { toFixed } from '@/utils/tool'
import balancePay from '~/components/shopCashier/balancePay.vue'
import internetBank from '~/components/shopCashier/internetBank.vue'
import orderNumberDisplay from '~/components/shopCashier/orderNumberDisplay.vue'
import { useSyncTime } from '~/hooks/useSyncTime'

export default defineComponent({
  components: {
    balancePay,
    internetBank,
    orderNumberDisplay
  },
  props: {},
  setup(props, { emit }) {
    const context = useContext()
    const store = useStore()
    const route = useRoute()
    const $message = inject('$message')
    const { showErrorTip } = useMsgDialog()
    const orderNo = computed(() => route.value.query.orderNo?.split(','))
    const orderType = computed(() => route.value.query.orderType)
    const payMoney = ref(0)
    const payMode = computed(() => route.value.query.payMode)
    const currentView = ref(payMode.value == 'P02' ? 'internetBank' : 'balancePay')
    const orderInfos = ref([])
    const orderNumbers = ref([])
    const state = reactive({
      account: '',
      transSeqNo: '',
      menuList: [],
      lastCount: 0,
      orderExpireTime: null,
      collaps: false
    })

    const { currentTime } = useSyncTime()
    const setTimer = () => {
      const nowTime = new Date(currentTime.value).getTime() // 获取当前时间
      const stopTime = new Date(state.orderExpireTime.replace(/-/g, '/')).getTime() // 获取截止时间
      if (stopTime - nowTime > 0) {
        state.lastCount = stopTime - nowTime //   获取截止时间距离现在的毫秒差
      } else {
        state.lastCount = 0
      }
    }
    watch(
      () => currentTime.value,
      () => {
        if ([3, 4, 5].includes(+orderType.value) && state.orderExpireTime) {
          setTimer()
        }
      }
    )

    const orderTypeName = computed(() => {
      let str
      orderTypeMap.forEach((value, key) => {
        if (value == orderType.value) {
          str = key
        }
      })
      return str
    })
    const { replace, back } = useLink()
    const componentRef = ref(null)
    const payOrder = async (params) => {
      if ([23, 24, 25].includes(+orderType.value)) {
        params.payChannel = payChannelMap.get('加工')
      }
      const { next, ...tempParams } = params

      const { data, statusCode, message } = await context.$api.pay.payOrder(tempParams)
      if ([payChannelMap.get('余额支付'), payChannelMap.get('加工')].includes(params.payChannel)) {
        next()
      }
      if (statusCode == 200) {
        if ([payChannelMap.get('余额支付'), payChannelMap.get('加工')].includes(params.payChannel)) {
          if ([3, 4, 5, 23].includes(+orderType.value)) {
            await store.dispatch('app/GET_USERINFO', context)
            await store.dispatch('app/GET_PERMISSION', context)
          }
          replace('/shop/cashier', {
            orderNo: orderNo.value?.join(','),
            orderType: orderType.value,
            payNumber: data.payNumber
          })
        } else if (componentRef.value.payDialogRef) {
          componentRef.value.payDialogRef.dialogVisible = false
          componentRef.value.payDialogRef.tipDialogVisible = true
          componentRef.value.payDialogRef.payNumber = data.payNumber
          openBankUrl(data.frontUrl, data.signData)
        }
      } else if (statusCode == 30011 || statusCode == 30010 || statusCode == 30020) {
        // 30011:资金账户冻结
        showErrorTip(message)
      } else if (statusCode == 30012) {
        $message.error(message)
        back()
      } else if (statusCode == 30013) {
        replace('/shop/cashier', {
          orderNo: orderNo.value?.join(','),
          orderType: orderType.value,
          payError: true
        })
      } else if (statusCode == 50040) {
        $message.error(message)
        if (+orderType.value == 24) {
          replace('/buyercenter/c2mOrder')
        } else if (+orderType.value == 25) {
          replace('/processcenter/order')
        } else {
          replace('/buyercenter/purchaseOrder')
        }
      } else {
        $message.error(message)
      }
    }

    const orderTitle = computed(() => {
      let str
      orderTypeMap.forEach((value, key) => {
        if (value == orderType.value) {
          str = `${orderNo.value}的${key}`
        }
      })
      return str
    })

    const loading = ref(null)
    const getPayChannel = async (channel) => {
      if (channel != 'P01') {
        loading.value = Loading.service({
          lock: false,
          background: 'rgba(0,0,0,0.7)'
        })
      }
      try {
        const { data, statusCode } = await context.$api.pay.getPayChannel({
          orderList: orderNo.value,
          orderType: orderType.value,
          totalAmount: payMoney.value,
          orderTitle: orderTitle.value,
          channel
        })
        if (channel != 'P01') {
          loading.value.close()
        }
        if (statusCode == 200) {
          const { P01, P02 } = data
          state.account = P01 ?? 0
          state.menuList = P02?.tecPayBankEntities
          state.transSeqNo = P02?.transSeqNo
        }
      } catch (err) {
        loading.value.close()
      }
    }
    const changePayMethod = (compName, channel) => {
      currentView.value = compName
      replace('/shop/cashier', {
        ...route.value.query,
        payMode: channel
      })
      getPayChannel(channel)
    }

    const getOrderInfo = async () => {
      const { data, statusCode } = await context.$api.pay.getOrderInfo({
        orderNos: orderNo.value,
        orderType: orderType.value
      })
      if (statusCode == 200) {
        payMoney.value =
          toFixed(
            data.reduce(
              (total, cur) => (total += orderType.value == 0 ? parseFloat(toFixed(cur.payAmount)) : parseFloat(toFixed(cur.totalAmount))),
              0
            )
          ) * 1
        orderInfos.value = data
        if (orderType.value == 0) {
          orderNumbers.value = data.map((res) => res.orderNo)
        } else if ([2, 8].includes(+orderType.value)) {
          orderNumbers.value = data.map((res) => res.abstractGoodsId)
        } else if ([3, 4, 5, 23].includes(+orderType.value)) {
          if (data.length == 1) {
            state.orderExpireTime = data[0].orderExpireTime
            setTimer()
          }
        }
      } else if (statusCode == 50040) {
        replace('/buyercenter/purchaseOrder')
      }
    }
    onMounted(async () => {
      await getOrderInfo()
      getPayChannel(payMode.value ?? 'P01')
    })

    return {
      currentView,
      payMoney,
      orderNo,
      orderType,
      payOrder,
      componentRef,
      orderTypeName,
      daojishi,
      ...toRefs(state),
      changePayMethod,
      getPayChannel,
      orderNumbers
    }
  }
})
