import { onMounted, reactive, toRefs, useContext } from '@nuxtjs/composition-api'
import { potocolTypeMap } from '@/constant/potocol'


export default {
  __name: 'ruleTemplate',
  props: {
  potocolType: String
},
  setup(__props, { expose }) {

const props = __props;


const context = useContext()
const state = reactive({
  detail: {}
})

const getPotocolAsync = async () => {
  const { data, statusCode } = await context.$api.potocol.getPotocol({ type: props.potocolType })
  if (statusCode == 200) {
    state.dialogVisible = true
    state.detail = data
  }
}
onMounted(() => {
  getPotocolAsync()
})

expose({
  ...toRefs(state)
})

return { __sfc: true,props, context, state, getPotocolAsync, potocolTypeMap }
}

}