import { useLink } from '@/hooks/useLink'


export default {
  __name: 'menuItem',
  props: {
  item: {
    type: Object
  }
},
  setup(__props) {

const props = __props;


const { push, pushBcs } = useLink()

return { __sfc: true,props, push, pushBcs }
}

}