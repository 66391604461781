import { useContext } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLogout } from '@/hooks/useLogout'
import { getParams } from '@/utils/sso'

export default {
  __name: 'loginInfo',
  setup(__props) {

const context = useContext()
const { logoutAsync } = useLogout()
const { userInfo, loginCorpId, loginCorpInfo } = useLoginInfo()

const { $config } = context
const gotoLogin = () => {
  window.location.href = `${$config.ssoUrl}/login${getParams()}`
}
const gotoRegister = () => {
  window.location.href = `${$config.ssoUrl}/register${getParams()}`
}

return { __sfc: true,context, logoutAsync, userInfo, loginCorpId, loginCorpInfo, $config, gotoLogin, gotoRegister }
}

}