import { render, staticRenderFns } from "./PortalHeader.vue?vue&type=template&id=11363f05"
import script from "./PortalHeader.vue?vue&type=script&setup=true&lang=js"
export * from "./PortalHeader.vue?vue&type=script&setup=true&lang=js"
import style0 from "./PortalHeader.vue?vue&type=style&index=0&id=11363f05&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GbScrollTool: require('/usr/src/app/components/common/ScrollTool.vue').default,GbLoginDialog: require('/usr/src/app/components/common/LoginDialog.vue').default})
