import { computed, useRoute } from '@nuxtjs/composition-api'


export default {
  __name: '_type',
  setup(__props) {

const typeMap = new Map([
  ['regist', '注册'],
  ['auth', '会员认证'],
  ['buy', '购买商品'],
  ['pack', '打包竞价流程'],
  ['single', '单条竞价流程'],
  ['delivery', '预约提货'],
  ['invoice', '查看发票'],
  ['afterSale', '售后服务'],
  ['demand', '加工需求'],
  ['order', '加工订单'],
  ['applyC2M', '加工预约'],
  ['deliveryC2M', '加工实提']
])
const route = useRoute()
const paramsType = computed(() => route.value.params.type)

return { __sfc: true,typeMap, route, paramsType }
}

}