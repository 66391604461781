
import { defineComponent } from '@nuxtjs/composition-api'
import c2mHeader from '@/components/c2m/layouts/c2mHeader.vue'
import c2mFooter from '@/components/c2m/layouts/c2mfooter.vue'
import navSearchBar from '@/components/homeHeader/navSearchBar.vue'
export default defineComponent({
  components: {
    c2mHeader,
    // navSearchBar,
    c2mFooter
  },
  setup() {}
})
