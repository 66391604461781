import { onMounted, reactive, ref, useContext } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { noticeTypeTagMap } from '@/constant/home'
import { useMsgDialog } from '@/hooks/useMsgDialog'

export default {
  __name: 'notice',
  setup(__props) {

const context = useContext()
const state = reactive({
  page: {
    limit: 6,
    page: 1
  }
})
const noticeList = ref([])
const getListAsync = async () => {
  const { data, statusCode } = await context.$api.home.getNoticeList({ ...state.page })
  if (statusCode == 200) {
    const { rows = [] } = data
    rows.forEach((row) => {
      row.newTag = handleIsNew(row.pushTime)
    })
    noticeList.value = rows
  }
}

const handleIsNew = (time) => {
  const todayDate = new Date().setHours(0, 0, 0, 0)
  const paramsDate = new Date(time).setHours(0, 0, 0, 0)
  return todayDate === paramsDate
}

const { push } = useLink()
const { showErrorTip } = useMsgDialog()
const handleNotice = async (item = '') => {
  if (!item) {
    push('/notice')
  } else {
    const { data, statusCode, message } = await context.$api.home.getNoticeDeatil({ id: item.id })
    if (statusCode == 200) {
      push(`/notice/${item.id}`)
    } else {
      showErrorTip(message)
      getListAsync()
    }
  }
}

onMounted(() => {
  getListAsync()
})

return { __sfc: true,context, state, noticeList, getListAsync, handleIsNew, push, showErrorTip, handleNotice, noticeTypeTagMap }
}

}