
import { defineComponent, onMounted, ref, computed, watch, useRoute } from '@nuxtjs/composition-api'
import inputRange from '@/components/basic/InputRange.vue'
import { useLink } from '@/hooks/useLink'

export default defineComponent({
  components: {
    inputRange
  },
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { replace } = useLink()
    const route = useRoute()
    const urlQuery = computed(() => route.value.query)
    const searchForm = ref({
      thickness: [],
      width: [],
      length: [],
      spec: ''
    })
    const rangeSize = () => {
      searchForm.value.thickness = []
      searchForm.value.width = []
      searchForm.value.length = []
    }
    const changeSize = () => {
      searchForm.value.spec = ''
    }

    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }

    const doSearch = () => {
      const [lengthStart = '', lengthEnd = ''] = searchForm.value.length
      const [widthStart = '', widthEnd = ''] = searchForm.value.width
      const [thicknessStart = '', thicknessEnd = ''] = searchForm.value.thickness
      const tempParam = {
        ...urlQuery.value,
        lengthStart,
        lengthEnd,
        widthStart,
        widthEnd,
        thicknessStart,
        thicknessEnd,
        spec: searchForm.value.spec
      }
      const params = transParams(tempParam)
      params.page = 1
      replace(route.value.path, { ...params })
      emit('change', { ...params })
    }
    const getQueryInit = (data) => {
      const { spec = '', thicknessStart = '', thicknessEnd = '', widthStart = '', widthEnd = '', lengthStart = '', lengthEnd = '' } = data
      searchForm.value.spec = spec ?? ''
      searchForm.value.thickness = thicknessStart || thicknessEnd ? [thicknessStart, thicknessEnd] : []
      searchForm.value.width = widthStart || widthEnd ? [widthStart, widthEnd] : []
      searchForm.value.length = lengthStart || lengthEnd ? [lengthStart, lengthEnd] : []
    }
    watch(
      () => urlQuery.value,
      (newVal) => {
        getQueryInit(newVal)
      }
    )
    onMounted(() => {
      getQueryInit(urlQuery.value)
    })
    return {
      searchForm,
      rangeSize,
      changeSize,
      doSearch
    }
  }
})
