
import { defineComponent, ref, useContext, reactive, toRefs } from '@nuxtjs/composition-api'
import warrantyTag from './warrantyTag.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import attributeTable from '~/components/spotMall/attributeTable.vue'
import qualityAssure from '~/components/spotMall/qualityAssure.vue'

export default defineComponent({
  components: {
    attributeTable,
    qualityAssure,
    warrantyTag
  },
  props: {
    isBid: {
      type: Boolean,
      default: false
    },
    goodsInfo: {
      type: Object,
      default: () => {}
    },
    isEmit: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const { userInfo } = useLoginInfo()
    const { judgeIsLogin } = useMsgDialog()

    const state = reactive({
      warranty: ''
    })
    const showInfo = (row) => {
      if (!props.isEmit) {
        window.open(`/shop/goods?id=${encodeURI(row.resourceId)}`, '_blank')
      } else {
        emit('toLink', props.goodsInfo)
      }
    }
    const qualityAssureRef = ref(null)

    const handleQuality = (data) => {
      state.warrantyUrl = data ?? ''
      qualityAssureRef.value.dialogVisible = true
    }
    return {
      showInfo,
      qualityAssureRef,
      userInfo,
      ...toRefs(state),
      judgeIsLogin,
      handleQuality
    }
  }
})
