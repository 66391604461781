import { ref } from '@nuxtjs/composition-api'


export default {
  __name: 'internetbank',
  setup(__props) {

const inforMation = ref([
  { name: '收款人: ', content: '江苏金贸钢宝电子商务股份有限公司' },
  { name: '公司开户行: ', content: '中国银行股份有限公司南京南钢支行' },
  { name: '银行账号: ', content: ' 474165924053' },
  { name: '联行号: ', content: '104301004629' }
])

return { __sfc: true,inforMation }
}

}