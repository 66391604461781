import { reactive, watch, useContext } from '@nuxtjs/composition-api'

export default {
  __name: 'c2mBuyer',
  props: {
  orderNo: {
    type: String
  }
},
  setup(__props) {

const props = __props;


const context = useContext()
const detail = reactive({
  amountAllWithTax: 0,
  buyerAccount: '',
  buyerAddress: '',
  buyerBank: '',
  buyerConcat: '',
  buyerCreditCode: '',
  buyerId: '',
  buyerLegalPerson: '',
  buyerMobile: '',
  buyerName: '',
  contractNo: '',
  cuttingName: '',
  processDetailUrl: '',
  processRequirement: '',
  processRequirementUrl: '',
  processTime: '',
  sellerAccount: '',
  sellerAddress: '',
  sellerBank: '',
  sellerConcat: '',
  sellerCreditCode: '',
  sellerId: '',
  sellerLegalPerson: '',
  sellerMobile: '',
  sellerName: '',
  settlementMethod: '',
  signDate: '',
  taxRate: 0
})
const getContractAsync = async (orderNo) => {
  const { statusCode, data } = await context.$api.c2m.getC2MBuyerContract({
    orderNo
  })
  if (statusCode == 200) {
    Object.assign(detail, data)
  }
}

watch(
  () => props.orderNo,
  (newVal) => {
    if (newVal) {
      getContractAsync(newVal)
    }
  },
  { immediate: true }
)

return { __sfc: true,props, context, detail, getContractAsync }
}

}