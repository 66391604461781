import { reactive, watch, useContext } from '@nuxtjs/composition-api'
import { formatDate, formatNumber, formatMoney } from '@/utils/tool'

export default {
  __name: 'saleFactory',
  props: {
  orderNo: {
    type: String
  }
},
  setup(__props) {

const props = __props;



const detail = reactive({
  amountAllWithTax: 0,
  breakContractDay: '',
  buySellProductDtos: [],
  buyerAccount: '',
  buyerAddress: '',
  buyerBank: '',
  buyerConcat: '',
  buyerCreditCode: '',
  buyerId: '',
  buyerLegalPerson: '',
  buyerMobile: '',
  buyerName: '',
  buyerPostcode: '',
  consignee: '',
  consigneePhone: '',
  contractAllNum: 0,
  contractBuyerNum: 0,
  contractNo: '',
  contractSellerNum: 0,
  deliveryMethod: '',
  deliveryPlace: '',
  goodsUnitPriceSum: 0,
  includingFreight: '',
  invoiceGetDay: '',
  invoiceGetMethod: '',
  prePayDay: '',
  prepaymentRatio: '',
  prepaymentTerm: '',
  priceType: '',
  pricingValidityEnd: '',
  pricingValidityStart: '',
  processRequirement: '',
  processRequirementUrl: '',
  processingCycle: '',
  productDetailUrl: '',
  qualityObjectionHandling: '',
  quantityDifferenceMethod: '',
  quantitySum: 0,
  remark: '',
  sellerAccount: '',
  sellerAddress: '',
  sellerBank: '',
  sellerConcat: '',
  sellerCreditCode: '',
  sellerId: '',
  sellerLegalPerson: '',
  sellerMobile: '',
  sellerName: '',
  settleBalanceTime: '',
  settleOverdueDay: '',
  settlePayMethod: '',
  settlementMethod: '',
  signDate: '',
  taxRate: 0,
  taxSum: 0,
  totalAmountNoTaxSum: 0,
  totalAmountSum: 0,
  weightSum: 0
})

const context = useContext()
const getContractAsync = async (orderNo) => {
  const { statusCode, data } = await context.$api.c2m.getSaleFactoryContract({
    orderNo
  })
  if (statusCode == 200) {
    Object.assign(detail, data)
  }
}

watch(
  () => props.orderNo,
  (newVal) => {
    if (newVal) {
      getContractAsync(newVal)
    }
  },
  { immediate: true }
)

return { __sfc: true,props, detail, context, getContractAsync, formatDate, formatNumber, formatMoney }
}

}