
import { defineComponent, ref, useContext, reactive, toRefs, useStore } from '@nuxtjs/composition-api'
import { phone, phoneReg } from '@/utils/validate'
import { useLink } from '@/hooks/useLink'

export default defineComponent({
  props: {
    bankObj: {
      type: Object,
      default: null
    },
    payMoney: {
      type: [Number, String],
      default: ''
    },
    orderNo: {
      type: [String, Array],
      default: () => []
    },
    orderType: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const store = useStore()
    const dialogVisible = ref(false)
    const state = reactive({
      btnFlag: false
    })
    const formRef = ref(null)
    const payResultTipState = ref(0)
    const payResultTipMsg = new Map([
      [0, '请在新打开的页面上进行支付，支付完成后再关闭此窗口'],
      [1, '未检测到支付信息']
    ])
    const rules = {
      mobile: phone()
    }
    const form = ref({
      mobile: ''
    })
    const closeDialog = () => {
      formRef.value.resetFields()
    }
    const surePay = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          emit('surePay', { mobile: form.value.mobile })
        }
      })
    }
    const tipDialogVisible = ref(false)
    const payNumber = ref(null)
    const { replace } = useLink()
    const hasPay = async () => {
      const { data, statusCode } = await context.$api.pay.getPayInfo({ payNumber: payNumber.value })
      if (statusCode == 200) {
        if (data?.payStatus == 3 || data?.payStatus == 4 || data?.payStatus == 5) {
          if ([3, 4, 5].includes(+props.orderType)) {
            await store.dispatch('app/GET_USERINFO', context)
            await store.dispatch('app/GET_PERMISSION', context)
          }
          replace(`/shop/cashier?orderNo=${props.orderNo?.join(',')}&orderType=${props.orderType}&payNumber=${data.payNumber}`)
        } else {
          payResultTipState.value = 1
        }
      }
    }
    const changeInput = (value) => {
      if (phoneReg.test(value)) {
        state.btnFlag = true
      } else {
        state.btnFlag = false
      }
    }
    const payProblem = () => {
      tipDialogVisible.value = false
      location.reload()
    }
    const closePayResult = () => {
      payResultTipState.value = 0
      location.reload()
    }
    return {
      dialogVisible,
      formRef,
      rules,
      form,
      closeDialog,
      surePay,
      tipDialogVisible,
      hasPay,
      payResultTipState,
      payResultTipMsg,
      replace,
      ...toRefs(state),
      changeInput,
      payNumber,
      payProblem,
      closePayResult
    }
  }
})
