
import { defineComponent, onMounted, reactive, ref, toRefs, useContext, useRoute, watch, inject, useStore } from '@nuxtjs/composition-api'
import tableSearchBar from '@/components/spotMall/tableSearchBar.vue'
import depositDialog from '@/components/bid/depositDialog.vue'
import countdown from '@/components/bid/countdown.vue'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { bidStatusMap } from '@/constant/bid'
import { throttle } from '@/utils/tool'

export default defineComponent({
  components: {
    tableSearchBar,
    depositDialog,
    countdown
  },
  props: {
    currentTime: {
      type: [String, Number]
    },
    condition: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const store = useStore()
    const $message = inject('$message')
    const route = useRoute()
    const { push } = useLink()
    const state = reactive({
      tableLoading: true,
      tableEmpty: true,
      rowObj: {}
    })
    const { replace } = useLink()
    const { userInfo, loginCorpId, bidPackAuth, loginCorpAuth } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
    const tablePage = reactive({
      total: 0
    })
    const editForm = reactive({
      page: 1,
      limit: 50,
      auctionName: ''
    })
    const tableData = ref([])
    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }
    const handleSizeChange = (val) => {
      editForm.limit = val
      editForm.page = 1
      replace(route.value.path, transParams(editForm))
      emit('change', { ...transParams(editForm) })
      Object.assign(editForm, { ...transParams(editForm) })
      getListAsync()
    }
    const handleCurrentChange = (val) => {
      editForm.page = val
      replace(route.value.path, transParams(editForm))
      emit('change', { ...transParams(editForm) })
      Object.assign(editForm, { ...transParams(editForm) })
      getListAsync()
    }
    const handleDetail = (row) => {
      window.open(`${route.value.path}/${row.auctionNo}`, '_blank')
    }
    const handleOffer = async (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!bidPackAuth.value) {
        judgeIsAuth()
        return
      }
      const { data, statusCode, message } = await context.$api.bid.getValidateBid({ auctionNo: row.auctionNo })
      if (statusCode == 200) {
        push('/buyercenter/pack')
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }
    const handleJoin = async (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!bidPackAuth.value) {
        judgeIsAuth()
        return
      }
      !loginCorpAuth.value && (await store.dispatch('app/GET_CORPAUTH', context))
      if (!loginCorpAuth.value) {
        return showErrorTip('', 7)
      }
      state.rowObj = row
      depositRef.value.dialogVisible = true
      depositRef.value.getPayBalance()
    }
    const depositRef = ref(null)

    const getListAsync = throttle(async () => {
      state.tableLoading = true
      try {
        const { data, statusCode } = await context.$api.bid.getPackageBidList(transParams(editForm))
        state.tableLoading = false
        if (statusCode == 200) {
          const { rows, total } = data
          tablePage.total = total
          tableData.value = rows
          state.tableEmpty = rows.length == 0
        } else {
          tablePage.total = 0
          tableData.value = []
          state.tableEmpty = true
        }
      } catch {
        tablePage.total = 0
        tableData.value = []
        state.tableEmpty = true
      }
    }, 500)

    const handleTimout = () => {
      Object.assign(editForm, {
        bidStartTime: '',
        bidEndTime: ''
      })
      handleCurrentChange(1)
      setTimeout(() => {
        emit('timeout')
      }, 3 * 1000)
    }
    watch(
      () => props.condition,
      () => {
        Object.assign(editForm, { ...props.condition })
        getListAsync()
      },
      {
        deep: true
      }
    )

    watch(
      () => userInfo.value,
      () => {
        getListAsync()
      }
    )

    watch(
      () => [props.currentTime],
      ([currentTimeNewVal]) => {
        const time = currentTimeNewVal / 1000 / 60 / 30
        if (typeof time === 'number' && time % 1 === 0) {
          getListAsync()
          setTimeout(() => {
            getListAsync()
          }, 1000 * 3)
        }
      }
    )

    const handleTabSearch = (obj) => {
      Object.assign(editForm, { ...obj })
      emit('change', obj)
    }

    const searchSession = () => {
      editForm.page = 1
      replace(route.value.path, transParams(editForm))
      emit('change', { ...transParams(editForm) })
      Object.assign(editForm, { ...transParams(editForm) })
      getListAsync()
    }

    onMounted(() => {})

    return {
      ...toRefs(state),
      ...toRefs(tablePage),
      tableData,
      handleSizeChange,
      handleCurrentChange,
      handleDetail,
      handleOffer,
      handleJoin,
      depositRef,
      bidStatusMap,
      judgeIsLogin,
      userInfo,
      getListAsync,
      loginCorpId,
      handleTabSearch,
      editForm,
      handleTimout,
      searchSession
    }
  }
})
