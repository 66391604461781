import { ref, reactive, toRefs, inject, computed, watchEffect, toRef } from '@nuxtjs/composition-api'
import cuttingGraphic from './cuttingGraphic.vue'
import tagLines from './tagLines.vue'
import { math } from '@/utils/mathAll'
import { HELP_FEE } from '~/constant/fee'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { useLink } from '@/hooks/useLink'
import { C2M_HOT_LINE } from '@/constant'
import { toFixed } from '@/utils/tool'


export default {
  __name: 'quickEnquiry',
  setup(__props, { expose }) {

const formatHotline = (hotline) => {
  return hotline.replace(/^(.{3})-(.*)-(.{4})$/, '$1\xA0$2\xA0$3')
}
const $message = inject('$message')
const state = reactive({
  dialogVisible: false,
  goodData: {},
  isDetail: false
})
const categoryList = [{ value: '热轧钢板', label: '热轧钢板' }]

const { dialogVisible, goodData } = toRefs(state)

const editForm = reactive({
  width: undefined,
  height: undefined,
  thick: undefined,
  enquriyType: '1',
  widthLines: [],
  heightLines: []
})

const target = reactive({
  width: 0,
  height: 0,
  thick: 0
})
const visible = ref(false)

const options = [
  {
    label: '激光切割',
    value: '1'
  },
  {
    label: '火焰切割',
    value: '2'
  },
  {
    label: '等离子切割',
    value: '3'
  }
]

const columns = ref([
  {
    label: '品名',
    align: 'center',
    prop: 'categoryName',
    showOverflowTooltip: true
  },
  {
    label: '材质',
    align: 'center',
    prop: 'material',
    showOverflowTooltip: true
  },
  {
    label: '尺寸信息',
    align: 'center',
    prop: 'sizeInfo',
    width: 90,
    showOverflowTooltip: true
  },
  {
    label: '质量信息',
    align: 'center',
    prop: 'measure',
    width: 90,
    showOverflowTooltip: true
  },
  {
    label: '重量(吨)',
    align: 'center',
    prop: 'weight',
    showOverflowTooltip: true
  },
  {
    label: '产地',
    align: 'center',
    prop: 'origin',
    showOverflowTooltip: true
  }
])

const graphics = ref([])
const xAxis = ref([])
const yAxis = ref([])
const totalLength = computed(() => {
  if (graphics.value.length) {
    const xLen = math('*', editForm.height, xAxis.value.length)
    const yLen = math('*', editForm.width, yAxis.value.length)
    return xLen + yLen
  }

  return 0
})

const fee = ref(0)
const thick = ref(0)
const fee0 = ref(0)
watchEffect(() => {
  thick.value = Math.floor(editForm.thick)
  let price
  if (thick.value <= 10) {
    price = 8
  } else if (thick.value <= 20 && thick.value > 10) {
    price = 12
  } else if (thick.value <= 30 && thick.value > 20) {
    price = 15
  } else if (thick.value <= 40 && thick.value > 30) {
    price = 18
  } else if (thick.value <= 60 && thick.value > 40) {
    price = 22
  } else if (thick.value <= 80 && thick.value > 60) {
    price = 25
  } else if (thick.value <= 100 && thick.value > 80) {
    price = 27
  } else {
    price = 30
  }
  fee0.value = price
  fee.value = math('+', math('*', totalLength.value, 0.001, fee0.value), HELP_FEE)
})

const handleGraphicChange = (value1, value2, value3) => {
  graphics.value = value1
  xAxis.value = value2
  yAxis.value = value3
}

const calc = () => {
  // reset()
  if (!editForm.width || !editForm.height || !editForm.thick) {
    return $message.warning('请输入对应切割件的长、宽、厚')
  }
  target.width = editForm.width
  target.height = editForm.height
  target.thick = editForm.thick
  target.widthLines = editForm.widthLines
  target.heightLines = editForm.heightLines
  visible.value = true
}
const reset = () => {
  init()
  initGraphicsData()
  visible.value = false
}
const initGraphicsData = () => {
  graphics.value = []
  if (cuttingGraphicRef.value) {
    cuttingGraphicRef.value.init()
    Object.assign(target, {
      width: 0,
      height: 0,
      thick: 0
    })
  }
}

const handleChangeSpec = () => {
  editForm.widthLines = []
  editForm.heightLines = []
  initGraphicsData()
}

const cuttingGraphicRef = ref(null)
const handleMouseenter = (val) => {
  cuttingGraphicRef.value.mouseenter(val)
}
const handleMouseleave = () => {
  cuttingGraphicRef.value.mouseleave()
}

const init = () => {
  if (state.goodData.spec && state.goodData.spec != '-') {
    const specs = state.goodData.spec.split('*')
    editForm.thick = specs[0] ? Number(specs[0]) : undefined
    editForm.width = specs[1] ? Number(specs[1]) : undefined
    editForm.height = specs[2] ? (specs[2].toLocaleLowerCase() == 'l' ? undefined : Number(specs[2])) : undefined
  } else {
    editForm.thick = undefined
    editForm.height = undefined
    editForm.width = undefined
  }
  editForm.heightLines = []
  editForm.widthLines = []
}

const openDialog = (val, isDetail = false) => {
  state.isDetail = isDetail
  state.goodData = Object.assign(
    {
      categoryName: '热轧钢板'
    },
    val
  )
  reset()
  state.dialogVisible = true
}

const handleClick = (key, row) => {
  editForm[key] = row.value
}

const { push } = useLink()
const { userInfo, loginCorpId } = useLoginInfo()
const { judgeIsLogin, judgeIsCompany } = useMsgDialog()
const handlePublish = (path = '/c2m') => {
  if (!userInfo.value) {
    judgeIsLogin()
    return
  }
  if (!loginCorpId.value) {
    judgeIsCompany()
    return
  }
  push(path)
}

expose({
  openDialog,
  ...toRefs(state)
})

return { __sfc: true,formatHotline, $message, state, categoryList, dialogVisible, goodData, editForm, target, visible, options, columns, graphics, xAxis, yAxis, totalLength, fee, thick, fee0, handleGraphicChange, calc, reset, initGraphicsData, handleChangeSpec, cuttingGraphicRef, handleMouseenter, handleMouseleave, init, openDialog, handleClick, push, userInfo, loginCorpId, judgeIsLogin, judgeIsCompany, handlePublish, cuttingGraphic, tagLines, math, HELP_FEE, C2M_HOT_LINE, toFixed }
}

}