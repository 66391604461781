import { useLink } from '@/hooks/useLink'


export default {
  __name: 'CardTitle',
  props: {
  isBtn: {
    type: Boolean,
    default: false
  },
  isLine: {
    type: Boolean,
    default: false
  },
  isBack: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    required: true
  },
  isTag: {
    type: Boolean,
    default: false
  }
},
  emits: 'goBack',
  setup(__props, { emit }) {

const props = __props;





const { back } = useLink()
const goBack = () => {
  if (!props.isBack) back()
  else emit('goBack')
}

return { __sfc: true,props, emit, back, goBack }
}

}