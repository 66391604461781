export default {
  __name: 'SectionTitle',
  props: {
  title: {
    type: String,
    default: ''
  },
  tip: {
    type: String,
    default: ''
  },
  isBackground: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {



return { __sfc: true, }
}

}