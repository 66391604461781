import { reactive, toRefs, useRoute } from '@nuxtjs/composition-api'
import siderBarItem from './siderBarItem.vue'
import { helpcenterMenus } from '@/utils/menu'


export default {
  __name: 'siderBar',
  setup(__props, { expose }) {

const route = useRoute()
const state = reactive({
  openeds: ['rules', 'guides', 'mode', 'contact']
})

expose({
  ...toRefs(state)
})

return { __sfc: true,route, state, siderBarItem, helpcenterMenus }
}

}