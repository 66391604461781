import { render, staticRenderFns } from "./portalDefault.vue?vue&type=template&id=3602a9d0"
import script from "./portalDefault.vue?vue&type=script&lang=js"
export * from "./portalDefault.vue?vue&type=script&lang=js"
import style0 from "./portalDefault.vue?vue&type=style&index=0&id=3602a9d0&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppHeader: require('/usr/src/app/components/layout/Header.vue').default,AppIntroduce: require('/usr/src/app/components/layout/Introduce.vue').default,AppFooter: require('/usr/src/app/components/layout/Footer.vue').default,GbScrollTool: require('/usr/src/app/components/common/ScrollTool.vue').default})
