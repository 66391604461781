import { useLink } from '@/hooks/useLink'


export default {
  __name: 'c2mApp',
  setup(__props) {

const { push } = useLink()
const handleLink = () => {
  push('/c2m/download')
}

return { __sfc: true,push, handleLink }
}

}