import { reactive, toRefs, useRoute, computed } from '@nuxtjs/composition-api'
import goodsTypeMenus from '@/components/homeHeader/components/goodsTypeMenus.vue'


export default {
  __name: 'allGoodsType',
  props: {
  isFixed: {
    type: Boolean,
    default: false
  }
},
  setup(__props, { expose }) {

const props = __props;



const route = useRoute()
const state = reactive({
  dropFlag: false, // 左侧类目导航
  isHome: computed(() => route.value.path == '/' && !props.isFixed),
  isDisabled: computed(() => !route.value.path.includes('/shop') && route.value.path != '/')
})

expose({
  ...toRefs(state)
})

return { __sfc: true,props, route, state, goodsTypeMenus }
}

}