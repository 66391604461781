import { useRoute, computed } from '@nuxtjs/composition-api'


export default {
  __name: '_type',
  setup(__props) {

const typeMap = new Map([
  ['merchant', '商家入驻'],
  ['goodList', '商品挂牌'],
  ['bid', '发布竞价'],
  ['outbound', '自动出库'],
  ['invoice', '自动报账']
])
const route = useRoute()
const paramsType = computed(() => route.value.params.type)

return { __sfc: true,typeMap, route, paramsType }
}

}