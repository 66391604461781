
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const getSort = () => {
      const value = props.value == '1' ? '2' : '1'
      emit('change', value)
      emit('sort', value)
    }
    return {
      getSort
    }
  }
})
