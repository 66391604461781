
import { defineComponent, reactive, toRefs, ref, useContext, inject, onMounted, useRoute, computed, useStore } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { formatNumber, toFixed } from '@/utils/tool'
import signContract from '~/components/shopCarts/signContract.vue'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { threadSteelName, threadSteelCompanyID } from '@/constant'

export default defineComponent({
  components: {
    signContract
  },
  setup() {
    const context = useContext()
    const store = useStore()
    const route = useRoute()
    const $message = inject('$message')
    const redirectPath = context.query.value.redirect
    const { loginCorpInfo, loginCorpAuth } = useLoginInfo()
    const { push, replace, back } = useLink()
    const orderOrigin = context.query.value.type == 1 ? 'detail' : 'cart'
    const state = reactive({
      radio: '1'
    })
    const orderInfo = ref([])
    const selectedProductsNum = computed(() => {
      return getTotalCount('cartQuantity')
    })
    const totalGoodsAmount = computed(() => {
      return getTotalCount('goodsAmount', 2)
    })
    const totalCartGoodsWeight = computed(() => {
      return getTotalCount('cartGoodsWeight', 3)
    })
    const totalDifferenceAmount = computed(() => {
      return getTotalCount('commonDifferenceAmount', 2)
    })
    const totalGoodsAmountAll = computed(() => {
      return getTotalCount('goodsAmountAll', 2)
    })
    const getTotalCount = (key, fixedNum = 0) => {
      let count = 0
      orderInfo.value.forEach((orderList) => {
        orderList.repoGoods.forEach((repoGood) => {
          const validateOrderDtos = repoGood.validateOrderDtos
          validateOrderDtos.forEach((row) => {
            if (row[key]) {
              count += parseFloat(toFixed(row[key], fixedNum))
            }
          })
        })
      })
      return parseFloat(toFixed(count, fixedNum))
    }
    const getDisplayContractParams = () => {
      let params = []
      orderInfo.value.forEach((orderList) => {
        orderList.repoGoods.forEach((repoGood) => {
          const validateOrderDtos = repoGood.validateOrderDtos
          const list = validateOrderDtos.map((res) => {
            return {
              listedPrice: res.listedPrice,
              outboundCost: res.outboundCost,
              quantity: res.cartQuantity,
              resourceId: res.resourceId,
              categoryName: res.categoryName,
              corpId: res.ownerCorpId
            }
          })
          params = [...params, ...list]
        })
      })
      return params
    }
    const signRef = ref(null)
    const contractObj = ref([])
    const signParams = ref(null)
    const { showErrorTip } = useMsgDialog()

    const generateContract = async () => {
      !loginCorpAuth.value && (await store.dispatch('app/GET_CORPAUTH', context))
      if (!loginCorpAuth.value) {
        return showErrorTip('', 7)
      }
      const orderParams = getDisplayContractParams()
      const threadSteel = orderParams.filter((res) => res.categoryName == threadSteelName && res.corpId == threadSteelCompanyID)
      if (!(threadSteel.length == 0 || threadSteel.length == orderParams.length)) {
        return $message.error(`${threadSteelName}(南钢)需要单独生成合同`)
      }
      const { statusCode, data, message } = await context.$api.orderCart.displayContract(orderOrigin, orderParams)
      if (statusCode == 200) {
        signParams.value = orderParams
        contractObj.value = data
        if (threadSteel.length == 0) {
          store.dispatch('app/GET_TRANSACTIONPARAMS', context)
        }
        signRef.value.displayType = orderOrigin
        signRef.value.dialogVisible = true
      } else if (statusCode == 30030) {
        $message.error(message)
        back()
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }
    const createOrderValidatePage = async () => {
      const { type, resourceIds, quantity } = route.value.query
      const _resourceIds = resourceIds.split(',')
      const { statusCode, data, message } = await context.$api.orderCart.createOrderValidatePage({
        type,
        quantity,
        resourceIds: _resourceIds
      })
      if (statusCode == 200) {
        data.forEach((item) => {
          item.list = []
          if (item.repoGoods.length) {
            item.repoGoods.forEach((repoGood) => {
              item.list.push(repoGood)
              item.list = [...item.list, ...repoGood.validateOrderDtos]
            })
          }
          orderInfo.value.push(item)
        })
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        backCarts()
        $message.error(message)
      }
    }
    const backCarts = () => {
      replace(redirectPath || '/shop/carts')
    }
    const columns = ref([
      {
        label: '品名/材质',
        align: 'left',
        width: '130',
        render: (row) => {
          return row.resourceId ? (
            <div>
              <div>{row.categoryName}</div>
              <div>{row.material}</div>
            </div>
          ) : (
            <div>
              <span style="font-weight:bold;margin-right:10px">{row.repositoryName || ''}</span>
              <span>{`地址：${row.repositoryAddress || ''}`}</span>
            </div>
          )
        }
      },
      {
        label: '产地/规格',
        align: 'left',
        width: '130',
        render: (row) => {
          return row.resourceId ? (
            <div>
              <div>{row.origin}</div>
              <div>{row.spec}</div>
            </div>
          ) : (
            ''
          )
        }
      },
      {
        label: '质量信息',
        align: 'left',
        width: '100',
        render: (row) => {
          return row.resourceId ? <span>{row.qualityInfo}</span> : ''
        }
      },
      {
        label: '尺寸信息/计量方式',
        align: 'left',
        width: '130',
        render: (row) => {
          return row.resourceId ? (
            <div>
              <span>{row.sizeInfo}</span>
              <span>/</span>
              <span>{row.measure}</span>
            </div>
          ) : (
            ''
          )
        }
      },
      {
        label: '数量/总重量',
        align: 'right',
        width: '150',
        render: (row) => {
          return row.resourceId ? (
            <span>
              {formatNumber(row.cartQuantity ?? 0)}件<span style="margin:0 3px">/{formatNumber(row.cartGoodsWeight ?? 0, 3)}吨</span>
            </span>
          ) : (
            ''
          )
        }
      },
      {
        label: '含税单价',
        align: 'right',
        width: '130',
        render: (row) => {
          return row.resourceId ? <span>{formatNumber(row.listedPrice, 2)}元/吨</span> : ''
        }
      },
      {
        label: '商品金额/公差金额',
        align: 'right',
        width: '170',
        render: (row) => {
          return row.resourceId ? (
            <div>
              <div>
                <i class="iconfont icon-a-1"></i>
                {formatNumber(row.goodsAmount, 2)}
              </div>
              <div>
                <i class="iconfont icon-a-1"></i>
                {row.commonDifferenceAmount ? formatNumber(row.commonDifferenceAmount, 2) : 0}
              </div>
            </div>
          ) : (
            ''
          )
        }
      },
      {
        label: '总金额',
        align: 'right',
        width: '130',
        render: (row) => {
          return row.resourceId ? (
            <span style="color:#DE0000;font-weight:bold">
              <i class="iconfont icon-a-1"></i>
              {formatNumber(row.goodsAmountAll, 2)}
            </span>
          ) : (
            ''
          )
        }
      },
      {
        label: '备注',
        align: 'left',
        showOverflowTooltip: true,
        render: (row) => {
          return row.resourceId ? <span>{row.remark}</span> : ''
        }
      }
    ])
    const arraySpanMethod = ({ row }) => {
      if (!row.resourceId) {
        return [1, columns.value.length]
      }
    }
    const handleCellStyle = ({ row, columnIndex }) => {
      if (row.resourceId) {
        if (columnIndex === 0) {
          return 'border-left: 1px solid #e6e9eb;padding: 20px 0'
        }
        if (columnIndex === columns.value.length - 1) {
          return 'border-right: 1px solid #e6e9eb;padding: 20px 0'
        }
        return 'padding: 20px 0'
      } else if (columnIndex === 0) {
        return 'padding: 20px 0 5px;background-color: #fff'
      }
    }
    onMounted(() => {
      createOrderValidatePage()
    })
    return {
      ...toRefs(state),
      signRef,
      generateContract,
      push,
      orderInfo,
      selectedProductsNum,
      totalGoodsAmount,
      totalCartGoodsWeight,
      totalDifferenceAmount,
      totalGoodsAmountAll,
      loginCorpInfo,
      backCarts,
      columns,
      arraySpanMethod,
      handleCellStyle,
      contractObj,
      signParams,
      redirectPath
    }
  }
})
