import { ref, computed, useStore, watch } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { HOT_LINE, C2M_HOT_LINE } from '@/constant'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'


export default {
  __name: 'tipMessage',
  setup(__props) {

const store = useStore()
const { loginCorpInfo } = useLoginInfo()
const { showErrorTip } = useMsgDialog()

const dialogVisible = ref(false)
const storeTipMsgDialogVisible = computed(() => store.getters.tipMsgDialogVisible)
const tipMsgState = computed(() => store.getters.tipMsgState)
const tipMessage = computed(() => store.getters.tipMessage)
watch(
  () => dialogVisible.value,
  () => {
    store.commit('tipMsgDialog/SET_TIPMSGDIALOGVISIBLE', dialogVisible.value)
  }
)
watch(
  () => storeTipMsgDialogVisible.value,
  () => {
    dialogVisible.value = storeTipMsgDialogVisible.value
  }
)
const { push } = useLink()
const onSubmit = () => {
  closeTipMsgDialog()
  switch (tipMsgState.value) {
    case 0:
      joinCompany()
      break
    case 4:
      joinMerchant()
      break
    case 6:
      joinCompany()
      break
    case 7:
      joinCompany(false)
      break
    case 9:
      handleBreakOut()
      break
    case 10:
      joinFactory()
      break
    // default:
    //   closeTipMsgDialog()
    //   break
  }
}
const joinCompany = (isDetail = true) => {
  push(isDetail ? '/usercenter/joinCompany/buyerCertify' : '/usercenter/joinCompany')
}
const handleBreakOut = () => {
  push('/buyercenter/purchaseOrder?type=4')
}
const joinMerchant = () => {
  if (loginCorpInfo.value.primaryUser != 1) {
    dialogVisible.value = false
    setTimeout(() => {
      showErrorTip('', 5)
    })
    return
  }
  push('/usercenter/merchant')
}
const joinFactory = () => {
  if (loginCorpInfo.value.primaryUser != 1) {
    dialogVisible.value = false
    setTimeout(() => {
      showErrorTip('', 11)
    })
    return
  }
  push('/usercenter/factory')
}
const closeTipMsgDialog = () => {
  dialogVisible.value = false
  store.commit('tipMsgDialog/SET_TIPMSGDIALOGVISIBLE', false)
}

const changeCompany = () => {
  store.commit('app/SET_CORPCHANGEFLAG', true)
  closeTipMsgDialog()
}

const tipStateMap = new Map([
  [0, { message: '当前为普通会员，无法进行该操作！', btnLabel: '加入/创建公司' }],
  [1, { message: '无法操作，请联系公司管理员进行授权', btnLabel: '关闭' }],
  [2, { message: '商品有异常，请选择其他商品', btnLabel: '关闭' }],
  [4, { message: `当前为买家账号， 还不能享受商家挂货等平台权益，`, btnLabel: '商家入驻' }],
  [5, { message: `无法操作，仅公司管理员可申请商家入驻，`, btnLabel: '好的' }],
  [6, { message: `当前为个人账号，无法进行相关操作`, btnLabel: '加入/创建公司' }],
  [7, { message: `请公司管理员尽快完成公司认证，以免影响平台交易。`, btnLabel: '加入/创建公司' }],
  [8, { message: `无法操作，卖家业务已停用；`, btnLabel: '关闭' }],
  [9, { message: `下单失败，请先处理已违约订单` }],
  [10, { message: `当前账号未开通工厂权限，`, btnLabel: '工厂加盟' }],
  [11, { message: `无法操作，仅公司管理员可申请工厂加盟，`, btnLabel: '好的' }],
  [12, { message: `无法操作，平台已停用您的工厂权限，`, btnLabel: '好的' }]
])

return { __sfc: true,store, loginCorpInfo, showErrorTip, dialogVisible, storeTipMsgDialogVisible, tipMsgState, tipMessage, push, onSubmit, joinCompany, handleBreakOut, joinMerchant, joinFactory, closeTipMsgDialog, changeCompany, tipStateMap, HOT_LINE, C2M_HOT_LINE }
}

}