
import { defineComponent, useContext, inject, ref, reactive, onMounted, toRefs, computed } from '@nuxtjs/composition-api'
import payDialog from '@/components/shopCashier/payDialog.vue'
import { orderTypeMap, payChannelMap } from '@/constant/orders'

export default defineComponent({
  components: {
    payDialog
  },
  props: {
    payMoney: {
      type: [Number, String],
      default: ''
    },
    orderNo: {
      type: [String, Array],
      default: () => []
    },
    orderType: {
      type: String,
      default: ''
    },
    withdrawMoney: {
      type: Boolean,
      default: () => false
    },
    transSeqNo: {
      type: String
    },
    menuList: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const payDialogRef = ref(null)
    const $message = inject('$message')
    const doNext = () => {
      if (bankObj.value) {
        if (props.withdrawMoney) {
          emit('createOrder')
        } else {
          payDialogRef.value.dialogVisible = true
        }
      } else {
        $message.error('请选择企业银行')
      }
    }
    const openDialog = (flag) => {
      payDialogRef.value.dialogVisible = flag
    }

    const state = reactive({})
    const bankObj = ref(null)
    const bankIndex = ref(null)
    const changeTab = (index) => {
      bankIndex.value = index
      bankObj.value = props.menuList[bankIndex.value]
    }
    const orderTitle = computed(() => {
      let str
      orderTypeMap.forEach((value, key) => {
        if (value == props.orderType) {
          str = `${props.orderNo}的${key}`
        }
      })
      return str
    })
    const surePay = (data) => {
      const params = {
        orderNos: props.orderNo,
        orderTitle: orderTitle.value,
        orderType: props.orderType,
        payAmt: props.payMoney,
        payChannel: payChannelMap.get('企业网银'),
        payPhone: data.mobile,
        payBankId: bankObj.value.shortName,
        transSeqNo: props.transSeqNo,
        bankShortName: bankObj.value.shortName
      }
      emit('payOrder', params)
    }
    onMounted(() => {})
    return {
      payDialogRef,
      doNext,
      bankObj,
      bankIndex,
      ...toRefs(state),
      changeTab,
      surePay,
      openDialog
    }
  }
})
