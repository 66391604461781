
import { defineComponent, ref, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SearchInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    btnLabel: {
      type: String,
      default: '确定'
    }
  },
  setup(props, { emit }) {
    const searchValue = ref('')
    watch(
      () => props.value,
      () => {
        searchValue.value = props.value
      },
      { immediate: true }
    )
    const changeInput = (val) => {
      emit('input', val)
    }
    const doSearch = () => {
      emit('click')
    }
    return {
      searchValue,
      changeInput,
      doSearch
    }
  }
})
