import { reactive, toRefs, useRoute, useStore, computed } from '@nuxtjs/composition-api'


export default {
  __name: 'goodsTypeMenus',
  props: {
  useType: {
    type: String,
    default: 'nav'
  }
},
  setup(__props, { expose }) {

const props = __props;



const route = useRoute()
const store = useStore()
const state = reactive({
  activeIndex: -1, // 类目悬浮索引
  dropFlag: false, // 左侧类目导航
  outFlag: false, // 右侧类目是否显示
  categoryList: computed(() => store.getters.homeTreeData),
  useIndex: null
})
const act = (o, o2, o3) => {
  state.useIndex = null
  state.activeIndex = o
  state.outFlag = o2
  if (o3 != undefined) {
    if (o3.name == '用途分类') state.useIndex = 11
  }
}

const newTagArr = [
  // '方管'
]

expose({
  ...toRefs(state)
})

return { __sfc: true,props, route, store, state, act, newTagArr }
}

}