export default {
  __name: 'contactItem',
  props: {
  item: Object
},
  setup(__props) {

const props = __props;


const formatPhone = (phone) => {
  return phone.replace(/^(.{3})(.*)(.{4})$/, '$1\xA0$2\xA0$3')
}

return { __sfc: true,props, formatPhone }
}

}