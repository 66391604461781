
import { computed, defineComponent, ref, watch, useRoute } from '@nuxtjs/composition-api'
export default defineComponent({
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    isAllCheck: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
    isArea: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const checkValue = ref([])
    const handleConfirm = () => {
      emit('change', checkValue.value)
      emit('success')
    }
    const handleCancel = () => {
      emit('change', [])
      emit('cancel')
    }

    watch(
      () => props.value,
      () => {
        checkValue.value = props.value
        if (props.isArea) {
          activeAreaKey.value = Object.keys(props.sortItems)[0]
          activeAreas.value = props.sortItems[activeAreaKey.value]
        }
      },
      { immediate: true }
    )

    const checkAll = ref(false)
    const isIndeterminate = ref(false)
    const handleCheckAllChange = (val) => {
      checkValue.value = val ? props.items.map((_) => _.key) : []
    }

    const handleClick = (tab, event) => {
      activeAreaKey.value = tab.name
      activeAreas.value = props.sortItems[activeAreaKey.value]
    }
    const areas = computed(() => {
      if (props.sortItems) {
        return Object.keys(props.sortItems)
      } else {
        return {}
      }
    })
    const activeAreaKey = ref('')
    const activeAreas = ref([])

    const getAllCheck = (checkedArr, items) => {
      let allCheck = true
      items.forEach((item) => {
        if (!checkedArr.includes(item.key)) {
          allCheck = false
        }
      })
      return allCheck
    }

    watch(
      () => checkValue.value,
      (newVal) => {
        if (props.items.length) {
          if (getAllCheck(newVal, props.items)) {
            checkAll.value = true
            isIndeterminate.value = false
          } else if (newVal.length == 0) {
            checkAll.value = false
            isIndeterminate.value = false
          } else {
            checkAll.value = false
            isIndeterminate.value = true
          }
        }
      },
      { immediate: true }
    )
    return {
      checkValue,
      handleConfirm,
      handleCancel,
      checkAll,
      isIndeterminate,
      handleCheckAllChange,
      areas,
      activeAreas,
      activeAreaKey,
      handleClick
    }
  }
})
