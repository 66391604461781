import { inject, useContext, useStore } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { formatNumber } from '@/utils/tool'
import { invoiceModeSecond } from '@/constant/goods'
import { debounce } from '@/utils'

export default {
  __name: 'recommendItem',
  props: {
  item: Object
},
  setup(__props) {

const props = __props;



const context = useContext()
const $message = inject('$message')
const store = useStore()
const { userInfo, loginCorpId, isCartAuth, isJinMaoCompany } = useLoginInfo()
const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
const handleDetail = (detail) => {
  // window.open(`/shop/${detail.resourceId}`, '_blank')
  window.open(`/shop/goods?id=${encodeURI(detail.resourceId)}`, '_blank')
}
const handleLogin = () => {
  if (!userInfo.value) {
    judgeIsLogin(true)
  }
}
const handleJoin = (row) => {
  debounce(async () => {
    if (!userInfo.value) {
      judgeIsLogin(true)
      return
    }
    if (!loginCorpId.value) {
      judgeIsCompany()
      return
    }
    if (!isCartAuth.value) {
      judgeIsAuth()
      return
    }
    const { statusCode, data, message } = await context.$api.orderCart.addCart({ resourceId: row.resourceId, quantity: 1 })
    if (statusCode == 200) {
      $message.success('加入购物车成功')
      store.dispatch('spotMall/GET_CARTSNUMBER', context)
    } else if (statusCode == 30010 || statusCode == 30020) {
      showErrorTip(message)
    } else {
      $message.error(message)
    }
  }, 500)
}

return { __sfc: true,props, context, $message, store, userInfo, loginCorpId, isCartAuth, isJinMaoCompany, judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip, handleDetail, handleLogin, handleJoin, formatNumber, invoiceModeSecond }
}

}