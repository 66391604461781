
import { defineComponent, ref, watch } from '@nuxtjs/composition-api'
export default defineComponent({
  name: '',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    showSuffix: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const pwdValue = ref('')

    watch(
      () => props.value,
      (newVal) => {
        pwdValue.value = newVal
      },
      {
        immediate: true
      }
    )
    const passwordType = ref('password')
    const showPwd = () => {
      passwordType.value = passwordType.value === 'password' ? '' : 'password'
    }
    const inputChange = (val) => {
      const temp = val.replace(/[^\d]/g, '')
      emit('input', temp)
      setTimeout(() => {
        pwdValue.value = temp
      }, 10)
    }
    return {
      pwdValue,
      passwordType,
      showPwd,
      inputChange
    }
  }
})
