import appIntroduce from '@/components/home/introduce.vue'

export default {
  __name: 'download',
  setup(__props) {


return { __sfc: true,appIntroduce }
}

}