import { ref } from '@nuxtjs/composition-api'


export default {
  __name: 'TipConfirm',
  props: {
  loading: {
    type: Boolean,
    default: false
  }
},
  emits: 'onSubmit',
  setup(__props, { expose, emit }) {

const props = __props;




const dialogVisible = ref(false)
const onSubmit = () => {
  emit('onSubmit')
}

expose({
  dialogVisible
})

return { __sfc: true,props, emit, dialogVisible, onSubmit }
}

}