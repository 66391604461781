export default {
  __name: 'imageBox',
  props: {
  src: {
    type: String,
    default: ''
  },
  showType: {
    type: String,
    default: 'image'
  },
  isOrigin: {
    type: Boolean,
    default: false
  }
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}