import { computed, watch, onMounted, reactive, ref, toRefs, useContext, useStore, onUnmounted } from '@nuxtjs/composition-api'
import originTagItem from '@/components/home/components/originTagItem'
import { useInterval } from '@/hooks/useInterval'


export default {
  __name: 'originResource',
  setup(__props, { expose }) {

const store = useStore()
const currentTime = computed(() => store.getters.currentTime)
const context = useContext()
const state = reactive({
  pageNum: 10
})
const carouselNum = computed(() => Math.ceil(originList.value.length / state.pageNum))
const originList = ref([])

const getListAsync = async () => {
  const { data, statusCode } = await context.$api.home.getOriginList()
  if (statusCode == 200) {
    originList.value = data
  }
}

const { setTimer } = useInterval(60)

const getIntervalTimer = () => {
  setTimer(() => {
    getListAsync()
  })
}

const handleVisiable = (e) => {
  const isHidden = e.target.hidden
  if (isHidden === false) {
    getListAsync()
    getIntervalTimer()
  }
}
onMounted(() => {
  getListAsync()
  document.addEventListener('visibilitychange', handleVisiable)
  getIntervalTimer()
})
onUnmounted(() => {
  document.removeEventListener('visibilitychange', handleVisiable)
})

expose({
  ...toRefs(state)
})

return { __sfc: true,store, currentTime, context, state, carouselNum, originList, getListAsync, setTimer, getIntervalTimer, handleVisiable, originTagItem }
}

}