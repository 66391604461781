
import { defineComponent, onMounted, reactive, ref, toRefs, useContext } from '@nuxtjs/composition-api'
import { requiredInput } from '@/utils/validate'
export default defineComponent({
  props: {
    index: {
      type: Number
    },
    isDelete: {
      type: Boolean,
      default: true
    },
    goodsForm: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const handleDelete = (index) => {
      emit('delete', index)
    }
    const attributeArrays = reactive({
      category: [],
      material: []
    })
    const getPMInfoAsync = async () => {
      const { data, statusCode } = await context.$api.goodsType.getPMInfo()
      if (statusCode == 200) {
        attributeArrays.category = data
      }
    }
    const getMaterialInfoAsync = async () => {
      const { data, statusCode } = await context.$api.goodsType.getMaterialInfo()
      if (statusCode == 200) {
        attributeArrays.material = data
      }
    }
    onMounted(() => {
      getPMInfoAsync()
      getMaterialInfoAsync()
    })
    return {
      requiredInput,
      handleDelete,
      attributeArrays
    }
  }
})
