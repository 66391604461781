import { reactive, toRefs, ref, useContext } from '@nuxtjs/composition-api'
import { HOT_LINE } from '@/constant'
import { requiredInput } from '@/utils/validate'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'feedbackDialog',
  setup(__props, { expose }) {

const context = useContext()
const { push } = useLink()
const state = reactive({
  dialogVisible: false,
  resultFlag: false,
  loading: false
})
const formRef = ref(null)
const rules = {
  feedback: requiredInput('请输入反馈意见')
}
const editForm = ref({
  feedback: ''
})
const onSubmit = () => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      state.loading = true
      const { data, statusCode } = await context.$api.home.addFeedback({
        content: editForm.value.feedback,
        status: 1
      })
      state.loading = false
      if (statusCode == 200) {
        state.resultFlag = true
      }
    }
  })
}
const handleBack = () => {
  state.dialogVisible = false
  push('/')
}

expose({
  ...toRefs(state),
  formRef
})

return { __sfc: true,context, push, state, formRef, rules, editForm, onSubmit, handleBack, HOT_LINE }
}

}