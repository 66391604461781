import { useRoute, computed } from '@nuxtjs/composition-api'


export default {
  __name: '_type',
  setup(__props) {

const typeMap = new Map([
  ['apply', '工厂申请'],
  ['demand', '询单报价'],
  ['order', '合同管理'],
  ['delivery', '发货管理']
])
const route = useRoute()
const paramsType = computed(() => route.value.params.type)

return { __sfc: true,typeMap, route, paramsType }
}

}