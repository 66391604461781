
import { defineComponent, onMounted, reactive, ref, toRefs, computed, watch, useRoute } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import sortButton from '~/components/basic/SortButton.vue'
import inputRange from '~/components/basic/InputRange.vue'

export default defineComponent({
  components: {
    sortButton,
    inputRange
  },
  props: {
    isBid: {
      type: Boolean,
      default: false
    },
    isTransport: {
      type: Boolean,
      default: false
    },
    isBidSingle: {
      type: Boolean,
      default: false
    },
    isBatch: {
      type: Boolean,
      default: false
    },
    isBatchJoin: {
      type: Boolean,
      default: false
    },
    isSort: {
      type: Boolean,
      default: true
    },
    condition: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const { pushQuery } = useLink()
    const route = useRoute()
    const { userInfo } = useLoginInfo()
    const state = reactive({
      sortType: ''
    })
    const searchForm = reactive({
      params: {
        price: '',
        weightSum: '',
        spec: ''
      },
      designation: 0,
      listedPrice: []
    })
    const sorts = ref([
      { key: 'price', value: props.isBid || props.isTransport ? '挂牌价' : '销售价' },
      { key: 'spec', value: '规格' },
      { key: 'weightSum', value: '挂牌重量合计' }
    ])
    const getNormalState = () => {
      state.sortType = ''
      Object.keys(searchForm.params).forEach((key) => {
        searchForm.params[key] = ''
      })
      search()
    }
    const getSortType = (type) => {
      state.sortType = type
      Object.keys(searchForm.params).forEach((key) => {
        if (key != type) searchForm.params[key] = ''
      })
      search()
    }

    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }

    const search = () => {
      const [listedPriceStart = '', listedPriceEnd = ''] = searchForm.listedPrice
      const tempParams = {
        ...props.condition,
        designation: searchForm.designation,
        sortName: '',
        sortType: '',
        listedPriceStart,
        listedPriceEnd
      }
      for (const i in searchForm.params) {
        if (searchForm.params[i]) {
          tempParams.sortName = sorts.value.find((_) => _.key == i).value
          tempParams.sortType = searchForm.params[i]
        }
      }
      const params = transParams(tempParams)
      delete params.orderFields
      params.page = 1
      pushQuery(route.value.path, { ...params })
      emit('change', { ...params })
    }
    watch(
      () => props.condition,
      () => {
        renderSearchForm()
      },
      {
        deep: true
      }
    )
    const getPriceRange = () => {
      search()
    }
    const renderSearchForm = () => {
      const { designation = '', listedPriceStart = '', listedPriceEnd = '', sortName = '', sortType = '' } = props.condition
      searchForm.designation = designation
      if (designation == 1) {
        searchForm.designation = 1
      }
      searchForm.listedPrice = [listedPriceStart, listedPriceEnd]
      if (sortName) {
        const key = sorts.value.find((_) => _.value == sortName).key
        searchForm.params[key] = sortType
        state.sortType = key
      }
    }
    const batchShop = () => {
      emit('batchShop', !props.isBatch)
    }
    const handleBatchJoin = () => {
      emit('batchJoin', !props.isBatchJoin)
    }
    const showDesignation = () => {
      searchForm.designation = searchForm.designation == 1 ? 0 : 1
      search()
    }
    onMounted(() => {
      renderSearchForm()
    })
    return {
      userInfo,
      ...toRefs(state),
      ...toRefs(searchForm),
      getNormalState,
      getSortType,
      search,
      getPriceRange,
      batchShop,
      showDesignation,
      handleBatchJoin
    }
  }
})
