import { ref } from '@nuxtjs/composition-api'


export default {
  __name: 'contentTooltip',
  props: {
  spanText: {
    type: String,
    default: ''
  },
  maxLength: {
    type: Number,
    default: 10
  },
  placement: {
    type: String,
    default: 'bottom'
  },
  label: {
    type: String,
    default: ''
  },
  width: {
    type: Number,
    default: 80
  },
  isOverflow: {
    type: Boolean,
    default: true
  }
},
  setup(__props) {

const props = __props;



const isShowTooltip = ref(true)
const refName = ref(null)
const onMouseOver = () => {
  const parentWidth = refName.value.parentNode.offsetWidth
  const parentHeight = refName.value.parentNode.offsetHeight
  const contentWidth = refName.value.offsetWidth
  const contentHeight = refName.value.offsetHeight
  // 判断是否开启tooltip功能
  if (contentWidth > parentWidth || contentHeight > parentHeight) {
    isShowTooltip.value = false
  } else {
    isShowTooltip.value = true
  }
}

return { __sfc: true,props, isShowTooltip, refName, onMouseOver }
}

}