
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    goodsInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup() {}
})
