export default {
  __name: 'spanBadge',
  props: {
  text: {
    type: String
  },
  icon: {
    type: String,
    default: ''
  },
  showOverFlowTooltip: {
    type: Boolean,
    default: false
  },
  maxLength: {
    type: Number,
    default: 95
  }
},
  emits: 'c2m',
  setup(__props, { emit }) {

const props = __props;




const handleClick = () => {
  if (props.icon == 'c2m') {
    emit('openc2m')
  }
}

return { __sfc: true,props, emit, handleClick }
}

}