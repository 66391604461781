import { useLink } from '@/hooks/useLink'
import { formatNumber } from '@/utils/tool'


export default {
  __name: 'originTagItem',
  props: {
  item: Object
},
  setup(__props) {

const props = __props;



const { push } = useLink()
const handleOrigin = (item) => {
  push('/shop', { origin: item.origin })
}

return { __sfc: true,props, push, handleOrigin, formatNumber }
}

}