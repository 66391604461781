
import { computed, defineComponent, inject, reactive, toRefs, watchEffect } from '@nuxtjs/composition-api'
import { formatDate } from '@/utils/tool'
export default defineComponent({
  props: {
    itemObj: [Object, Number],
    activeIndex: Object
  },
  setup(props, { emit }) {
    const state = reactive({
      timeStart: '',
      timeEnd: '',
      sessionDate: '',
      dateText: ''
    })
    const changeSession = () => {
      emit('change-session', props.itemObj)
    }
    const syncTime = inject('syncTime')
    const today = computed(() => formatDate(new Date(syncTime.value), 'MM月dd日'))
    watchEffect(() => {
      state.timeStart = formatDate(props.itemObj.beginTime, 'hh:mm')
      state.timeEnd = formatDate(props.itemObj.endTime, 'hh:mm')
      state.sessionDate = formatDate(props.itemObj.endTime, 'MM月dd日')
      if (props.itemObj.status == 7) {
        state.dateText = '正在进行'
      } else if (props.itemObj.status == 4 && today.value == state.sessionDate) {
        state.dateText = '今日即将开抢'
      } else if (props.itemObj.status == 4 && today.value != state.sessionDate) {
        state.dateText = `${state.sessionDate}开抢`
      }
    })
    return {
      ...toRefs(state),
      changeSession,
      today
    }
  }
})
