
import { defineComponent, ref, onMounted, onUnmounted, watch } from '@nuxtjs/composition-api'
export default defineComponent({
  props: {
    enableFloat: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const floatbarEnable = ref(false)
    watch(
      () => props.enableFloat,
      (value) => {
        if (value) {
          listenerFunction()
          handleScroll()
        } else {
          floatbarEnable.value = false
          document.removeEventListener('scroll', handleScroll, false)
        }
      }
    )
    const listenerFunction = () => {
      if (process.client) {
        document.addEventListener('scroll', handleScroll, false)
      }
    }
    // 编写滚动条事件
    const handleScroll = () => {
      if (process.client) {
        const cartFloatbar = document.querySelector('.cart-floatbar')
        // 判断floatbar是否在可视窗口范围之内
        if (cartFloatbar.getBoundingClientRect().top + 62 >= window.innerHeight) {
          floatbarEnable.value = true
        } else {
          floatbarEnable.value = false
        }
      }
    }
    onMounted(() => {
      if (props.enableFloat) {
        listenerFunction()
        handleScroll()
      }
    })
    onUnmounted(() => {
      document.removeEventListener('scroll', handleScroll, false)
    })

    return {
      floatbarEnable,
      handleScroll
    }
  }
})
