import { ref, useStore, computed, watch, useContext } from '@nuxtjs/composition-api'
import loginForm from '@/components/layoutComp/loginForm.vue'
import { getParams } from '@/utils/sso'

export default {
  __name: 'LoginDialog',
  setup(__props) {

const context = useContext()
const store = useStore()
const loginDialogVisible = ref(false)
const storeLoginDialogVisible = computed(() => store.getters.dialogVisible)
watch(
  () => loginDialogVisible.value,
  () => {
    store.commit('loginDialog/SET_DIALOGVISIBLE', loginDialogVisible.value)
  }
)
watch(
  () => storeLoginDialogVisible.value,
  () => {
    loginDialogVisible.value = storeLoginDialogVisible.value
  }
)
const loginSuccess = () => {
  loginDialogVisible.value = false
}
const loginFormRef = ref(null)
const openLoginDialog = () => {
  if (loginFormRef.value && loginFormRef.value.formRef) loginFormRef.value.formRef.resetFields()
}
const { $config } = context

const gotoRegister = () => {
  window.location.href = `${$config.ssoUrl}/register${getParams()}`
}

const gotoForgetPwd = () => {
  window.location.href = `${$config.ssoUrl}/forgetPwd${getParams()}`
}

return { __sfc: true,context, store, loginDialogVisible, storeLoginDialogVisible, loginSuccess, loginFormRef, openLoginDialog, $config, gotoRegister, gotoForgetPwd, loginForm }
}

}