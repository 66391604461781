import { ref } from '@nuxtjs/composition-api'
import { C2M_HOT_LINE, JIN_MAO } from '@/constant'
import { useLink } from '@/hooks/useLink'

export default {
  __name: 'c2mfooter',
  setup(__props) {

const { pushBcs } = useLink()
const links = [
  {
    text: '平台规则',
    url: '/helpcenter/trendRule'
  },
  {
    text: '关于我们',
    href: 'http://www.gangbao365.cn'
  },
  {
    text: '帮助中心',
    url: '/helpcenter/trendRule'
  },
  {
    text: '新手指南',
    url: '/helpcenter/buyerGuide/regist'
  },
  {
    text: '联系我们',
    url: '/helpcenter/contact/service'
  },
  {
    text: '营业执照',
    href: 'https://oss.gangbao365.com/ec-public-prd/sqwts/license.pdf'
  }
]
const icp = ref('https://beian.miit.gov.cn/#/Integrated/index')
const licence = ref('https://web.gangbao365.com/webresource/images/gb/zzdxxkzz.jpg')

const handleLink = (item) => {
  if (item.url) {
    pushBcs(item.url, false)
  }
}

return { __sfc: true,pushBcs, links, icp, licence, handleLink, C2M_HOT_LINE, JIN_MAO }
}

}