
import { defineComponent, reactive, toRefs, useContext, useRoute } from '@nuxtjs/composition-api'
import countdown from '@/components/bid/countdown.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { bidStatusMap } from '@/constant/bid'
import { useMsgDialog } from '@/hooks/useMsgDialog'
export default defineComponent({
  components: {
    countdown
  },
  props: {
    packList: {
      type: Array
    },
    currentTime: {
      type: [String, Number, Date],
      default: () => new Date()
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const route = useRoute()
    const { push } = useLink()
    const { userInfo, loginCorpId } = useLoginInfo()
    const { judgeIsLogin } = useMsgDialog()

    const state = reactive({
      swiperOption: {
        mousewheel: true,
        slidesPerView: 'auto',
        direction: 'vertical',
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      }
    })
    const handleDetail = (row) => {
      if (row.status == 4 || row.status == 7) {
        window.open(`/bid/pack/${row.auctionNo}`, '_blank')
      }
    }
    const clickProps = (e) => {
      const auctionNo = e.target.dataset.auctionNo
      if (auctionNo) {
        const row = {
          auctionNo
        }
        handleDetail(row)
      }
    }

    const handleTimeOut = () => {
      emit('packTimeOut')
    }

    return {
      ...toRefs(state),
      userInfo,
      bidStatusMap,
      handleDetail,
      clickProps,
      judgeIsLogin,
      handleTimeOut
    }
  }
})
