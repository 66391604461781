
import { defineComponent, useContext, reactive, ref, useStore } from '@nuxtjs/composition-api'
import { useExTable } from '@/hooks/useExTable'
import { formatDate, addDays } from '@/utils/tool'
import { messageType } from '@/constant/home'
import queryCondition from '@/components/message/queryCondition.vue'

export default defineComponent({
  components: {
    queryCondition
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const store = useStore()
    const query = reactive({
      startTime: '',
      endTime: ''
    })
    const columns = ref([
      {
        slot: 'content'
      },
      {
        width: '200',
        render: (row) => {
          return <span class={row.readStatus == 0 ? 'message-unread2' : 'message-read'}>{formatDate(row.pushTime, 'yyyy年MM月dd日 hh:mm')}</span>
        }
      }
    ])

    const { data, total, getListAsync, handleCurrentChange, handleSizeChange } = useExTable({
      query,
      queryApi: context.$api.home.getMessageList,
      limit: 10
    })

    const handleRead = async (row) => {
      if (row.readStatus == 1 || row.loading) {
        return
      }
      row.loading = true
      try {
        const { statusCode } = await context.$api.home.readed({ id: row.id })
        row.loading = false
        if (statusCode === 200) {
          row.readStatus = '1'
          store.dispatch('home/GET_MSGCOUNT', context)
        }
      } catch (error) {
        row.loading = false
      }
    }

    const handleReadAll = async () => {
      const { statusCode } = await context.$api.home.readedAll()
      if (statusCode === 200) {
        getListAsync()
        store.dispatch('home/GET_MSGCOUNT', context)
      }
    }

    const handleDateChange = (date) => {
      query.startTime = date ? formatDate(date[0], 'yyyy-MM-dd') : ''
      query.endTime = date ? formatDate(addDays(date[1], 1), 'yyyy-MM-dd') : ''
      getListAsync()
    }

    const handleRowClassName = ({ row }) => {
      return row.readStatus == 0 ? 'is-focus' : ''
    }

    return {
      columns,
      query,
      data,
      total,
      handleCurrentChange,
      handleSizeChange,
      handleRead,
      handleReadAll,
      handleDateChange,
      messageType,
      handleRowClassName
    }
  },
  head: {
    title: '消息中心-金陵钢宝网'
  }
})
