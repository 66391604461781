import { onMounted, onUnmounted, ref, useRoute, watch } from '@nuxtjs/composition-api'
import headSearch from '@/components/homeHeader/headSearch.vue'
import dropMenu from '@/components/homeHeader/components/allGoodsType.vue'
import menuBar from '@/components/homeHeader/components/menuBar.vue'


export default {
  __name: 'navSearchBar',
  setup(__props) {

const route = useRoute()
const whether = ref(false)
const handleScroll = () => {
  // 计算滚动条位置
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  // 计算绑定div位置
  const offsetTop = document.querySelector('#menuFlag').offsetTop
  // 进行比较设置位置fixed
  whether.value = scrollTop > offsetTop
}
watch(
  () => route.value.path,
  (newVal) => {
    if (newVal == '/') {
      window.addEventListener('scroll', handleScroll)
    } else {
      window.removeEventListener('scroll', handleScroll)
      whether.value = false
    }
  }
)
onMounted(() => {
  if (route.value.path == '/') {
    window.addEventListener('scroll', handleScroll)
  }
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  whether.value = false
})

return { __sfc: true,route, whether, handleScroll, headSearch, dropMenu, menuBar }
}

}