
import { defineComponent } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'

export default defineComponent({
  setup() {
    const { pushBcs } = useLink()
    const { showErrorTip } = useMsgDialog()
    const { permissions } = useLoginInfo()
    const investMoney = () => {
      let topupAmountMenu = -1
      const moneyMenus = permissions.value.find((res) => res.name == '资金中心')
      if (moneyMenus && moneyMenus.children) {
        const moneyMsgMenus = moneyMenus.children.find((res) => res.name == '资金管理')
        if (moneyMsgMenus && moneyMsgMenus.children) {
          topupAmountMenu = moneyMsgMenus.children.findIndex((res) => res.name == '充值')
        }
      }
      if (topupAmountMenu == -1) {
        showErrorTip('无法操作，请联系公司管理员进行授权')
      } else {
        pushBcs('/moneycenter/topupAmount')
      }
    }

    return {
      investMoney
    }
  }
})
