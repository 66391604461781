import { ref, reactive, watch } from '@nuxtjs/composition-api'
import { phoneOrTel } from '@/utils/validate'


export default {
  __name: 'basic',
  props: {
  info: {
    type: Object,
    default: () => {}
  }
},
  emits: ['save'],
  setup(__props, { expose, emit }) {

const props = __props;





const editFormRef = ref(null)
const editForm = reactive({
  contactPerson: '',
  contactMobile: ''
})
const rules = {
  contactPerson: [{ required: true, message: '请填写联系人', trigger: 'blur' }],
  contactMobile: phoneOrTel()
}

const validate = () => {
  return editFormRef.value.validate()
}

const resetFields = () => {
  editFormRef.value.resetFields()
}

const handleLoginAuth = () => {
  emit('save')
}

const handleClear = () => {
  emit('save')
}

expose({
  validate,
  editForm,
  resetFields
})

watch(
  () => props.info,
  (value) => {
    if (value) {
      Object.assign(editForm, { contactPerson: value.contactPerson || '', contactMobile: value.contactMobile || '' })
    }
  },
  {
    immediate: true
  }
)

return { __sfc: true,props, emit, editFormRef, editForm, rules, validate, resetFields, handleLoginAuth, handleClear }
}

}