import { computed, onMounted, reactive, toRefs, ref, useContext, useStore } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'


export default {
  __name: 'industryInfo',
  setup(__props, { expose }) {

const context = useContext()
const store = useStore()
const state = reactive({
  page: {
    limit: 5,
    page: 1
  }
})

const lists = ref([])
const infoBanners = computed(() => store.getters.infoBanners)

const getListAsync = async () => {
  const { data, statusCode } = await context.$api.home.getNewsList({ ...state.page })
  if (statusCode == 200) {
    const { rows = [] } = data
    lists.value = rows
  }
}
const { push, pushBcs } = useLink()
const { showErrorTip } = useMsgDialog()
const handleNews = async (row) => {
  if (row) {
    if (row.link) {
      pushBcs(row.link, false)
    } else {
      const { data, statusCode, message } = await context.$api.home.getNewsDeatil({ id: row.id })
      if (statusCode == 200) {
        pushBcs(`/news/${row.id}`, false)
      } else {
        showErrorTip(message)
        getListAsync()
      }
    }
  } else {
    push('/news')
  }
}

const handleLink = (item) => {
  if (item.link) {
    pushBcs(item.link, false)
  }
}

onMounted(() => {
  getListAsync()
})

expose({
  ...toRefs(state)
})

return { __sfc: true,context, store, state, lists, infoBanners, getListAsync, push, pushBcs, showErrorTip, handleNews, handleLink }
}

}