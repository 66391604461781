
import { defineComponent, reactive, ref, toRefs, useRoute, useContext, watch, onMounted, computed, inject, useStore } from '@nuxtjs/composition-api'
import cardTitle from '@/components/bid/cardTitle.vue'
import tableSearchBar from '@/components/spotMall/tableSearchBar.vue'
import countdown from '@/components/bid/countdown.vue'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { bidStatusMap } from '@/constant/bid'
import { throttle, formatNumber } from '@/utils/tool'

export default defineComponent({
  components: {
    tableSearchBar,
    cardTitle,
    countdown
  },
  props: {
    currentTime: {
      type: [String, Number]
    },
    condition: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const store = useStore()
    const $message = inject('$message')
    const route = useRoute()
    const state = reactive({
      tableLoading: true,
      tableEmpty: false,
      tipMessage: '',
      rowObj: {}
    })

    const editForm = reactive({
      page: 1,
      limit: 50
    })
    const { replace, push } = useLink()
    const { userInfo, loginCorpId, bidSingleAuth, loginCorpAuth } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
    const tablePage = reactive({
      total: 0
    })
    const tableData = ref([])

    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }
    const handleSizeChange = (val) => {
      editForm.limit = val
      editForm.page = 1
      replace(route.value.path, transParams(editForm))
      emit('change', transParams(editForm))
    }
    const handleCurrentChange = (val) => {
      editForm.page = val
      replace(route.value.path, transParams(editForm))
      emit('change', transParams(editForm))
    }
    const handleDetail = (row) => {
      window.open(`${route.value.path}/${row.auctionNo}`, '_blank')
    }
    const handleOffer = async (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!bidSingleAuth.value) {
        judgeIsAuth()
        return
      }
      const { data, statusCode, message } = await context.$api.bid.getValidateBid({ auctionNo: row.auctionNo })
      if (statusCode == 200) {
        push('/buyercenter/single')
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }
    const handleJoin = async (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!bidSingleAuth.value) {
        judgeIsAuth()
        return
      }
      !loginCorpAuth.value && (await store.dispatch('app/GET_CORPAUTH', context))
      if (!loginCorpAuth.value) {
        return showErrorTip('', 7)
      }
      state.tipMessage = '是否参与该条竞价？'
      confirmRef.value.dialogVisible = true
      state.rowObj = row
    }
    const confirmRef = ref(null)

    const sureTipConfirm = async () => {
      const auctionNo = Array.isArray(state.rowObj)
        ? state.rowObj.map((res) => {
            return {
              auctionNo: res.auctionNo
            }
          })
        : state.rowObj.auctionNo
      const { data, statusCode, message } = Array.isArray(state.rowObj)
        ? await context.$api.bid.joinBatchSingle(auctionNo)
        : await context.$api.bid.joinAuction({
            auctionNo
          })
      if (statusCode == 200) {
        confirmRef.value.dialogVisible = false
        isBatch.value = false
        checkState.checkedList = []
        getListAsync()
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }

    const getListAsync = throttle(async () => {
      state.tableLoading = true
      try {
        const { data, statusCode } = await context.$api.bid.getSingleBidList({ ...handleQuery() })
        state.tableLoading = false
        if (statusCode == 200) {
          const { rows, total } = data
          tablePage.total = total
          tableData.value = getChecked(rows) || []
          getAllCheckedStatus()
          state.tableEmpty = !rows || rows.length == 0
        } else {
          tablePage.total = 0
          tableData.value = []
          state.tableEmpty = true
        }
      } catch {
        tablePage.total = 0
        tableData.value = []
        state.tableEmpty = true
      }
    }, 500)

    const handleQuery = () => {
      const query = { ...editForm }
      if (query.sortName) {
        // query.orderFields = [
        //   {
        //     field: query.sortName,
        //     // 1升序2降序
        //     order: query.sortType
        //   }
        // ]
        if (query.sortName == '规格') {
          query.orderFields = [
            { field: '厚', order: query.sortType },
            { field: '宽', order: query.sortType },
            { field: '长', order: query.sortType }
          ]
        }
      } else {
        query.orderFields = []
      }
      if (query?.invoiceList?.length) {
        query.invoiceList = query.invoiceList?.split(',').map((_) => _ + '类')
      } else {
        query.invoiceList = []
      }
      delete query.sv
      delete query.sk
      delete query.sr
      delete query.matchList
      delete query.invoiceList
      return query
    }

    const handleTimout = () => {
      isBatch.value = false
      checkState.checkedList = []
      Object.assign(editForm, {
        bidStartTime: '',
        bidEndTime: ''
      })
      handleCurrentChange(1)
      setTimeout(() => {
        emit('timeout')
      }, 3 * 1000)
    }
    watch(
      () => props.condition,
      () => {
        Object.assign(editForm, { ...props.condition })
        getListAsync()
      },
      {
        deep: true
      }
    )
    watch(
      () => userInfo.value,
      () => {
        getListAsync()
      }
    )

    watch(
      () => [props.currentTime],
      ([currentTimeNewVal]) => {
        const time = currentTimeNewVal / 1000 / 60 / 30
        if (typeof time === 'number' && time % 1 === 0) {
          getListAsync()
          setTimeout(() => {
            getListAsync()
          }, 1000 * 3)
        }
      }
    )
    const handleTabSearch = (obj) => {
      Object.assign(editForm, { ...obj })
      emit('change-tab', obj)
    }

    const isBatch = ref(false)
    const handleBarBatchJoin = (flag) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      // if (!isCartAuth.value) {
      //   judgeIsAuth()
      //   return
      // }
      isBatch.value = flag
      getInitCheckedState()
    }
    const checkState = reactive({
      checkAll: false,
      checkAllDisabled: false,
      isIndeterminate: false,
      checkedList: []
    })
    const chosedNumber = computed(() => checkState.checkedList.length)
    const handleCheck = (flag, row) => {
      row.checked = flag
      getCheckedList()
    }
    const getChecked = (data) => {
      const chosedIdArr = checkState.checkedList.map((res) => res.id)
      data.forEach((res) => {
        res.checked = chosedIdArr.includes(res.id)
      })
      return data
    }
    const getAllCheckedStatus = () => {
      const validNumber = tableData.value.reduce((acc, cur) => {
        if (cur.corpId != loginCorpId.value && cur.joined == '0') {
          acc += 1
        }
        return acc
      }, 0)
      const checkedNum = tableData.value.filter((_) => _.checked).length
      checkState.checkAll = checkedNum == validNumber
      checkState.checkAllDisabled = validNumber == 0
      checkState.isIndeterminate = !!(checkedNum && checkedNum < validNumber)
    }
    const getCheckedList = () => {
      const chosedIdArr = checkState.checkedList.map((res) => res.id)
      const unChosedIdArr = tableData.value.filter((_) => !_.checked).map((res) => res.id)
      checkState.checkedList = checkState.checkedList.filter((_) => !unChosedIdArr.includes(_.id))
      checkState.checkedList = [...checkState.checkedList, ...tableData.value.filter((_) => _.checked && !chosedIdArr.includes(_.id))]
      getAllCheckedStatus()
    }
    const handleCheckAllChange = (flag) => {
      checkState.checkAll = flag
      tableData.value.forEach((res) => {
        if (res.corpId != loginCorpId.value && res.joined == '0') {
          res.checked = flag
        }
      })
      getCheckedList()
    }
    const handleBatch = () => {
      state.tipMessage = '是否参与该条竞价？'
      confirmRef.value.dialogVisible = true
      state.rowObj = checkState.checkedList
    }

    const handleBatchCancel = () => {
      isBatch.value = false
      getInitCheckedState()
    }
    const getInitCheckedState = () => {
      checkState.checkedList = []
      checkState.checkAll = false
      checkState.checkAllDisabled = false
      checkState.isIndeterminate = false
      tableData.value.forEach((res) => (res.checked = false))
    }

    onMounted(() => {})

    return {
      ...toRefs(state),
      ...toRefs(tablePage),
      tableData,
      handleSizeChange,
      handleCurrentChange,
      handleDetail,
      handleOffer,
      handleJoin,
      confirmRef,
      sureTipConfirm,
      getListAsync,
      userInfo,
      judgeIsLogin,
      bidStatusMap,
      loginCorpId,
      editForm,
      handleTabSearch,
      formatNumber,
      handleTimout,
      handleBarBatchJoin,
      handleBatchCancel,
      handleBatch,
      isBatch,
      ...toRefs(checkState),
      handleCheckAllChange,
      chosedNumber,
      handleCheck
    }
  }
})
