import contactItem from '@/components/helpcenter/contactItem.vue'
import { warehouse } from '@/constant/helpContact'

export default {
  __name: 'warehouse',
  setup(__props) {


return { __sfc: true,contactItem, warehouse }
}

}