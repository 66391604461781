import { reactive } from '@nuxtjs/composition-api'


export default {
  __name: 'queryCondition',
  emits: ['date-change', 'readAll'],
  setup(__props, { emit }) {

const form = reactive({
  date: ''
})



const handleDateChange = (date) => {
  emit('date-change', date)
}

const handleReadAll = () => {
  emit('readAll')
}

return { __sfc: true,form, emit, handleDateChange, handleReadAll }
}

}