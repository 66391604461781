
import { defineComponent, reactive, toRefs, ref, watchEffect, useContext, computed, inject } from '@nuxtjs/composition-api'
import qualityAssure from '@/components/spotMall/qualityAssure.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { JIN_MAO_ID } from '@/constant'

export default defineComponent({
  components: {
    qualityAssure
  },
  props: {
    detailInfo: {
      type: Object,
      defualt: () => {}
    },
    isGoodContent: {
      type: Boolean,
      defualt: true
    }
  },
  setup(props) {
    const context = useContext()
    const { userInfo } = useLoginInfo()
    const $message = inject('$message')
    const { judgeIsLogin } = useMsgDialog()
    const state = reactive({
      warrantyUrl: ''
    })

    const goodsInfo = reactive({
      resourceId: '',
      corpId: '',
      categoryName: '',
      material: '',
      spec: '',
      origin: '',
      qualityInfo: '',
      sizeInfo: '',
      repositoryName: '',
      warehouseDate: '',
      measure: '',
      remark: '',
      warranty: ''
    })
    watchEffect(() => {
      Object.assign(goodsInfo, { ...props.detailInfo })
    })
    const qualityAssureRef = ref(null)
    const showQuality = async () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (
        goodsInfo.warranty == 3 ||
        (goodsInfo.corpId == JIN_MAO_ID && ['鞍钢', '湘钢'].includes(goodsInfo.origin) && goodsInfo.qualityInfo.includes('分割'))
      ) {
        const { statusCode, data } = await context.$api.goods.getWarranty({ resourceId: goodsInfo.resourceId })
        if (statusCode == 200) {
          if (data) {
            state.warrantyUrl = data ?? ''
            qualityAssureRef.value.dialogVisible = true
          } else {
            $message.warning('抱歉，暂时无法查询到质保书')
          }
        }
      } else if (goodsInfo.warranty != 3 && goodsInfo.corpId == JIN_MAO_ID && goodsInfo.origin.includes('南钢')) {
        const { statusCode, data, message } = await context.$api.goods.getNgChapter({ resourceId: goodsInfo.resourceId })
        if (statusCode == 200) {
          state.warrantyUrl = data ?? ''
          qualityAssureRef.value.dialogVisible = true
        } else if (statusCode == 60010) {
          $message.warning(message)
        } else {
          $message.error(message)
        }
      }
    }
    const fileTypes = new Map([
      [1, '否'],
      [2, '有(未上传)'],
      [3, '有(已上传)']
    ])

    const getWarrantyState = () => {
      if (!goodsInfo.warranty) {
        return '否'
      } else {
        if (
          (goodsInfo.warranty != 3 && goodsInfo.corpId == JIN_MAO_ID && goodsInfo.origin.includes('南钢')) ||
          (goodsInfo.corpId == JIN_MAO_ID && ['鞍钢', '湘钢'].includes(goodsInfo.origin) && goodsInfo.qualityInfo.includes('分割'))
        ) {
          return '有(已上传)'
        }
        return fileTypes.get(+goodsInfo.warranty)
      }
    }

    const warrantyDisabled = computed(
      () =>
        goodsInfo.warranty == 3 ||
        (goodsInfo.warranty != 3 && goodsInfo.corpId == JIN_MAO_ID && goodsInfo.origin.includes('南钢')) ||
        (goodsInfo.corpId == JIN_MAO_ID && ['鞍钢', '湘钢'].includes(goodsInfo.origin) && goodsInfo.qualityInfo.includes('分割'))
    )

    const openQualityDialog = (url) => {
      state.warrantyUrl = url ?? ''
      qualityAssureRef.value.dialogVisible = true
    }
    return {
      ...toRefs(state),
      goodsInfo,
      qualityAssureRef,
      showQuality,
      fileTypes,
      getWarrantyState,
      warrantyDisabled,
      openQualityDialog
    }
  }
})
