
import { computed, defineComponent, ref, watch, useRoute } from '@nuxtjs/composition-api'
import searchCheckBox from './searchCheckBox.vue'
import searchInput from '~/components/basic/SearchInput.vue'
export default defineComponent({
  components: {
    searchInput,
    searchCheckBox
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    labelKey: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    isSearch: {
      type: Boolean,
      default: false
    },
    isBtn: {
      type: Boolean,
      default: true
    },
    isCheckBtn: {
      type: Boolean,
      default: true
    },
    searchKey: {
      type: String,
      default: ''
    },
    isArea: {
      type: Boolean,
      default: false
    },
    isAllCheck: {
      type: Boolean,
      default: false
    },
    sortItems: {
      type: [Object, Array],
      default: () => {}
    },
    newTag: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const route = useRoute()
    const placeholder = computed(() => {
      const res = props.label.replace('：', '')
      return `请输入${res}`
    })
    const searchValue = ref('')
    const radioValue = ref('')
    const checkValue = ref([])
    const isCheck = ref(false)
    const selectArr = ref([])

    const isMore = ref(false)
    const doSure = () => {
      isCheck.value = false
      searchValue.value = ''
      emit('change', checkValue.value.join(','))
    }
    const onCheck = () => {
      radioValue.value = ''
      isCheck.value = !isCheck.value
    }

    const onMore = () => {
      isMore.value = !isMore.value
    }
    const nullTip = ref(false)
    const showSearchValue = ref('')
    const doSearch = () => {
      selectArr.value = props.items
      if (searchValue.value) {
        isCheck.value = false
        console.log(props.items, 'props.items')
        selectArr.value = selectArr.value.filter((_) => _.value.toLowerCase().includes(searchValue.value.toLowerCase()))
        showSearchValue.value = searchValue.value
        if (selectArr.value.length) {
          nullTip.value = false
          emit('likeSearch', { searchKey: props.searchKey, value: searchValue.value, labelKey: props.labelKey })
        } else {
          nullTip.value = true
          emit('likeSearch', { searchKey: props.searchKey, value: searchValue.value, labelKey: props.labelKey })
        }
      } else {
        nullTip.value = false
        emit('likeSearch', { searchKey: props.searchKey, value: '', labelKey: props.labelKey })
      }
    }
    watch(
      () => searchValue.value,
      () => {
        if (!searchValue.value) {
          selectArr.value = props.items
          nullTip.value = false
          emit('likeSearch', { searchKey: props.searchKey, value: '' })
        }
      }
    )
    watch(
      () => props.items,
      () => {
        selectArr.value = props.items
        nullTip.value = false
        if (route.value.query[props.searchKey]) {
          const data = route.value.query[props.searchKey]
          showSearchValue.value = data
          selectArr.value = props.items
          isCheck.value = false
          selectArr.value = selectArr.value.filter((_) => _.value.toLowerCase().includes(data.toLowerCase()))
          if (selectArr.value.length) {
            nullTip.value = false
          } else {
            nullTip.value = true
          }
        }
      },
      { immediate: true }
    )
    watch(
      () => props.value,
      () => {
        if (props.value.includes(',')) {
          checkValue.value = props.value.split(',')
        } else {
          radioValue.value = props.value
          checkValue.value = props.value ? [props.value] : []
        }
      },
      { immediate: true }
    )
    watch(
      () => route.value.query[props.searchKey],
      (newValue, oldValue) => {
        if (!newValue && !route.value.query[props.labelKey]) {
          selectArr.value = props.items
          nullTip.value = false
          searchValue.value = ''
        }
      }
    )
    const clickRadio = (data) => {
      checkValue.value = []
      searchValue.value = ''
      emit('change', data)
    }
    const doCancel = () => {
      checkValue.value = []
      isCheck.value = false
      if (props.value.includes(',')) {
        checkValue.value = props.value.split(',')
      } else {
        radioValue.value = props.value
        checkValue.value = []
      }
    }
    const newTagArr = [
      // '方管'
    ]
    return {
      placeholder,
      isCheck,
      isMore,
      checkValue,
      doSure,
      onCheck,
      onMore,
      searchValue,
      doSearch,
      clickRadio,
      radioValue,
      selectArr,
      nullTip,
      showSearchValue,
      doCancel,
      newTagArr
    }
  }
})
