import { ref, reactive, inject, useContext } from '@nuxtjs/composition-api'
import { numSix } from '@/utils/validate'


export default {
  __name: 'setPayPwdDialog',
  emits: 'setSuccess',
  setup(__props, { expose, emit }) {

const context = useContext()
const checkVisible = ref(false)
const checkForm = reactive({
  password: '',
  passwordAgain: ''
})
const checkRules = {
  password: numSix(),
  passwordAgain: [{ validator: validateRePwd, trigger: 'blur', required: true }]
}
function validateRePwd(rule, value, callback) {
  if (value === '') {
    callback(new Error('请再次输入新密码'))
  } else if (value !== checkForm.password) {
    callback(new Error('两次输入密码不一致'))
  } else {
    callback()
  }
}
const checkRef = ref(null)
const $message = inject('$message')

const onSubmit = () => {
  checkRef.value.validate(async (valid) => {
    if (valid) {
      const { statusCode } = await context.$api.account.setPwdFee({
        lastestPayPassword: checkForm.password
      })
      if (statusCode == 200) {
        $message.success('设置支付密码成功')
        checkVisible.value = false
        emit('setSuccess')
      }
    }
  })
}
const closeDialog = () => {
  if (checkRef.value) {
    checkRef.value.resetFields()
  }
}

expose({
  checkVisible,
  checkRef
})

return { __sfc: true,context, checkVisible, checkForm, checkRules, validateRePwd, checkRef, $message, emit, onSubmit, closeDialog }
}

}