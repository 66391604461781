
import { defineComponent } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'

export default defineComponent({
  props: {
    numberList: {
      type: Array,
      default: () => []
    },
    orderType: String
  },
  setup() {
    const { pushBcs } = useLink()
    return {
      pushBcs
    }
  }
})
