import { useContext, useStore, onMounted, computed } from '@nuxtjs/composition-api'
import searchTypeInput from '@/components/homeHeader/searchTypeInput.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { HOT_LINE } from '@/constant'


export default {
  __name: 'headSearch',
  setup(__props) {

const context = useContext()
const store = useStore()
const { push } = useLink()
const { userInfo, loginCorpId, isCartAuth } = useLoginInfo()

const pushCarts = () => {
  if (!userInfo.value) {
    push('/login', { redirect: '/shop/carts' })
    return
  }
  push('/shop/carts')
}

const handleWantBuy = () => {
  if (!userInfo.value) {
    push('/login', { redirect: '/wantBuy' })
    return
  }
  push('/wantBuy')
}
const cartsNum = computed(() => store.getters.cartsNumber + 0)
onMounted(() => {
  store.commit('spotMall/SET_CARTSNUMBER', 0)
  if (userInfo.value && loginCorpId.value && isCartAuth.value) {
    store.dispatch('spotMall/GET_CARTSNUMBER', context)
  }
})

return { __sfc: true,context, store, push, userInfo, loginCorpId, isCartAuth, pushCarts, handleWantBuy, cartsNum, searchTypeInput, HOT_LINE }
}

}