import { useContext, ref, reactive, toRefs, inject, watch } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'uploadFile',
  props: {
  modelValue: {
    type: Array,
    default: () => []
  },
  limit: {
    type: Number,
    default: 6
  },
  limitInfo: {
    type: Object,
    default() {
      return {
        fileSize: 20
      }
    }
  },
  fileType: {
    type: String,
    default: 'jpg,png'
  },
  multiple: {
    type: Boolean,
    default: true
  },
  params: {
    type: Object,
    default: () => {}
  }
},
  emits: ['change'],
  setup(__props, { expose, emit }) {

const props = __props;





const context = useContext()
const { pushBlank } = useLink()
const $message = inject('$message')
const uploadRef = ref(null)
const state = reactive({
  fileList: [],
  srcList: [],
  file: '',
  uploadNum: 0,
  source: null,
  loading: false
})
const { fileList, srcList, uploadNum, loading, source } = toRefs(state)
const handleFileType = (fileUrl, fileType) => {
  if (fileUrl) {
    const fileTypes = fileType.split(',')
    let match = false
    fileTypes.forEach((type) => {
      if (fileUrl.endsWith(type.toLowerCase()) || fileUrl.endsWith(type.toUpperCase())) match = true
    })
    return match
  } else {
    return false
  }
}
const handlebeforeUpload = (file) => {
  const arrayName = file.name.split('.')
  const fileType = arrayName[arrayName.length - 1]
  const fileTypesArr = props.fileType.split(',').map((_) => _.replace('.', ''))
  if (!fileTypesArr.includes(fileType.toLowerCase()) && !fileTypesArr.includes(fileType.toUpperCase())) {
    $message.warning(`文件格式错误，请重新上传`)
    return false
  }
  const isLt2M = file.size / 1024 / 1024 <= props.limitInfo.fileSize
  if (!isLt2M) {
    $message.warning(`文件大小不能超过${props.limitInfo.fileSize}M，请重新上传`)
    return false
  }
  state.file = file
  state.uploadNum++
}

const handleRemove = (file, fileList, fileIndex) => {
  const uploadFiles = uploadRef.value.uploadFiles
  for (let i = 0; i < uploadFiles.length; i++) {
    if (uploadFiles[i].uid === file.uid) {
      uploadFiles.splice(i, 1)
    }
  }
  state.fileList = state.fileList.filter((res) => res.name !== file.name)
  state.uploadNum = state.fileList.length
  emit('change', state.fileList)
}
const handleBeforeRemove = () => {
  state.source && state.source.cancel()
  uploadRef.value.clearFiles()
}
const handleHttpRequest = (param) => {
  state.loading = true
  param.onProgress({ percent: 0 })
  const form = new FormData()
  form.append('file', state.file)
  form.append('sceneType', props.params.sceneType)
  form.append('attachType', props.params.attachType)
  state.source = context.$axios.CancelToken.source()
  const prom = new Promise((resolve, reject) => {
    context.$api.c2m
      .uploadFile(form, {
        cancelToken: state.source.token,
        onUploadProgress: (progress) => {
          if (progress.lengthComputable) {
            const uploadprogressPercentage = (progress.loaded / progress.total).toFixed(2) * 100 // 进度条百分比
            param.onProgress({ percent: uploadprogressPercentage })
          }
        }
      })
      .then(({ data, statusCode }) => {
        state.source = null
        state.loading = false
        if (statusCode == 200) {
          param.onProgress({ percent: 100 })
          const list = {
            name: param.file.name,
            url: data.fileUrl,
            uuid: data.uuid,
            uid: data.uuid
          }
          state.fileList.push(list)
          emit('change', state.fileList)
        }
        resolve()
      })
      .catch(() => {
        state.source = null
        state.loading = false
      })
  })
  prom.abort = () => {}
  return prom
}
const handleViewPdf = (file) => {
  const tempFile = state.fileList.find((_) => _.uid == file.uid)
  pushBlank(tempFile.fullPath, true)
}
const handleExceed = () => {
  $message.warning(`上传失败！超过上传限制:${props.limit}个`)
}

watch(
  () => props.modelValue,
  (newV) => {
    state.fileList = newV
  },
  {
    immediate: true
  }
)

expose({
  handleBeforeRemove,
  uploadRef,
  ...toRefs(state)
})

return { __sfc: true,props, emit, context, pushBlank, $message, uploadRef, state, fileList, srcList, uploadNum, loading, source, handleFileType, handlebeforeUpload, handleRemove, handleBeforeRemove, handleHttpRequest, handleViewPdf, handleExceed }
}

}