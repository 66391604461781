import { ref } from '@nuxtjs/composition-api'
import { noticeTypeMap } from '@/constant/home'
import { addDays } from '@/utils/tool'


export default {
  __name: 'queryCondition',
  props: {
  queryType: String,
  selectTypes: {
    type: Array,
    default: () => []
  }
},
  emits: 'change',
  setup(__props, { emit }) {

const props = __props;





const searchForm = ref({
  type: '',
  date: []
})
const handleChange = () => {
  const { date, ...params } = searchForm.value
  if (searchForm.value.date) {
    const [pushStartTime = '', pushEndTime = ''] = date
    Object.assign(params, { pushStartTime, pushEndTime: addDays(pushEndTime, 1) })
  } else {
    Object.assign(params, { pushStartTime: '', pushEndTime: '' })
  }
  emit('change', params)
}

return { __sfc: true,props, emit, searchForm, handleChange, noticeTypeMap }
}

}