import { useContext, onMounted } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import headerMenuList from '~/components/layoutComp/headerMenuList'
import tipMessage from '~/components/layoutComp/tipMessage'

import { getParams } from '@/utils/sso'

export default {
  __name: 'PortalHeader',
  setup(__props) {

const { userInfo, loginCorpId } = useLoginInfo()
const context = useContext()
const { $config } = context

const gotoLogin = () => {
  window.location.href = `${$config.ssoUrl}/login${getParams()}`
}
const gotoRegister = () => {
  window.location.href = `${$config.ssoUrl}/register${getParams()}`
}

onMounted(() => {})

return { __sfc: true,userInfo, loginCorpId, context, $config, gotoLogin, gotoRegister, headerMenuList, tipMessage }
}

}