import { useContext } from '@nuxtjs/composition-api'
import { formatDate, formatNumber } from '@/utils/tool'
import { exportXls } from '@/utils/excel'
import { useLink } from '@/hooks/useLink'
import { JIN_MAO } from '@/constant'


export default {
  __name: 'bidNotice',
  props: {
  detailInfo: Object
},
  setup(__props) {

const props = __props;



const context = useContext()
const { push } = useLink()
const handleDetail = () => {
  push(`${props.detailInfo.childType == 1 ? '/bid/pack' : '/bid/single'}/${props.detailInfo.auctionNo}`)
}
const handleDownload = async () => {
  const data = await context.$api.home.dowmloadTemp()
  exportXls(data, '销售合同模板.doc')
}

return { __sfc: true,props, context, push, handleDetail, handleDownload, formatDate, formatNumber, JIN_MAO }
}

}