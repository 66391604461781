import { computed, useStore, useContext, onMounted } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import headerMenuList from '~/components/layoutComp/headerMenuList'
import tipMessage from '~/components/layoutComp/tipMessage'

import { getParams } from '@/utils/sso'

export default {
  __name: 'Header',
  props: {
  layout: {
    type: String,
    default: 'default'
  }
},
  setup(__props) {

const props = __props;


const context = useContext()
const store = useStore()
const { userInfo, loginCorpId } = useLoginInfo()
const { $config } = context
const title = computed(() => store.getters.title)
const gotoLogin = () => {
  window.location.href = `${$config.ssoUrl}/login${getParams()}`
}
const gotoRegister = () => {
  window.location.href = `${$config.ssoUrl}/register${getParams()}`
}

onMounted(() => {})

return { __sfc: true,props, context, store, userInfo, loginCorpId, $config, title, gotoLogin, gotoRegister, headerMenuList, tipMessage }
}

}