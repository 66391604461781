export default {
  __name: 'TabsArrow',
  props: {
  menuList: {
    type: Array,
    default() {
      return []
    }
  },
  bankFlag: {
    type: Boolean,
    default: false
  },
  tabIndex: {
    type: Number,
    default() {
      return 0
    }
  }
},
  emits: 'changeTab',
  setup(__props, { emit }) {

const props = __props;





const changeMenu = (index, item) => {
  emit('changeTab', index, item)
}
const badgeFilter = (count) => {
  const hightCore = 999
  if (+count > hightCore) {
    return hightCore + '+'
  } else {
    return +count
  }
}

return { __sfc: true,props, emit, changeMenu, badgeFilter }
}

}