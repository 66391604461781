
import { defineComponent, useStore, onUnmounted, useAsync, useContext, onMounted } from '@nuxtjs/composition-api'
import topAdvert from '@/components/home/topAdvert.vue'
import navSearchBar from '@/components/homeHeader/navSearchBar.vue'
export default defineComponent({
  components: {
    navSearchBar,
    topAdvert
  },
  setup() {
    const store = useStore()
    const context = useContext()
    useAsync(async () => {
      await store.dispatch('app/GET_CATEGORYLIST', context)
    })
    onUnmounted(() => {
      store.commit('loginDialog/SET_DIALOGVISIBLE', false)
      store.commit('tipMsgDialog/SET_TIPMSGDIALOGVISIBLE', false)
    })
  }
})
