import { ref } from '@nuxtjs/composition-api' // toRefs


export default {
  __name: 'company',
  setup(__props) {

const info = ref([
  { name: '企业全称: ', content: '江苏金贸钢宝电子商务股份有限公司' },
  { name: '纳税人识别号：', content: '9132010056286146XP' },
  { name: '开户行及账号：', content: '中国银行南京南钢支行 528758216686' },
  { name: '地址：', content: '南京市江北新区智达路6号智城园区2号楼4楼' },
  { name: '电话：', content: '025-57080020' }
])

return { __sfc: true,info }
}

}