
import { defineComponent, nextTick, reactive, ref } from '@nuxtjs/composition-api'
import goodsListItem from './goodsListItem'
import { requiredInput } from '@/utils/validate'
import { scrollToError } from '@/utils/scrollToError'
export default defineComponent({
  components: {
    goodsListItem
  },
  setup() {
    const formRef = ref(null)
    const getInitGoodsList = () => {
      return {
        categoryName: '',
        material: '',
        spec: '',
        origin: '',
        weight: undefined,
        priceLow: undefined,
        priceHigh: undefined
      }
    }
    const editForm = reactive({
      goodsList: [getInitGoodsList()]
    })
    const rules = {
      categoryName: requiredInput('请填写品名'),
      material: requiredInput('请填写材质'),
      spec: requiredInput('请填写规格')
    }

    const handleAdd = () => {
      editForm.goodsList.push(getInitGoodsList())
    }
    const handleDelete = (index) => {
      editForm.goodsList.splice(index, 1)
    }
    const validateForm = () => {
      formRef.value.validate((valid) => {
        if (!valid) {
          nextTick(() => {
            scrollToError()
          })
        }
      })
    }

    const restForm = () => {
      formRef.value.resetFields()
      editForm.goodsList = [getInitGoodsList()]
    }
    return {
      formRef,
      rules,
      editForm,
      handleDelete,
      handleAdd,
      validateForm,
      requiredInput,
      restForm
    }
  }
})
