import { inject, reactive, toRefs, useContext, useStore, watch, computed } from '@nuxtjs/composition-api'
import { HOT_LINE } from '@/constant'
import { setCorpId } from '@/utils/auth'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { filterPhone } from '@/utils/tool'


export default {
  __name: 'choiceCorpDialog',
  props: {
  corps: {
    type: Array,
    default: () => []
  }
},
  emits: 'change',
  setup(__props, { expose, emit }) {

const props = __props;




const $message = inject('$message')
const context = useContext()
const store = useStore()
const { userInfo, loginCorpInfo, loginCorpId } = useLoginInfo()
const state = reactive({
  corpSelectDialogVisible: false,
  showClose: false,
  accountType: '',
  selectCorp: {
    corpId: ''
  }
})

// const showClose = computed(() => {
//   return props.corps.every((_) => _.corpStatus == 0 || _.status == 0)
// })

watch(
  () => userInfo.value,
  () => {
    if (userInfo.value?.loginCorpId) {
      state.accountType = '1'
      state.selectCorp = loginCorpInfo.value
    } else {
      state.accountType = '2'
      state.selectCorp = userInfo.value
    }
  }
)

watch(
  () => loginCorpInfo.value,
  () => {
    state.selectCorp = loginCorpInfo.value
  }
)

const choiceCorp = async (corpId) => {
  const { statusCode, message } = await context.$api.auth.choiceCorp({
    corpId
  })
  if (statusCode == 200) {
    setCorpId(context, corpId)
    await store.dispatch('app/GET_USERINFO', context)
    await store.dispatch('app/GET_PERMISSION', context)
    if (corpId) {
      await store.dispatch('tipMsgDialog/GET_OVERDELIVERY', context)
      await store.dispatch('tipMsgDialog/GET_CORPIDFILE', context)
      await store.dispatch('tipMsgDialog/GET_C2MDELIVERY', context)
      window.localStorage.setItem(`overDelivery-${loginCorpId.value}`, store.getters.overDelivery || 0)
      window.localStorage.setItem(`corpIdFile-${loginCorpId.value}`, JSON.stringify(store.getters.corpIdFile))
      window.localStorage.setItem(`c2mDelivery-${loginCorpId.value}`, Number(store.getters.c2mDelivery) || 0)
    }
    emit('change')
  } else {
    $message.error(message)
  }
}

const saveCorpChange = async () => {
  state.corpSelectDialogVisible = false
  if (state.accountType == 1) {
    await choiceCorp(state.selectCorp.corpId)
  } else {
    choiceCorp()
  }
}

const changeRadio = (value) => {
  if (value == 2 && !loginCorpInfo.value) {
    state.selectCorp = userInfo.value
  } else if (loginCorpInfo.value) {
    state.selectCorp = loginCorpInfo.value
  } else {
    state.selectCorp = props.corps[0]
  }
}
const openDialog = () => {
  if (userInfo.value?.loginCorpId) {
    state.accountType = '1'
    state.selectCorp = loginCorpInfo.value
  } else {
    state.accountType = '2'
    state.selectCorp = userInfo.value
  }
}

expose({
  ...toRefs(state)
})

return { __sfc: true,props, emit, $message, context, store, userInfo, loginCorpInfo, loginCorpId, state, choiceCorp, saveCorpChange, changeRadio, openDialog, HOT_LINE, filterPhone }
}

}