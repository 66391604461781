
import { defineComponent, reactive, toRefs, ref, useContext, inject } from '@nuxtjs/composition-api'
import topUpTip from '@/components/shopCashier/topUpTip.vue'
import { numSix, numberRegSix } from '@/utils/validate'
import { useMsgDialog } from '@/hooks/useMsgDialog'

export default defineComponent({
  components: {
    topUpTip
  },
  props: {
    rowObj: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const $message = inject('$message')
    const state = reactive({
      dialogVisible: false,
      pwdFlag: false,
      isAgree: false,
      balance: 0
    })
    const editForm = ref({
      payPassword: ''
    })
    const rules = {
      payPassword: numSix('支付密码不能为空')
    }
    const changePwd = (value) => {
      if (numberRegSix.test(value)) {
        state.pwdFlag = true
      } else {
        state.pwdFlag = false
      }
    }
    const formRef = ref(null)
    const closeDialog = () => {
      state.pwdFlag = false
      state.isAgree = false
      formRef.value.resetFields()
    }

    const getPayBalance = async () => {
      const { data, statusCode } = await context.$api.pay.getPayChannel({
        channel: 'P01'
      })
      if (statusCode == 200) {
        const { P01 } = data
        state.balance = P01
      }
    }

    const { showErrorTip } = useMsgDialog()
    const handleSure = async () => {
      const { data, statusCode, message } = await context.$api.bid.joinAuction({
        auctionNo: props.rowObj.auctionNo,
        payPwd: editForm.value.payPassword
      })
      if (statusCode == 200) {
        state.dialogVisible = false
        emit('joinSuccess')
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }

    return {
      ...toRefs(state),
      editForm,
      rules,
      changePwd,
      formRef,
      closeDialog,
      getPayBalance,
      handleSure
    }
  }
})
