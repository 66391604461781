
import { defineComponent, onMounted, useRoute, useStore, reactive, toRefs, useContext, useMeta } from '@nuxtjs/composition-api'
import siderBar from './helpcenter/layout/siderBar.vue'
import { helpcenterMenus } from '@/utils/menu'
import { exportXls } from '@/utils/excel'
export default defineComponent({
  components: {
    siderBar
  },
  layout: 'portalDefault',
  middleware: 'helpRedirect',
  setup() {
    const context = useContext()
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      openeds: ['rules', 'guides', 'mode', 'contact']
    })
    onMounted(() => {
      store.commit('app/SET_TITLE', '帮助中心')
    })
    const handleDownload = async () => {
      const data = await context.$api.home.dowmloadTemp()
      exportXls(data, '销售合同模板.doc')
    }
    return {
      ...toRefs(state),
      helpcenterMenus,
      route,
      handleDownload
    }
  },
  head: {
    title: '帮助中心-金陵钢宝网'
  }
})
