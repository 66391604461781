
import { defineComponent, onMounted, onUnmounted, provide, reactive, ref, toRefs, useContext, useRoute, watch } from '@nuxtjs/composition-api'
import flowChart from '@/components/bid/flowChart.vue'
import messageScroll from '@/components/bid/messageScroll.vue'
import sessionDisplay from '@/components/bid/sessionDisplay.vue'
import searchTableList from '@/components/bid/pack/searchTableList.vue'
import { useSyncTime } from '@/hooks/useSyncTime'
import { bidRefreshSecond } from '@/constant/bid'
import { useLink } from '@/hooks/useLink'
export default defineComponent({
  components: {
    flowChart,
    messageScroll,
    sessionDisplay,
    searchTableList
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const route = useRoute()
    const state = reactive({
      sessionData: [],
      bidNotices: [],
      isSession: false
    })
    const getCondition = () => {
      return {
        bidStartTime: '',
        bidEndTime: '',
        sortName: '',
        sortType: '2',
        designation: '',
        auctionName: ''
      }
    }
    const condition = reactive(getCondition())

    const tableListRef = ref(null)
    const getSessionAsync = async () => {
      tableListRef.value.getListAsync()
      const { data, statusCode } = await context.$api.bid.getPackageBidParam()
      if (statusCode == 200) {
        state.sessionData = data
      }
    }

    const getNoticeList = async () => {
      const { data, statusCode } = await context.$api.home.getNoticeList({
        limit: 5,
        page: 1,
        type: '竞价公告',
        childType: 1
      })
      if (statusCode == 200) {
        const { rows } = data
        state.bidNotices = rows
      }
    }

    const { currentTime } = useSyncTime({
      getListAsync: getSessionAsync,
      freshSecond: bidRefreshSecond,
      isRefreshSecond: true
    })

    provide('syncTime', currentTime)

    watch(
      () => [currentTime.value],
      ([currentTimeNewVal]) => {
        const time = currentTimeNewVal / 1000 / 60 / 30
        if (typeof time === 'number' && time % 1 === 0) {
          setTimeout(() => {
            getSessionAsync()
            getNoticeList()
          }, 1000 * 1)
        }
      }
    )

    const handleVisiable = (e) => {
      const isHidden = e.target.hidden
      if (isHidden === false) {
        getSessionAsync()
        getNoticeList()
      }
    }
    const handleClick = (obj) => {
      Object.assign(condition, { ...getCondition() }, { ...obj })
    }
    const handTimeout = () => {
      Object.assign(condition, {
        bidStartTime: '',
        bidEndTime: ''
      })
      setTimeout(() => {
        getSessionAsync()
      })
    }

    onMounted(() => {
      Object.assign(condition, { ...getCondition() }, { ...route.value.query })
      Object.assign(tableListRef.value.editForm, { ...route.value.query })
      getSessionAsync()
      getNoticeList()
      document.addEventListener('visibilitychange', handleVisiable)
    })
    onUnmounted(() => {
      document.removeEventListener('visibilitychange', handleVisiable)
    })
    return {
      ...toRefs(state),
      tableListRef,
      currentTime,
      condition,
      handleClick,
      handTimeout
    }
  },
  head: {
    title: '打包竞价-金陵钢宝网'
  }
})
