import { ref, reactive, watch, toRefs } from '@nuxtjs/composition-api'
import UploadFile from './uploadFile.vue'
import { provideMaterialsMap, qualityHandlingMap, oddmentsHandleMap } from '@/constant/demand'
import { useLoginInfo } from '@/hooks/useLoginInfo'


export default {
  __name: 'demand',
  props: {
  info: {
    type: Object,
    default: () => {}
  }
},
  emits: ['save'],
  setup(__props, { expose, emit }) {

const props = __props;




const { userInfo } = useLoginInfo()

const pickerOptions = {
  disabledDate(time) {
    return time.getTime() < Date.now() - 8.64e7
  }
}

const editFormRef = ref(null)
const obj = {
  provideMaterials: 0,
  sampleFileList: [],
  machiningDemandInfo: '详见图纸',
  requiredCompletionDate: '',
  materialRequirements: '',
  originRequirements: '',
  demandDeliveryMethod: '0',
  demandSellerDelivery: '',
  areaName: [],
  qualityObjectionHandling: '0',
  oddmentsHandle: ''
}
const editForm = reactive({ ...obj })

const state = reactive({
  materialRequirementsOption: 0,
  originRequirementsOption: 0
})

watch(
  () => state.materialRequirementsOption,
  (newVal) => {
    if (newVal == 1) {
      rules.materialRequirements = [{ required: true, message: '请输入材质要求', trigger: 'change' }]
    } else {
      editForm.materialRequirements = ''
      rules.materialRequirements = null
      editFormRef.value.clearValidate('materialRequirements')
    }
  }
)
watch(
  () => state.originRequirementsOption,
  (newVal) => {
    if (newVal == 1) {
      rules.originRequirements = [{ required: true, message: '请输入产地要求', trigger: 'change' }]
    } else {
      editForm.originRequirements = ''
      rules.originRequirements = null
      editFormRef.value.clearValidate('originRequirements')
    }
  }
)

watch(
  () => editForm.demandDeliveryMethod,
  (newVal) => {
    if (newVal == 1) {
      rules.demandSellerDelivery = [{ required: true, message: '请输入收货地址', trigger: 'change' }]
    } else {
      editForm.demandDeliveryMethod = '0'
      editForm.demandSellerDelivery = null
      rules.demandSellerDelivery = null
      editFormRef.value.clearValidate('demandSellerDelivery')
    }
  }
)

const rules = {
  provideMaterials: [{ required: true, message: '请选择平台提供材料', trigger: 'change' }],
  sampleFileList: [{ type: 'array', required: true, message: '请上传样品图', trigger: 'change' }],
  machiningDemandInfo: [{ required: true, message: '请输入加工要求', trigger: 'blur' }],
  requiredCompletionDate: [{ required: true, message: '请选择要求完工日期', trigger: 'change' }],
  areaName: [{ required: true, message: '请选择收货地区', trigger: 'change' }],
  materialRequirements: null,
  originRequirements: null,
  demandSellerDelivery: null,
  qualityObjectionHandling: [{ required: true, message: '请选择质量异议处理', trigger: 'change' }],
  oddmentsHandle: [{ required: true, message: '请选择余料处理', trigger: 'change' }]
}

const handleSuccess = (response) => {
  editForm.sampleFileList = response
  editFormRef.value.validateField('sampleFileList')
}

const validate = () => {
  return editFormRef.value.validate()
}

const uploadFileRef = ref(null)
const resetFields = () => {
  uploadFileRef.value && uploadFileRef.value.handleBeforeRemove()
  Object.assign(editForm, { ...obj })
  editForm.materialFileList = []
  editFormRef.value.resetFields()
  state.materialRequirementsOption = 0
  state.originRequirementsOption = 0
}

const handleLoginAuth = () => {
  emit('save')
}

const changeProvideMaterials = (val) => {
  if (val == 0) {
    editForm.qualityObjectionHandling = '0'
    editForm.oddmentsHandle = ''
  } else {
    editForm.oddmentsHandle = '0'
    editForm.qualityObjectionHandling = ''
  }
  editFormRef.value.clearValidate('oddmentsHandle')
  editFormRef.value.clearValidate('qualityObjectionHandling')
}

expose({
  ...toRefs(state),
  validate,
  editForm,
  resetFields
})

return { __sfc: true,props, emit, userInfo, pickerOptions, editFormRef, obj, editForm, state, rules, handleSuccess, validate, uploadFileRef, resetFields, handleLoginAuth, changeProvideMaterials, UploadFile, provideMaterialsMap, qualityHandlingMap, oddmentsHandleMap }
}

}