import { ref, inject, nextTick, watch } from '@nuxtjs/composition-api'


export default {
  __name: 'tagLines',
  props: {
  value: {
    type: Array,
    default: () => []
  },
  length: {
    type: Number,
    default: 0
  }
},
  emits: ['update:value', 'change'],
  setup(__props, { emit }) {

const props = __props;



const $message = inject('$message')

const axisArray = ref([])
const lineTags = ref([])

const inputValue = ref('')
const inputVisible = ref(false)
const inputRef = ref(null)


const handleInputConfirm = () => {
  if (lineTags.value.includes(+inputValue.value)) {
    $message.warning('该位置已存在切割线')
    return
  }
  if (inputValue.value && inputValue.value > 0 && inputValue.value < props.length && !lineTags.value.includes(+inputValue.value)) {
    lineTags.value.push(+inputValue.value)
    lineClass(lineTags.value[lineTags.value.length - 1])
  }
  inputVisible.value = false
  inputValue.value = ''
  emit('update:value', lineTags.value)
}

const lineClass = (axis) => {
  if (axisArray.value.includes(axis)) {
    return
  }
  axisArray.value.push(+axis)
}

const handleInputFocus = () => {
  inputValue.value = Math.floor(props.length / 2)
}

const handleClose = (tag) => {
  lineTags.value.splice(lineTags.value.indexOf(tag), 1)
  axisArray.value.splice(axisArray.value.indexOf(tag), 1)
  emit('update:value', lineTags.value)
}

const showInput = () => {
  inputVisible.value = true
  nextTick(() => {
    inputRef.value.focus()
  })
}

watch(
  () => props.value,
  (newValue) => {
    lineTags.value = newValue
  },
  { immediate: true }
)

return { __sfc: true,props, $message, axisArray, lineTags, inputValue, inputVisible, inputRef, emit, handleInputConfirm, lineClass, handleInputFocus, handleClose, showInput }
}

}