import { reactive, toRefs } from '@nuxtjs/composition-api'


export default {
  __name: 'dbButton',
  props: {
  isLoad: {
    type: Boolean,
    default: true
  }
},
  emits: 'click',
  setup(__props, { expose, emit }) {

const props = __props;



const state = reactive({
  loading: false
})



const handleClick = () => {
  if (props.isLoad) {
    state.loading = true
    new Promise((resolve) => {
      emit('click', resolve)
    }).then(() => {
      state.loading = false
    })
  } else {
    emit('click')
  }
}

expose({
  ...toRefs(state)
})

return { __sfc: true,props, state, emit, handleClick }
}

}