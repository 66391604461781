import ruleTemplate from '@/components/helpcenter/ruleTemplate.vue'

export default {
  __name: 'privacyRule',
  setup(__props) {


return { __sfc: true,ruleTemplate }
}

}