
import { defineComponent, reactive, watch, ref, toRefs, onUnmounted, computed } from '@nuxtjs/composition-api'
import { formatDate } from '@/utils/tool'

export default defineComponent({
  props: {
    time: {
      type: String,
      default: ''
    },
    format: {
      type: Array,
      default: () => ['时', '分', '秒']
    },
    syncTime: {
      type: [String, Number, Date],
      default: () => new Date()
    }
  },
  setup(props, { emit }) {
    const countTimeData = reactive({
      hour: 0,
      minute: 0,
      second: 0
    })
    const damagesTime = computed(() => new Date(formatDate(props.time, 'yyyy/MM/dd hh:mm:ss')).getTime())
    const currentTime = computed(() =>
      typeof props.syncTime == 'number' ? props.syncTime : new Date(formatDate(props.syncTime, 'yyyy/MM/dd hh:mm:ss')).getTime()
    )
    const timer = ref(null)
    let leftTime = 0
    const countTime = () => {
      if (leftTime >= 0) {
        countTimeData.hour = Math.floor((leftTime / 60 / 60 / 1000) % 24)
        countTimeData.minute = Math.floor((leftTime / 60 / 1000) % 60)
        countTimeData.second = Math.floor((leftTime / 1000) % 60)
        leftTime -= 1000
        clearTimer()
        timer.value = setTimeout(countTime, 1000)
      } else {
        emit('timeout')
        clearTimer()
      }
    }
    const formateCountTime = (time) => {
      const str = time.toString()
      if (str.length == 1) {
        return `0${str}`
      }
      return str
    }
    const clearTimer = () => {
      timer.value && clearTimeout(timer.value)
    }
    const initTime = () => {
      leftTime = damagesTime.value - currentTime.value
      if (leftTime >= 0) {
        countTime()
      } else {
        countTimeData.hour = 0
        countTimeData.minute = 0
        countTimeData.second = 0
      }
      if (leftTime == 1000 * 60 * 4 + 30 * 1000) {
        emit('lastTimeout')
      }
    }
    watch(
      () => [props.time, props.syncTime],
      ([newTime, newSyncTime]) => {
        if (newTime && newSyncTime) {
          // console.log(newTime, newSyncTime)
          initTime()
        }
      },
      {
        immediate: true
      }
    )
    onUnmounted(() => {
      clearTimer()
    })
    return {
      ...toRefs(countTimeData),
      formateCountTime,
      countTime,
      clearTimer
    }
  }
})
