
import { computed, defineComponent, onMounted, ref, useRoute, useStore, useContext } from '@nuxtjs/composition-api'
import feedbackDialog from '@/components/home/feedbackDialog.vue'
import quickEnquiry from '@/components/c2m/quickEnquiry.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'
export default defineComponent({
  components: {
    feedbackDialog,
    quickEnquiry
  },
  setup() {
    const context = useContext()
    const { $config } = context
    const { userInfo } = useLoginInfo()
    const { push } = useLink()
    const { judgeIsLogin } = useMsgDialog()
    const route = useRoute()
    const whitePath = ['/']
    const showRoute = computed(() => {
      const nowPath = route.value.path
      return whitePath.includes(nowPath)
    })
    const shopPage = computed(
      () =>
        (route.value.path.includes('/shop') || route.value.path.includes('/oddments') || route.value.path.includes('/special')) &&
        !route.value.path.includes('/shop/')
    )
    const totop = ref(false)
    const tobottom = ref(false)
    const speed = 100 * 1000
    const scrolltotop = (e) => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 400) {
        totop.value = true
      } else {
        totop.value = false
      }
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      const scrollHeight = document.documentElement.scrollHeight
      const rollheight = scrollHeight - clientHeight
      const top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
      if (rollheight <= top + 400) {
        tobottom.value = false
      } else {
        tobottom.value = true
      }
      if (top == 0) {
        tobottom.value = false
      }
    }
    const backTOP = () => {
      const back = setInterval(() => {
        if (document.body.scrollTop || document.documentElement.scrollTop) {
          document.body.scrollTop -= speed
          document.documentElement.scrollTop -= speed
        } else {
          clearInterval(back)
        }
      })
    }

    // 点击回到页面底部
    const toBottom = (i) => {
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      const scrollHeight = document.documentElement.scrollHeight
      const rollheight = scrollHeight - clientHeight // 超出窗口上界的值就是底部的scrolTop的值
      document.documentElement.scrollTop += speed
      let c
      if (document.documentElement.scrollTop + 1 <= rollheight) {
        // 之所以+1，可以打印这两个值的日志就知道了，下面+1也是同理
        c = setTimeout(() => toBottom(i), 10) // 调用setTimeout是为了“回到底部”这过程不是一瞬间
      } else {
        clearTimeout(c)
      }
    }

    onMounted(() => {
      window.addEventListener('scroll', scrolltotop)
    })

    const feedbackRef = ref(null)
    const handleFeedback = () => {
      if (!userInfo.value) {
        judgeIsLogin(true)
        return
      }
      feedbackRef.value.resultFlag = false
      feedbackRef.value.formRef && feedbackRef.value.formRef.resetFields()
      feedbackRef.value.dialogVisible = true
    }

    const handleContract = () => {
      push('/helpcenter/contact/service')
    }

    const handleCart = () => {
      if (!userInfo.value) {
        judgeIsLogin(true, '/shop/carts')
        return
      }
      push('/shop/carts')
    }
    const store = useStore()
    const cartsNum = computed(() => store.getters.cartsNumber + 0)

    const quickEnquiryRef = ref(null)
    const handleLinkToC2m = () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      quickEnquiryRef.value.openDialog({})
    }
    return {
      totop,
      tobottom,
      backTOP,
      toBottom,
      showRoute,
      feedbackRef,
      handleFeedback,
      handleContract,
      cartsNum,
      userInfo,
      shopPage,
      handleCart,
      handleLinkToC2m,
      quickEnquiryRef
    }
  }
})
