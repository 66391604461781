
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { formatDate } from '@/utils/tool'
export default defineComponent({
  props: {
    bidNotices: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const { push } = useLink()
    const defaultOption = ref({
      step: 0.2, // 数值越大速度滚动越快
      limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 2 // 0向下 1向上 2向左 3向右
    })
    const handleClick = (e) => {
      if (e.target.dataset.obj) {
        const infoAnync = JSON.parse(e.target.dataset.obj)
        push(`/notice/${infoAnync.id}`)
      }
    }
    return {
      defaultOption,
      push,
      formatDate,
      handleClick
    }
  }
})
