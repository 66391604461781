import { ref, useContext, inject } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useCountDown } from '@/hooks/useCountDown'
import { requiredInput } from '@/utils/validate'


export default {
  __name: 'checkMobileDialog',
  emits: 'show-corps',
  setup(__props, { expose, emit }) {

const context = useContext()
const $message = inject('$message')
const tipDialogVisible = ref(false)
const checkForm = ref({
  mobile: '',
  code: ''
})
const checkRef = ref(null)
const checkRules = {
  code: requiredInput('请输入验证码')
}
const { userInfo } = useLoginInfo()
const { count, playing, countdown } = useCountDown()
const getCode = async () => {
  playing.value = true
  const { statusCode } = await context.$api.sms.getLoginCode({
    mobile: checkForm.value.mobile,
    codeMode: 1
  })
  if (statusCode == 200) {
    countdown()
    $message.success('验证码已发送，请注意查收')
  } else {
    playing.value = false
  }
}
const openTipDialog = () => {
  if (checkRef.value) checkRef.value.resetFields()
  playing.value = false
  checkForm.value.mobile = userInfo.value.mobile
}


const onTipSubmit = () => {
  checkRef.value.validate(async (valid) => {
    if (valid) {
      const { statusCode } = await context.$api.auth.checkChangeCorpCode({
        loginMode: 1,
        ...checkForm.value
      })
      if (statusCode == 200) {
        tipDialogVisible.value = false
        emit('show-corps')
      }
    }
  })
}

expose({
  tipDialogVisible
})

return { __sfc: true,context, $message, tipDialogVisible, checkForm, checkRef, checkRules, userInfo, count, playing, countdown, getCode, openTipDialog, emit, onTipSubmit }
}

}