
import { defineComponent, ref, computed, onMounted, watch, useRoute, useStore, reactive, toRefs } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { formatNumber } from '@/utils/tool'
export default defineComponent({
  props: {
    condition: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const store = useStore()
    const { replace } = useLink()
    const route = useRoute()
    const state = reactive({
      disableDelete: []
    })
    const searchItems = ref([])
    const tagKeys = ref([
      {
        key: 'variety',
        name: '品种',
        data: computed(() => store.getters.varieties)
      },
      {
        key: 'purpose',
        name: '用途',
        data: computed(() => store.getters.purpose)
      },
      {
        key: 'categoryId',
        name: '品名',
        data: computed(() => store.getters.categories)
      },
      {
        key: 'material',
        name: '材质',
        data: computed(() => store.getters.initMaterial)
      },
      {
        key: 'repositoryId',
        name: '仓库',
        data: computed(() => store.getters.initRepoDtos)
      },
      {
        key: 'origin',
        name: '产地',
        data: computed(() => store.getters.initOiginDescs)
      },
      {
        key: 'deliveryPlace',
        name: '交货地',
        data: computed(() => store.getters.initDeliPlaces)
      },
      {
        key: 'measure',
        name: '计量方式',
        data: computed(() => store.getters.measure)
      },
      {
        key: 'sizeInfo',
        name: '尺寸信息',
        data: computed(() => store.getters.sizeInfo)
      },
      {
        key: 'qualityInfo',
        name: '质量信息',
        data: computed(() => store.getters.measure)
      },
      {
        key: 'specialPrice',
        name: '是否特价',
        data: computed(() => store.getters.specialPrice)
      },
      // {
      //   key: 'invoiceList',
      //   name: '开票方',
      //   data: computed(() => store.getters.source)
      // },
      {
        key: 'type',
        name: '资源类型',
        data: computed(() => store.getters.types)
      }
    ])
    const sizeTagKey = [
      {
        key: 'thickness',
        name: '规格厚度'
      },
      {
        key: 'width',
        name: '规格宽度'
      },
      {
        key: 'length',
        name: '规格长度'
      },
      {
        key: 'spec',
        name: '规格'
      },
      {
        key: 'listedPrice',
        name: '价格'
      },
      {
        key: 'repositoryName',
        name: '仓库'
      },
      {
        key: 'materialLike',
        name: '材质'
      },
      {
        key: 'warehouseDate',
        name: '入库日期'
      },
      { key: 'keyWord', name: '关键字' }
    ]
    const sizeTagKeyArr = [
      'thicknessStart',
      'thicknessEnd',
      'widthStart',
      'widthEnd',
      'lengthStart',
      'lengthEnd',
      'spec',
      'repositoryName',
      'materialLike',
      'listedPriceStart',
      'listedPriceEnd',
      'warehouseDateStart',
      'warehouseDateEnd'
    ]
    const tagKeysArr = tagKeys.value.map((_) => _.key)
    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }
    const handleClose = (tag) => {
      const params = transParams(props.condition)
      if (tag) {
        for (const i in params) {
          if (i == tag || i.includes(tag.key)) {
            delete params[i]
          }
        }
        searchItems.value.splice(
          searchItems.value.findIndex((_) => _.key == tag.key),
          1
        )
        if (tag.key == 'keyWord') {
          delete params.sv
          delete params.sk
          delete params.sr
          delete params.keyWord
          delete params.matchList
        }
      } else {
        for (const i in params) {
          if (tagKeysArr.findIndex((_) => _ == i) != -1 || sizeTagKeyArr.findIndex((_) => _ == i) != -1) {
            delete params[i]
          }
        }
        searchItems.value = []
        delete params.sv
        delete params.sk
        delete params.sr
        delete params.keyWord
        delete params.matchList
      }
      delete params.orderFields
      params.page = 1
      replace(route.value.path, { ...params })
      emit('change', { ...params })
    }

    const renderTags = (key) => {
      const labelName = tagKeys.value?.find((_) => _.key == key).name
      const selectArr = []
      let searchData = []
      if (key != 'invoiceList') {
        searchData = props.condition[key].split(',')
      } else {
        searchData = [props.condition[key]]
      }

      searchData.forEach((data) => {
        const obj = tagKeys.value?.find((_) => _.key == key).data?.find((res) => res.key == data)
        if (obj) {
          selectArr.push(obj)
        }
      })
      if (selectArr.length) {
        const selectArrName = selectArr.map((_) => _.value).join(',')
        const tagName = `${labelName}：${selectArrName}`
        searchItems.value.push({
          key,
          name: tagName
        })
      } else {
        const tagName = `${labelName}：${searchData}`
        searchItems.value.push({
          key,
          name: tagName
        })
      }
    }
    const renderSizeTags = (key) => {
      if (key == 'spec' || key == 'repositoryName' || key == 'materialLike' || key == 'keyWord') {
        const labelName = sizeTagKey.find((_) => _.key == key).name
        const tagName = `${labelName}：${props.condition[key]}`
        searchItems.value.push({
          key,
          name: tagName
        })
      } else if (searchItems.value.findIndex((res) => res.key == sizeTagKey.find((_) => key.includes(_.key)).key) == -1) {
        const labelName = sizeTagKey.find((_) => key.includes(_.key)).name
        const start = props.condition[sizeTagKey.find((_) => key.includes(_.key)).key + 'Start'] ?? '~'
        const end = props.condition[sizeTagKey.find((_) => key.includes(_.key)).key + 'End'] ?? '~'
        let tagName
        if (key.includes('listedPrice')) {
          tagName = `${labelName}：${formatNumber(start)}-${formatNumber(end)}`
        } else {
          tagName = `${labelName}：${start}-${end}`
        }
        searchItems.value.push({
          key: sizeTagKey.find((_) => key.includes(_.key)).key,
          name: tagName
        })
      }
    }
    const judgeRouterQuery = (query) => {
      searchItems.value = []
      for (const i in query) {
        if (sizeTagKeyArr.includes(i)) {
          query[i] && renderSizeTags(i)
        } else if (tagKeysArr.includes(i)) {
          query[i] && renderTags(i)
        }
        if (i == 'keyWord') {
          query[i] && renderSizeTags(i)
        }
      }
    }
    const handleDisableDelete = () => {
      const { matchList = '' } = props.condition
      state.disableDelete = []
      if (matchList) {
        const _matchList = JSON.parse(matchList)
        for (const i in _matchList) {
          if (_matchList[i]) {
            state.disableDelete.push(i)
          }
        }
      }
    }

    const getTagDisable = (key) => {
      return !state.disableDelete.includes(key)
    }
    watch(
      () => props.condition,
      () => {
        setTimeout(() => {
          judgeRouterQuery(props.condition)
          handleDisableDelete()
        }, 200)
      },
      { deep: true }
    )

    watch(
      () => store.getters.varieties,
      () => {
        judgeRouterQuery(props.condition)
      }
    )

    onMounted(() => {})
    return {
      searchItems,
      tagKeys,
      handleClose,
      ...toRefs(state),
      getTagDisable
    }
  }
})
