import { useLink } from '@/hooks/useLink'


export default {
  __name: 'sellerCorpItem',
  props: {
  item: Object
},
  setup(__props) {

const props = __props;


const { pushBcs } = useLink()
const handleLink = (item) => {
  if (item.link) {
    pushBcs(item.link, false)
  }
}

return { __sfc: true,props, pushBcs, handleLink }
}

}