
import { computed, defineComponent, ref, useContext, useRoute, watch, onMounted } from '@nuxtjs/composition-api'
import { useTitle } from '@/hooks/useTitle'
import payResult from '~/components/shopCashier/payResult.vue'
import payOrder from '~/components/shopCashier/payOrder.vue'
export default defineComponent({
  name: '',
  components: {
    payResult,
    payOrder
  },
  layout: 'cart',
  setup() {
    useTitle('收银台')
    const context = useContext()
    const route = useRoute()
    const payNumber = computed(() => route.value.query.payNumber)

    const cashRef = ref(null)
    const currentView = ref(payNumber.value ? 'payResult' : 'payOrder')
    watch(
      () => [route.value.query.payNumber, route.value.query.payError],
      ([newPayNumber, newPayError]) => {
        currentView.value = newPayNumber || newPayError ? 'payResult' : 'payOrder'
      }
    )

    onMounted(() => {})
    return {
      currentView,
      cashRef
    }
  }
})
