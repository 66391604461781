import { reactive, ref, useContext, onMounted, toRefs, useStore, watch, computed, onUnmounted } from '@nuxtjs/composition-api'
import bidPack from '@/components/home/components/bidPack.vue'
import bidSingle from '@/components/home/components/bidSingle.vue'
import { useLink } from '@/hooks/useLink'
import { debounce } from '@/utils'
import { useSyncTime } from '@/hooks/useSyncTime'


export default {
  __name: 'bidZone',
  setup(__props, { expose }) {

const context = useContext()
const store = useStore()
const { push } = useLink()
const currentType = ref('bidPack')
const currentTime = computed(() => store.getters.currentTime)
const state = reactive({
  packList: [],
  singleList: []
})
const changeBidType = (type) => {
  currentType.value = type
  if (type == 'bidPack') {
    getPackListAsync()
  } else {
    getSingleListAsync()
  }
}
const handleMore = () => {
  push(currentType.value == 'bidPack' ? '/bid/pack' : '/bid/single')
}
const getPackListAsync = () => {
  debounce(async () => {
    const { data, statusCode } = await context.$api.home.getBidPackList()
    if (statusCode == 200) {
      state.packList = data
    }
  }, 500)
}

const getSingleListAsync = () => {
  debounce(async () => {
    const { data, statusCode } = await context.$api.home.getBidSingleList()
    if (statusCode == 200) {
      state.singleList = data
    }
  }, 500)
}

const getComponent = (component) => {
  if (component == 'bidPack') {
    return bidPack
  } else if (component == 'bidSingle') {
    return bidSingle
  }
}

watch(
  () => currentTime.value,
  (newVal) => {
    const time = newVal / 1000 / 60 / 30
    if (typeof time === 'number' && time % 1 === 0) {
      if (currentType.value == 'bidPack') {
        getPackListAsync()
      } else {
        getSingleListAsync()
      }
      setTimeout(() => {
        if (currentType.value == 'bidPack') {
          getPackListAsync()
        } else {
          getSingleListAsync()
        }
      }, 3 * 1000)
    }
  }
)
const handleVisiable = (e) => {
  const isHidden = e.target.hidden
  if (isHidden === false) {
    getPackListAsync()
    getSingleListAsync()
  }
}
const { getSyncTime } = useSyncTime()

onMounted(() => {
  getPackListAsync()
  document.addEventListener('visibilitychange', handleVisiable)
})
onUnmounted(() => {
  document.removeEventListener('visibilitychange', handleVisiable)
})

expose({
  ...toRefs(state)
})

return { __sfc: true,context, store, push, currentType, currentTime, state, changeBidType, handleMore, getPackListAsync, getSingleListAsync, getComponent, handleVisiable, getSyncTime }
}

}