import { onMounted, reactive, toRefs, useContext } from '@nuxtjs/composition-api'
import { potocolTypeMap } from '@/constant/potocol'


export default {
  __name: 'PotocolDialog',
  props: {
  potocolType: {
    type: String,
    default: 'P01'
  },
  isLink: {
    type: Boolean,
    default: false
  }
},
  emits: 'toLink',
  setup(__props, { expose, emit }) {

const props = __props;




const context = useContext()
const state = reactive({
  dialogVisible: false,
  detail: {},
  wrapperHeight: 0
})
const getScroll = (event) => {
  const scrollBottom = event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight
  //   if (!scrollBottom) {
  //     state.agreeFlag = false
  //   }
}

const handleCilck = () => {
  if (!props.isLink) {
    getPotocolAsync()
  } else {
    emit('toLink')
  }
}
const getPotocolAsync = async () => {
  const { data, statusCode } = await context.$api.potocol.getPotocol({ type: props.potocolType })
  if (statusCode == 200) {
    state.dialogVisible = true
    state.detail = data
  }
}
onMounted(() => {
  state.wrapperHeight = document.body.clientHeight - 400
})

expose({
  ...toRefs(state)
})

return { __sfc: true,props, emit, context, state, getScroll, handleCilck, getPotocolAsync, potocolTypeMap }
}

}