export default {
  __name: 'serviceItem',
  props: {
  item: Object,
  index: Number
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}