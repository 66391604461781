export default {
  __name: 'tabs',
  props: {
  menuList: {
    type: Array,
    default() {
      return []
    }
  },

  tabIndex: {
    type: Number,
    default() {
      return 0
    }
  },
  max: {
    type: Number,
    default() {
      return 999
    }
  }
},
  emits: 'changeTab',
  setup(__props, { emit }) {

const props = __props;



const changeMenu = (index) => {
  emit('changeTab', index)
}
const badgeFilter = (count) => {
  const hightCore = props.max
  if (+count >= hightCore) {
    return hightCore + '+'
  } else {
    return +count
  }
}

return { __sfc: true,props, emit, changeMenu, badgeFilter }
}

}