import { useContext } from '@nuxtjs/composition-api'
import { exportXls } from '@/utils/excel'


export default {
  __name: 'siderBarItem',
  props: {
  router: {
    type: Object,
    default: () => {}
  }
},
  setup(__props) {

const props = __props;



const context = useContext()
const handleDownload = async () => {
  const data = await context.$api.home.dowmloadTemp()
  exportXls(data, '销售合同模板.doc')
}

return { __sfc: true,props, context, handleDownload }
}

}