import { useStore, computed, useRoute } from '@nuxtjs/composition-api'
import searchTypeInput from '@/components/homeHeader/searchTypeInput.vue'


export default {
  __name: 'cartHeader',
  setup(__props) {

const store = useStore()
const title = computed(() => store.getters.title)
const route = useRoute()
const isSearch = computed(() => {
  const whiteList = ['/shop/carts']
  return whiteList.includes(route.value.path)
})

return { __sfc: true,store, title, route, isSearch, searchTypeInput }
}

}