
import { defineComponent, nextTick, reactive, ref, toRefs, useContext } from '@nuxtjs/composition-api'
import goodsList from './components/goodsList'
import { requiredInput, phone } from '@/utils/validate'
import { formatDate } from '@/utils/tool'
import { useSyncTime } from '@/hooks/useSyncTime'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { scrollToError } from '@/utils/scrollToError'
export default defineComponent({
  components: {
    goodsList
  },
  layout: 'home',
  middleware: 'auth',
  setup() {
    const context = useContext()
    const { userInfo } = useLoginInfo()
    const { syncTime, getSyncTime } = useSyncTime()
    const state = reactive({
      loading: false,
      dialogVisible: false
    })
    const pickerOptions = {
      disabledDate(time) {
        return time.getTime() < Date.now() - 8.64e7
      }
    }
    const deliveryType = new Map([
      [1, '买家自提'],
      [2, '商家配送']
    ])
    const endDateMap = new Map([
      [1, '1天'],
      [3, '3天'],
      [7, '7天'],
      [0, '其他时间']
    ])
    const formRef = ref(null)
    const getInitData = () => {
      return {
        address: [],
        endDateType: 7,
        endDate: '',
        deliveryType: 1,
        remark: '',
        concat: userInfo.value.optUserName,
        mobile: userInfo.value.mobile
      }
    }
    const editForm = reactive(getInitData())
    const rules = {
      deliveryType: requiredInput('请选择提货方式', 'change'),
      endDateType: requiredInput('请选择求购截止时间', 'change'),
      endDate: requiredInput('请选择求购截止时间', 'change'),
      concat: requiredInput('联系人不能为空'),
      mobile: phone('手机号不能为空')
    }
    const validateForm = () => {
      formRef.value.validate((valid) => {
        if (!valid) {
          nextTick(() => {
            scrollToError()
          })
        }
      })
    }
    const goodsListRef = ref(null)
    const handleSubmit = async () => {
      state.loading = true
      await getSyncTime()
      const array = [goodsListRef.value.validateForm(), validateForm(), formRef.value.validate(), goodsListRef.value.formRef.validate()]
      Promise.all(array)
        .then(async () => {
          const { data, statusCode } = await context.$api.wantBuy.addWantBuy(getParams())
          state.loading = false
          if (statusCode == 200) {
            state.dialogVisible = true
            goodsListRef.value.restForm()
            formRef.value.resetFields()
            Object.assign(editForm, getInitData())
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
          }
        })
        .catch(() => {
          state.loading = false
        })
    }

    const getParams = () => {
      const { address, endDateType, endDate, ...params } = editForm
      let tempEndDate
      if (endDateType == 0) {
        tempEndDate = endDate
      } else {
        tempEndDate = renderDate(endDateType)
      }
      const goodsList = goodsListRef.value.editForm.goodsList.map((_) => {
        const { weight, priceLow, priceHigh, ...params } = _
        return {
          weight: weight || '',
          priceLow: priceLow || '',
          priceHigh: priceHigh || '',
          ...params
        }
      })
      return {
        ...params,
        address: address.join(''),
        endDate: tempEndDate,
        goodsList
      }
    }

    const renderDate = (day) => {
      const timeStamp = day * 24 * 60 * 60 * 1000 + syncTime.value
      return formatDate(new Date(timeStamp), 'yyyy-MM-dd')
    }

    const handleRadio = (res) => {
      editForm.endDate = ''
    }
    return {
      ...toRefs(state),
      pickerOptions,
      deliveryType,
      endDateMap,
      formRef,
      editForm,
      rules,
      goodsListRef,
      handleSubmit,
      handleRadio
    }
  },
  head: {
    title: '我要求购-金陵钢宝网'
  }
})
