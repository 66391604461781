import { formatDate } from '@/utils/tool'


export default {
  __name: 'bidChangeNotice',
  props: {
  detailInfo: Object
},
  setup(__props) {

const props = __props;



return { __sfc: true,props, formatDate }
}

}