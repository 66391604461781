
import {
  defineComponent,
  onMounted,
  reactive,
  computed,
  ref,
  toRefs,
  useContext,
  useRoute,
  useStore,
  onUnmounted,
  watch,
  useMeta,
  inject
} from '@nuxtjs/composition-api'
import imageSwiper from '@/components/spotMall/imageSwiper.vue'
import goodsDetail from '@/components/spotMall/goodsDetail.vue'
import countdown from '@/components/bid/countdown.vue'
import tipDialog from '@/components/spotMall/tipDialog.vue'
import warrantyTag from '@/components/spotMall/warrantyTag.vue'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { useSyncTime } from '@/hooks/useSyncTime'
import { JIN_MAO } from '@/constant'
import { formatDate, formatNumber } from '@/utils/tool'
import { bidRefreshSecond } from '@/constant/bid'

export default defineComponent({
  components: {
    imageSwiper,
    goodsDetail,
    countdown,
    tipDialog,
    warrantyTag
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const $message = inject('$message')
    const store = useStore()
    const route = useRoute()
    const { push } = useLink()
    const { userInfo, loginCorpId, bidSingleAuth, loginCorpAuth } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
    const penaltyMoney = computed(() => store.getters.transactionParams?.entity?.penaltyMoney)
    const auctionNo = computed(() => route.value.params.id)
    const state = reactive({
      isSkeleton: true,
      tipMessage: '',
      startTime: '',
      intervalTime: null
    })
    const detailInfo = reactive({
      categoryName: '',
      material: '',
      spec: '',
      origin: ''
    })
    const swiperImgs = computed(() => {
      if (detailInfo.goodsUrl) {
        const urlArr = detailInfo.goodsUrl.split(',')
        return urlArr
      } else {
        return []
      }
    })

    const invoiceModeTypes = ref({
      '平台开票(第二方)': JIN_MAO,
      '商家开票(第三方)': ''
    })
    const invoiceModeName = computed(() => invoiceModeTypes.value[detailInfo.invoiceMode])
    const tipRef = ref(null)
    const getSingleBidGoodsDetail = async () => {
      const { statusCode, data } = await context.$api.bid.getSingleBidGoodsDetail({ auctionNo: auctionNo.value })
      if (statusCode == 200) {
        if (data) {
          state.isSkeleton = false
          Object.assign(detailInfo, data)
          if (detailInfo.invoiceMode == '商家开票(第三方)') {
            invoiceModeTypes.value[detailInfo.invoiceMode] = detailInfo.corpName
          }
          state.startTime = `${formatDate(data.bidStartTime, 'yyyy-MM-dd hh:mm')}-${formatDate(data.bidEndTime, 'hh:mm')}`
        } else {
          tipRef.value.dialogVisible = true
        }
      }
    }
    const detailInfoTitle = computed(() => {
      return `${detailInfo.categoryName} ${detailInfo.material} ${detailInfo.spec ?? ''} ${detailInfo.origin}`
    })
    useMeta(() => ({
      title: detailInfoTitle.value
    }))
    const confirmRef = ref(null)
    const handleOffer = async (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!bidSingleAuth.value) {
        judgeIsAuth()
        return
      }
      const { data, statusCode, message } = await context.$api.bid.getValidateBid({ auctionNo: auctionNo.value })
      if (statusCode == 200) {
        push('/buyercenter/single')
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }
    const handleJoin = async () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!bidSingleAuth.value) {
        judgeIsAuth()
        return
      }
      !loginCorpAuth.value && (await store.dispatch('app/GET_CORPAUTH', context))
      if (!loginCorpAuth.value) {
        return showErrorTip('', 7)
      }
      state.tipMessage = '是否参与该条竞价？'
      confirmRef.value.dialogVisible = true
    }
    const sureTipConfirm = async () => {
      const { data, statusCode, message } = await context.$api.bid.joinAuction({
        auctionNo: auctionNo.value
      })
      if (statusCode == 200) {
        confirmRef.value.dialogVisible = false
        getSingleBidGoodsDetail()
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }
    const { currentTime, getSyncTime } = useSyncTime({
      getListAsync: getSingleBidGoodsDetail,
      freshSecond: bidRefreshSecond,
      isRefreshSecond: true
    })

    const setTimer = () => {
      clearTimeout(state.intervalTime)
      state.intervalTime = setTimeout(() => {
        getSingleBidGoodsDetail()
        setTimer()
      }, 1000 * bidRefreshSecond)
    }

    watch(
      () => currentTime.value,
      (newVal) => {
        const time = newVal / 1000 / 60 / 30
        if (typeof time === 'number' && time % 1 === 0) {
          getSingleBidGoodsDetail()
          setTimeout(() => {
            getSingleBidGoodsDetail()
          }, 1000 * 5)
        }
      }
    )
    watch(
      () => userInfo.value,
      () => {
        getSingleBidGoodsDetail()
      }
    )

    const handleVisiable = (e) => {
      const isHidden = e.target.hidden
      if (isHidden === false) {
        getSingleBidGoodsDetail()
        setTimer()
      }
    }

    const getAfterSaleInfo = (detail) => {
      const qualityArr = ['正品', '分割正品', '船板', '分割船板']
      if (qualityArr.includes(detail.qualityInfo)) {
        return '受理质量异议'
      } else {
        return '不受理质量异议'
      }
    }

    const goodsDetailRef = ref(null)
    const handleQuality = (url) => {
      goodsDetailRef.value.openQualityDialog(url)
    }

    onMounted(() => {
      getSingleBidGoodsDetail()
      setTimer()
      document.addEventListener('visibilitychange', handleVisiable)
    })
    onUnmounted(() => {
      clearTimeout(state.intervalTime)
      document.removeEventListener('visibilitychange', handleVisiable)
    })
    return {
      ...toRefs(state),
      detailInfo,
      detailInfoTitle,
      userInfo,
      loginCorpId,
      invoiceModeName,
      swiperImgs,
      judgeIsLogin,
      penaltyMoney,
      push,
      handleOffer,
      handleJoin,
      confirmRef,
      sureTipConfirm,
      currentTime,
      getSingleBidGoodsDetail,
      auctionNo,
      tipRef,
      getAfterSaleInfo,
      formatNumber,
      handleQuality,
      goodsDetailRef
    }
  },
  head: {
    title: ''
  }
})
