import introduce from '@/components/home/introduce.vue'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'Introduce',
  setup(__props) {

const { pushBcs } = useLink()
const bottomText = ['现货资源 尽在钢宝', '阳光采购 质美价优', '优质服务 专业可靠']

return { __sfc: true,pushBcs, bottomText, introduce }
}

}