import { useContext } from '@nuxtjs/composition-api'
import { exportXls } from '@/utils/excel'


export default {
  __name: 'saleTemplate',
  setup(__props) {

const context = useContext()
const handleDownload = async () => {
  const data = await context.$api.home.dowmloadTemp()
  exportXls(data, '销售合同模板.doc')
}

return { __sfc: true,context, handleDownload }
}

}