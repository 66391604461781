import { render, staticRenderFns } from "./searchBarItem.vue?vue&type=template&id=0aa9393e"
import script from "./searchBarItem.vue?vue&type=script&lang=js"
export * from "./searchBarItem.vue?vue&type=script&lang=js"
import style0 from "./searchBarItem.vue?vue&type=style&index=0&id=0aa9393e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchInput: require('/usr/src/app/components/basic/SearchInput.vue').default})
