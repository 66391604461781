import { ref, watch, onMounted } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { useLogout } from '@/hooks/useLogout'


export default {
  __name: 'headerImg',
  props: {
  headImg: {
    type: Boolean,
    default: true
  },
  nowPath: {
    type: String,
    default: ''
  }
},
  setup(__props) {

const props = __props;



const { push } = useLink()
const { userInfo } = useLoginInfo()
const { logoutAsync } = useLogout()

const circle = ref(false)
const loadImage = () => {
  const img = new Image()
  img.onload = () => {
    if (img.width > img.height) {
      circle.value = true
    } else {
      circle.value = false
    }
  }
  if (userInfo.value.head) {
    img.src = userInfo.value.head
  }
}

watch(
  () => [userInfo.value.head, userInfo.value],
  ([newHead, newInfo]) => {
    if (newHead) {
      loadImage()
    }
  }
)

onMounted(() => {
  loadImage()
})

return { __sfc: true,props, push, userInfo, logoutAsync, circle, loadImage }
}

}