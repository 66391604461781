import { useContext, ref, watch, useRoute, reactive, toRefs, useStore, onMounted, nextTick } from '@nuxtjs/composition-api'
import choiceCorpDialog from './choiceCorpDialog.vue'
import headerImg from './headerImg.vue'
import fixedMenusBar from './fixedMenusBar.vue'
import headerCorpInfo from './headerCorpInfo.vue'
import checkMobileDialog from './checkMobileDialog.vue'
import c2mAppTab from './c2mApp.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { removeCorpId, setCorpSelect, getCorpSelect } from '@/utils/auth'

export default {
  __name: 'headerMenuList',
  props: {
  isBcsPage: {
    type: Boolean,
    default: false
  }
},
  setup(__props, { expose }) {

const props = __props;


const context = useContext()
const store = useStore()
const route = useRoute()
const { push } = useLink()

const { userInfo, corps, loginCorpInfo } = useLoginInfo()

const choiceCorpRef = ref(null)

const state = reactive({
  tipDialogVisible: false
})

const nowPath = ref('')
watch(
  () => store.getters.highlightPath,
  (newVal) => {
    nowPath.value = newVal
  }
)

const handleHelp = () => {
  push('/helpcenter/trendRule')
}

const checkMobileRef = ref(null)
const showCorps = () => {
  choiceCorpRef.value.corpSelectDialogVisible = true
  choiceCorpRef.value.showClose = true
  choiceCorpRef.value.selectCorp = loginCorpInfo.value
  getCorOpt()
}

const showCorpInfo = () => {
  if (process.env.NODE_ENV == 'development') {
    choiceCorpRef.value.corpSelectDialogVisible = true
    choiceCorpRef.value.showClose = true
    choiceCorpRef.value.selectCorp = loginCorpInfo.value
    getCorOpt()
  } else {
    checkMobileRef.value.tipDialogVisible = true
  }
}

watch(
  () => store.getters.corpChangeFlag,
  () => {
    if (store.getters.corpChangeFlag) {
      showCorpInfo()
      store.commit('app/SET_CORPCHANGEFLAG', false)
    }
  }
)

const corpsList = ref([])
const getCorOpt = async () => {
  const { statusCode, data } = await context.$api.auth.getCorOpt({
    optUserId: userInfo.value.optUserId
  })
  if (statusCode == 200) {
    corpsList.value = data
  }
}

const corpChange = () => {
  setCorpSelect(context)
  window.location.reload()
}

onMounted(() => {
  nowPath.value = route.value.path.includes('/bcs') ? route.value.path.replace('/bcs', '') : route.value.path
  if (!userInfo.value) {
    removeCorpId(context)
  }
  if (getCorpSelect(context) == null) {
    corpsList.value = corps.value
    if (userInfo.value && corps.value.length > 0) {
      choiceCorpRef.value.corpSelectDialogVisible = true
      nextTick(() => {
        choiceCorpRef.value.selectCorp = corpsList.value[0]
        choiceCorpRef.value.accountType = '1'
      })
      if (corps.value.every((_) => _.corpStatus == 0 || _.status == 0)) {
        choiceCorpRef.value.showClose = false
      }
    }
  } else {
    setCorpSelect(context)
  }
})
expose({
  ...toRefs(state)
})

return { __sfc: true,props, context, store, route, push, userInfo, corps, loginCorpInfo, choiceCorpRef, state, nowPath, handleHelp, checkMobileRef, showCorps, showCorpInfo, corpsList, getCorOpt, corpChange, choiceCorpDialog, headerImg, fixedMenusBar, headerCorpInfo, checkMobileDialog, c2mAppTab }
}

}