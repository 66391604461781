import { onMounted, computed, useStore, useContext } from '@nuxtjs/composition-api'
import { C2M_HOT_LINE } from '@/constant'


export default {
  __name: 'banner',
  emits: ['publish'],
  setup(__props, { emit }) {

const context = useContext()
const store = useStore()

const formatHotline = (hotline) => {
  return hotline.replace(/^(.{3})-(.*)-(.{4})$/, '$1\xA0$2\xA0$3')
}

const advertList = computed(() => store.getters.c2mBanners)



const handlePublish = () => {
  emit('publish')
}

onMounted(() => {
  store.dispatch('home/GET_ADVERTLIST', context)
})

return { __sfc: true,context, store, formatHotline, advertList, emit, handlePublish, C2M_HOT_LINE }
}

}