
import { defineComponent, reactive, toRefs, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    swiperImgs: {
      type: Array,
      default: () => []
    },
    isSpecial: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const state = reactive({
      mainImg: '',
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 25,
        freeMode: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    })
    const choseMainImage = (img) => {
      state.mainImg = img
    }
    watch(
      () => props.swiperImgs,
      () => {
        state.mainImg = props.swiperImgs.length ? props.swiperImgs[0] : ''
      },
      { immediate: true }
    )
    return {
      ...toRefs(state),
      choseMainImage
    }
  }
})
