import { ref, useContext } from '@nuxtjs/composition-api'
import { HOT_LINE } from '@/constant'
import { useLink } from '@/hooks/useLink'
import { exportXls } from '@/utils/excel'


export default {
  __name: 'introduce',
  setup(__props) {

const context = useContext()
const ulList = ref([
  {
    title: '平台交易',
    children: [
      {
        name: '平台交易规则',
        path: '/helpcenter/trendRule'
      },
      {
        name: '销售合同模板',
        path: '',
        isDownload: true
      }
    ]
  },
  {
    title: '操作指南',
    children: [
      {
        name: '买家指南',
        path: '/helpcenter/buyerGuide'
      },
      {
        name: '卖家指南',
        path: '/helpcenter/sellerGuide'
      }
    ]
  },
  {
    title: '支付方式',
    children: [
      {
        name: '网银支付',
        path: '/helpcenter/internetbank'
      },
      {
        name: '承兑支付',
        path: '/helpcenter/acceptPay'
      }
    ]
  }
])
const { push } = useLink()
const handleLink = (item) => {
  if (item.isDownload) {
    return handleDownload()
  }
  if (item.path) {
    push(item.path)
  }
}
const handleDownload = async () => {
  const data = await context.$api.home.dowmloadTemp()
  exportXls(data, '销售合同模板.doc')
}

return { __sfc: true,context, ulList, push, handleLink, handleDownload, HOT_LINE }
}

}