
import { computed, defineComponent, onMounted, reactive, ref, toRefs, useContext, useRoute, useMeta } from '@nuxtjs/composition-api'
import tipDialog from '@/components/spotMall/tipDialog.vue'
import { formatDate } from '@/utils/tool'
export default defineComponent({
  components: {
    tipDialog
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const tipRef = ref(null)
    const route = useRoute()
    const state = reactive({
      detailInfo: {},
      loading: true,
      tipMessage: ''
    })
    const id = computed(() => route.value.params.id)
    const getDetailAsync = async () => {
      const { data, statusCode, message } = await context.$api.home.getNewsDeatil({ id: id.value })
      if (statusCode == 200) {
        state.loading = false
        state.detailInfo = { ...data }
      } else {
        tipRef.value.dialogVisible = true
        state.tipMessage = message
      }
    }
    useMeta(() => ({
      title: state.detailInfo.title
    }))
    const getHeight = () => {
      if (process.client) {
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
        return clientHeight - 301
      }
    }
    onMounted(() => {
      getDetailAsync()
    })
    return {
      ...toRefs(state),
      formatDate,
      tipRef,
      getHeight
    }
  },
  head: {
    title: '资讯详情-金陵钢宝网'
  }
})
