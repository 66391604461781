import { reactive, toRefs } from '@nuxtjs/composition-api'


export default {
  __name: 'tableHeaderButtons',
  emits: 'change',
  setup(__props, { expose, emit }) {



const state = reactive({
  order: '',
  orderField: ''
})
const getSortType = (type) => {
  state.orderField = type
  if (!type) state.order = ''
  emit('change', state)
}
expose({
  ...toRefs(state)
})

return { __sfc: true,emit, state, getSortType }
}

}