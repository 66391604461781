
import { defineComponent, ref, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const selectValue = ref(props.multiple ? [] : '')
    const change = () => {
      let temp = selectValue.value
      if (props.multiple) {
        temp = temp.join(',')
      }
      emit('change', temp)
    }
    watch(
      () => props.value,
      () => {
        let temp = props.value
        if (props.multiple) {
          temp = props.value ? props.value.split(',') : []
        }
        selectValue.value = temp
      },
      { immediate: true }
    )
    return {
      selectValue,
      change
    }
  }
})
