import { computed, ref } from '@nuxtjs/composition-api'
import ExTableColumn from './ExTableColumn'


export default {
  __name: 'ExSearchTable',
  props: {
  columns: {
    type: Array,
    required: true
  },
  showCheckbox: {
    type: Boolean,
    default: false
  },
  showIndex: {
    type: Boolean,
    default: true
  },
  // pager组件相关
  total: {
    type: Number,
    default: 0
  },
  currentPage: {
    type: Number,
    default: 1
  },
  pageSize: {
    type: Number,
    default: 10
  },
  pageSizes: {
    type: Array,
    default: () => [10, 15, 30, 50]
  }
},
  emits: ['selection-change', 'sort-change', 'current-change', 'filter-change'],
  setup(__props, { emit }) {

const props = __props;





const tableRef = ref(null)
const handleSelectionChange = (val) => {
  emit('selection-change', val)
}

const handleSortChange = (val) => {
  emit('sort-change', val)
}

const handleCurrentChange = (val) => {
  emit('current-change', val)
}
const handleSizeChange = (val) => {
  emit('size-change', val)
}

const hideOnSinglePage = computed(() => {
  return props.total <= props.pageSizes[0]
})
const filterChange = (val) => {
  emit('filter-change', val)
}

const configColumns = computed(() => props.columns.filter((_) => _.show != false))

return { __sfc: true,props, emit, tableRef, handleSelectionChange, handleSortChange, handleCurrentChange, handleSizeChange, hideOnSinglePage, filterChange, configColumns, ExTableColumn }
}

}