
import { defineComponent, onMounted, reactive, ref, useContext, toRefs, inject, computed } from '@nuxtjs/composition-api'
import topUpTip from '@/components/shopCashier/topUpTip.vue'
import { numSix, numberRegSix } from '@/utils/validate'
import payPwdButton from '@/components/shopCarts/payPwdButton.vue'
import { orderTypeMap, payChannelMap } from '@/constant/orders'

export default defineComponent({
  components: {
    payPwdButton,
    topUpTip
  },
  props: {
    payMoney: {
      type: [Number, String],
      default: ''
    },
    orderNo: {
      type: [String, Array],
      default: () => []
    },
    orderType: {
      type: String,
      default: ''
    },
    account: {
      type: [Number, String],
      default: ''
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const formRef = ref(null)
    const form = ref({
      password: ''
    })
    const rules = {
      password: numSix('支付密码不能为空')
    }
    const state = reactive({
      pwdFlag: false
    })
    const changePwd = (value) => {
      if (numberRegSix.test(value)) {
        state.pwdFlag = true
      } else {
        state.pwdFlag = false
      }
    }
    const orderTitle = computed(() => {
      let str
      orderTypeMap.forEach((value, key) => {
        if (value == props.orderType) {
          str = `${props.orderNo}的${key}`
        }
      })
      return str
    })
    const surePay = (next) => {
      const params = {
        orderNos: props.orderNo,
        orderTitle: orderTitle.value,
        orderType: props.orderType,
        payAmt: props.payMoney,
        payChannel: payChannelMap.get('余额支付'),
        payPwd: form.value.password,
        next
      }
      emit('payOrder', params)
    }

    const orderTypeName = computed(() => {
      let str
      orderTypeMap.forEach((value, key) => {
        if (value == props.orderType) {
          str = key
        }
      })
      return str
    })

    onMounted(() => {})
    return {
      formRef,
      form,
      rules,
      ...toRefs(state),
      changePwd,
      surePay,
      orderTypeName
    }
  }
})
