
import { defineComponent, ref, onMounted, reactive, useContext, useStore, computed } from '@nuxtjs/composition-api'
import microApp from '@micro-zoe/micro-app'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
export default defineComponent({
  middleware: 'auth',
  setup() {
    const context = useContext()
    const { $config } = context
    const { userInfo, loginCorpId, permissions, corps, dropMenus } = useLoginInfo()
    const store = useStore()
    const { showErrorTip } = useMsgDialog()
    const show = ref(false)

    const url = ref($config.bcsUrl)

    const microAppData = reactive({
      permissions,
      loginCorpId,
      userInfo,
      corps,
      buttons: store.getters.buttons,
      transactionParams: computed(() => store.getters.transactionParams),
      dropMenus
    })

    const handleDataChange = async (e) => {
      const data = e.detail.data
      if (data.title) {
        store.commit('app/SET_TITLE', data.title)
      }
      if (data.updateUserInfo) {
        await store.dispatch('app/GET_USERINFO', context)
        microApp.setData('bcs-app', { ...microAppData })
      }
      if (data.tipMsgDialog) {
        showErrorTip(data.tipMessage, data.status)
      }
      if (data.highlightPath) {
        store.commit('app/SET_HIGHLIGHTPATH', data.highlightPath)
      }
      if (data.transParams) {
        await store.dispatch('app/GET_TRANSACTIONPARAMS', context)
        microApp.setData('bcs-app', { ...microAppData })
      }
    }

    onMounted(() => {
      show.value = true
    })

    return {
      show,
      url,
      microAppData,
      handleDataChange
    }
  }
})
