import { onMounted, useContext, ref } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import setPayPwdDialog from '~/components/account/setPayPwdDialog.vue'


export default {
  __name: 'payPwdButton',
  setup(__props) {

const context = useContext()
const { pushBcs } = useLink()
const { loginCorpInfo, loginCorpId } = useLoginInfo()
const paymentPwdSet = ref(null)
const setPayPwdRef = ref(null)
const getSecurity = async () => {
  const { data, statusCode } = await context.$api.orderCart.getSecurity({
    corpId: loginCorpId.value
  })
  if (statusCode == 200) {
    paymentPwdSet.value = data.paymentPwdSet
  }
}
const setPayPwd = () => {
  if (paymentPwdSet.value) {
    pushBcs('/usercenter/account/editFeePwd')
  } else {
    setPayPwdRef.value.checkVisible = true
  }
}
const setSuccess = () => {
  getSecurity()
}
onMounted(() => {
  getSecurity()
})

return { __sfc: true,context, pushBcs, loginCorpInfo, loginCorpId, paymentPwdSet, setPayPwdRef, getSecurity, setPayPwd, setSuccess, setPayPwdDialog }
}

}