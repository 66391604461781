
import { defineComponent, onMounted, reactive, toRefs, useContext, ref, watch, inject, onUnmounted, useStore } from '@nuxtjs/composition-api'
import spanBadge from '../../pages/shop/components/spanBadge.vue'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { integerReg } from '@/utils/validate'
import { toFixed, mathAdd, formatNumber } from '@/utils/tool'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { useExTable } from '@/hooks/useExTable'
import quickEnquiry from '@/components/c2m/quickEnquiry.vue'
import { threadSteelName, threadSteelCompanyID } from '@/constant'

export default defineComponent({
  components: {
    spanBadge,
    quickEnquiry
  },
  setup() {
    const context = useContext()
    const store = useStore()
    const { loginCorpId, isCartAuth } = useLoginInfo()
    const $message = inject('$message')
    const { push, back } = useLink()
    const { showErrorTip } = useMsgDialog()
    const state = reactive({
      selectedProductsNum: 0,
      totalMoney: 0,
      totalWeight: 0,
      indeterminate: false,
      isSelectedAll: false,
      checkedIds: [],
      checkedList: [],
      invalidGoods: 0,
      searchState: true,
      deliveryTolerance: store.getters.transactionParams?.entity?.deliveryTolerance,
      tipLoading: false
    })
    const tipState = ref(0)
    const tipStateMap = new Map([
      [0, '是否确认删除选择的商品？'],
      [1, '是否确认清空失效商品？'],
      [2, '是否确认清空购物车？']
    ])
    watch(
      () => state.checkedList,
      () => {
        state.totalMoney = getTotalMoney()
        state.totalWeight = getTotalWeight()
        state.selectedProductsNum = getSelectedNum()
      },
      {
        deep: true
      }
    )
    const changeInputNumber = async (row) => {
      let quantity
      if (row.choseNum && integerReg.test(row.choseNum)) {
        quantity = row.choseNum
      } else {
        quantity = row.cartQuantity
      }
      if (row.choseNum > row.quantity) {
        quantity = row.quantity
      }
      const { statusCode, message } = await context.$api.orderCart.updateCart({ resourceId: row.resourceId, quantity })
      if (statusCode == 200) {
        getOrderCartList()
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
        getOrderCartList()
      } else {
        $message.error(message)
        getOrderCartList()
      }
    }

    const blurNumber = (e, row) => {
      if (!e.target.value || !integerReg.test(e.target.value)) {
        row.choseNum = row.cartQuantity
      }
    }
    const getCheckedIds = () => {
      const _checkedIds = []
      for (let i = 0; i < cartList.value.length; i++) {
        const products = cartList.value[i].cartDtos.reduce((acc, cur) => (acc = [...acc, ...cur.dtos]), [])
        for (let j = 0; j < products.length; j++) {
          if (products[j].isSelected && products[j].status == '1') {
            _checkedIds.push(products[j].resourceId)
          }
        }
      }
      state.checkedIds = _checkedIds
    }
    const getCheckedList = () => {
      const _checkedList = []
      state.checkedIds.forEach((checkedId) => {
        for (let i = 0; i < cartList.value.length; i++) {
          const products = cartList.value[i].cartDtos.reduce((acc, cur) => (acc = [...acc, ...cur.dtos]), [])
          for (let j = 0; j < products.length; j++) {
            if (products[j].resourceId == checkedId && products[j].status == '1') {
              products[j].isSelected = true
              _checkedList.push(products[j])
            }
          }
        }
      })
      state.checkedList = _checkedList
      if (!state.checkedList.length) {
        state.isSelectedAll = false
        state.indeterminate = false
      }
      for (let i = 0; i < cartList.value.length; i++) {
        const products = cartList.value[i].cartDtos.reduce((acc, cur) => (acc = [...acc, ...cur.dtos]), [])
        const arrLength = products.filter((res) => res.status == '1')
        cartList.value[i].isSelected = false
        cartList.value[i].cartDtos.forEach((item) => {
          item.isSelected = false
        })
        let count = 0
        for (let j = 0; j < products.length; j++) {
          if (products[j].isSelected && products[j].status == '1') {
            count++
          }
        }
        if (arrLength.length == count && arrLength.length != 0) {
          cartList.value[i].isSelected = true
          cartList.value[i].indeterminate = false
          cartList.value[i].cartDtos.forEach((item) => {
            if (item.dtos.find((_) => _.status == 1)) {
              item.isSelected = true
              item.indeterminate = false
            } else {
              item.isDisabled = true
            }
          })
        }
        if (!arrLength.length) {
          cartList.value[i].isSelected = false
          cartList.value[i].isDisabled = true
          cartList.value[i].cartDtos.forEach((item) => {
            item.isSelected = false
            item.isDisabled = true
          })
        }
        if (arrLength.length && arrLength.length !== count) {
          cartList.value[i].cartDtos.forEach((item) => {
            const dtos = item.dtos.filter((res) => res.status == '1')
            const selectedLength = dtos.filter((res) => res.isSelected).length
            if (selectedLength && dtos.length == selectedLength) {
              item.isSelected = true
              item.indeterminate = false
            }
            if (!dtos.length) {
              item.isSelected = false
              item.isDisabled = true
            }
          })
        }
      }
    }
    // 计算勾选的商品总金额
    const getTotalMoney = () => {
      // const count = cartList.value.reduce((acc, cur) => {
      //   const products = cur.cartDtos
      //   let childCount = 0
      //   products.forEach((curChild) => {
      //     if (curChild.isSelected && curChild.status == 1 && curChild.goodsAmountAll) {
      //       childCount = parseFloat(toFixed(mathAdd(childCount, curChild.goodsAmountAll), 2))
      //     }
      //   })
      //   return parseFloat(toFixed(mathAdd(acc, childCount), 2))
      // }, 0)
      // return count
      if (state.checkedList.length) {
        const num = state.checkedList.reduce((acc, cur) => (acc = parseFloat(toFixed(mathAdd(acc, cur.goodsAmountAll), 2))), 0)
        return num
      } else {
        return formatNumber(0, 2)
      }
    }
    // 计算勾选的商品总重量
    const getTotalWeight = () => {
      // const count = cartList.value.reduce((acc, cur) => {
      //   const products = cur.cartDtos
      //   let childCount = 0
      //   products.forEach((curChild) => {
      //     if (curChild.isSelected && curChild.status == 1 && curChild.cartGoodsWeight) {
      //       childCount = parseFloat(toFixed(mathAdd(childCount, curChild.cartGoodsWeight), 3))
      //     }
      //   })
      //   return parseFloat(toFixed(mathAdd(acc, childCount), 3))
      // }, 0)
      // return count
      if (state.checkedList.length) {
        const num = state.checkedList.reduce((acc, cur) => (acc = parseFloat(toFixed(mathAdd(acc, cur.cartGoodsWeight), 3))), 0)
        return num
      } else {
        return formatNumber(0, 3)
      }
    }
    // 计算购物车总商品数
    const getSelectedNum = () => {
      // const count = cartList.value.reduce((acc, cur) => {
      //   const products = cur.cartDtos
      //   let childCount = 0
      //   products.forEach((curChild) => {
      //     if (curChild.isSelected && curChild.status == 1) {
      //       childCount = parseFloat(toFixed(mathAdd(childCount, curChild.cartQuantity), 0))
      //     }
      //   })
      //   return parseFloat(toFixed(mathAdd(acc, childCount), 0))
      // }, 0)
      // return count
      if (state.checkedList.length) {
        const num = state.checkedList.reduce((acc, cur) => (acc = parseFloat(toFixed(mathAdd(acc, cur.cartQuantity), 0))), 0)
        return num
      } else {
        return formatNumber(0)
      }
    }

    const handleCheckAllChange = (e) => {
      // 一级change事件
      state.isSelectedAll = e
      state.indeterminate = false
      for (let i = 0, len = cartList.value.length; i < len; i++) {
        // 二级全选反选
        cartList.value[i].isSelected = e
        cartList.value[i].indeterminate = false // 去掉二级不确定状态
        for (let j = 0, len1 = cartList.value[i].cartDtos.length; j < len1; j++) {
          // 四级全选反选
          if (cartList.value[i].cartDtos[j].dtos.find((_) => _.status == 1)) {
            cartList.value[i].cartDtos[j].isSelected = e
            cartList.value[i].cartDtos[j].indeterminate = false
            cartList.value[i].cartDtos[j].dtos.forEach((_) => {
              if (_.status == 1) {
                _.isSelected = e
              }
            })
          }
        }
      }
      getCheckedIds()
      getCheckedList()
    }
    const handleCheckedShopAllChange = (index, topId, e) => {
      // 二级change事件
      cartList.value[index].isSelected = e // 二级勾选后，子级全部勾选或者取消
      cartList.value[index].indeterminate = false // 去掉二级不确定状态
      cartList.value[index].cartDtos.forEach((item) => {
        if (item.dtos.find((_) => _.status == 1)) {
          item.isSelected = e
          item.indeterminate = false
          item.dtos.forEach((_) => {
            if (_.status == 1) {
              _.isSelected = e
            }
          })
        }
      })

      getIsCheckAll()
    }
    const handleCheckedShopAddressChange = (topIndex, index, value, e) => {
      cartList.value[topIndex].cartDtos[index].isSelected = e
      cartList.value[topIndex].cartDtos[index].indeterminate = false

      const cartDtosSelectedLength = cartList.value[topIndex].cartDtos.filter((item) => item.isSelected).length
      const cartDtosDisabledLength = cartList.value[topIndex].cartDtos.filter((item) => item.isDisabled).length
      if (!cartDtosDisabledLength) {
        cartList.value[topIndex].isSelected = cartDtosSelectedLength === cartList.value[topIndex].cartDtos.length && cartDtosSelectedLength !== 0
        cartList.value[topIndex].indeterminate = cartDtosSelectedLength !== cartList.value[topIndex].cartDtos.length && cartDtosSelectedLength !== 0
      } else {
        cartList.value[topIndex].isSelected =
          cartDtosSelectedLength === cartList.value[topIndex].cartDtos.length - cartDtosDisabledLength && cartDtosSelectedLength !== 0
        cartList.value[topIndex].indeterminate =
          cartDtosSelectedLength !== cartList.value[topIndex].cartDtos.length - cartDtosDisabledLength && cartDtosSelectedLength !== 0
      }

      const childrenArray = cartList.value[topIndex].cartDtos[index].dtos
      if (childrenArray) for (let i = 0, len = childrenArray.length; i < len; i++) childrenArray[i].isSelected = e

      getIsCheckAll()
    }
    const handleCheckedShopChange = (topIndex, repositoryIndex, son, topId, e) => {
      // 四级change事件
      const childrenArray = cartList.value[topIndex].cartDtos
        .reduce((acc, cur) => (acc = [...acc, ...cur.dtos]), [])
        .filter((res) => res.status == '1')
      let tickCount = 0
      let unTickCount = 0
      const len = childrenArray.length
      for (let i = 0; i < len; i++) {
        if (son.id == childrenArray[i].id) childrenArray[i].isSelected = e
        if (childrenArray[i].isSelected == true) tickCount++
        if (childrenArray[i].isSelected == false) unTickCount++
      }
      if (tickCount == len) {
        // 四级级全勾选
        cartList.value[topIndex].isSelected = true
        cartList.value[topIndex].indeterminate = false
        cartList.value[topIndex].cartDtos.forEach((item) => {
          item.isSelected = true
          item.indeterminate = false
        })
      } else if (unTickCount == len) {
        // 四级级全不勾选
        cartList.value[topIndex].isSelected = false
        cartList.value[topIndex].indeterminate = false
        cartList.value[topIndex].cartDtos.forEach((item) => {
          item.isSelected = false
          item.indeterminate = false
        })
      } else {
        cartList.value[topIndex].isSelected = false
        cartList.value[topIndex].indeterminate = true // 添加二级不确定状态

        if (
          cartList.value[topIndex].cartDtos[repositoryIndex].dtos.filter((item) => item.isSelected).length ===
          cartList.value[topIndex].cartDtos[repositoryIndex].dtos.length
        ) {
          cartList.value[topIndex].cartDtos[repositoryIndex].isSelected = true
          cartList.value[topIndex].cartDtos[repositoryIndex].indeterminate = false
        } else {
          cartList.value[topIndex].cartDtos[repositoryIndex].isSelected = false
          cartList.value[topIndex].cartDtos[repositoryIndex].indeterminate = true
        }
      }
      getIsCheckAll()
    }
    const getIsCheckAll = () => {
      let tickCount = 0
      let unTickCount = 0
      let indeterminateCount = 0
      const ArrLength = cartList.value.length
      for (let j = 0; j < ArrLength; j++) {
        // 全选checkbox状态
        if (cartList.value[j].isSelected == true) tickCount++
        if (cartList.value[j].isSelected == false) unTickCount++
        if (cartList.value[j].indeterminate == true) indeterminateCount++
      }
      if (tickCount == ArrLength) {
        // 二级全勾选
        state.isSelectedAll = true
        state.indeterminate = false
      } else if (unTickCount == ArrLength) {
        // 二级全不勾选
        state.isSelectedAll = false
        if (indeterminateCount > 0) {
          state.indeterminate = true
        } else {
          state.indeterminate = false
        }
      } else if (tickCount && unTickCount && tickCount + unTickCount == ArrLength) {
        state.isSelectedAll = true
        state.indeterminate = false
      } else {
        state.isSelectedAll = false
        state.indeterminate = true // 添加一级不确定状态
      }
      getCheckedIds()
      getCheckedList()
    }
    const cartList = ref(null)
    const timer = ref(null)
    const setTimer = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        getOrderCartList()
        getGoodsCount()
        setTimer()
      }, 30 * 1000)
    }

    const getOrderCartList = async () => {
      // setTimer()
      const { statusCode, data, message } = await context.$api.orderCart.getOrderCartList()
      if (statusCode == 200) {
        state.searchState = true
        state.invalidGoods = 0
        data.forEach((company) => {
          const cartDtos = company.cartDtos
          if (cartDtos.length) {
            cartDtos.forEach((list) => {
              // list.choseNum = list.cartQuantity
              // list.maxNum = list.cartQuantity > list.quantity ? list.cartQuantity : list.quantity
              list.isSelected = false
              // if (list.status == '0') state.invalidGoods++
            })
          }
          company.cartDtos = company.cartDtos.reduce((acc, cur) => {
            cur.choseNum = cur.cartQuantity
            cur.maxNum = cur.cartQuantity > cur.quantity ? cur.cartQuantity : cur.quantity
            if (cur.status == '0') state.invalidGoods++
            if (!acc.length) {
              acc.push({
                repositoryName: cur.repositoryName,
                repositoryAddress: cur.repositoryAddress,
                isSelected: false,
                dtos: [cur]
              })
            } else {
              const index = acc.findIndex((_) => `${_.repositoryName}${_.repositoryAddress}` === `${cur.repositoryName}${cur.repositoryAddress}`)
              if (index === -1) {
                acc.push({
                  repositoryName: cur.repositoryName,
                  repositoryAddress: cur.repositoryAddress,
                  isSelected: false,
                  dtos: [cur]
                })
              } else {
                acc[index].dtos.push(cur)
              }
            }
            return acc
          }, [])
        })
        cartList.value = data
        getCheckedList()
      } else if (statusCode == 30010 || statusCode == 30020) {
        // 30010 无法操作，请联系公司管理员进行授权
        state.searchState = false
        showErrorTip(message)
      } else {
        $message.error(message)
      }
    }
    const cartsNum = ref(0)
    const getGoodsCount = async () => {
      const { statusCode, data } = await context.$api.orderCart.getGoodsCount()
      if (statusCode == 200) {
        cartsNum.value = data
      }
    }
    const confirmRef = ref(null)
    const deleteIds = ref([])
    const deleteCart = (stateType, obj = '') => {
      if (stateType == 0 && !obj && state.checkedList.length == 0) {
        $message.error('请选择要删除的商品')
        return
      }
      if (stateType == 1 && !state.invalidGoods) {
        $message.error('购物车暂无失效商品')
        return
      }
      tipState.value = stateType
      confirmRef.value.dialogVisible = true
      deleteIds.value = []
      if (stateType == 0 && obj) {
        deleteIds.value.push(obj.id)
      } else if (stateType == 0) {
        state.checkedList.forEach((list) => {
          deleteIds.value.push(list.id)
        })
      }
    }
    const floatbarRef = ref(null)
    const delGoods = async () => {
      state.tipLoading = true
      const { statusCode, message } = await context.$api.orderCart.delGoods(deleteIds.value)
      state.tipLoading = false
      if (statusCode == 200) {
        confirmRef.value.dialogVisible = false
        state.checkedIds = []
        getOrderCartList()
        getGoodsCount()
        $message.success('成功删除购物车内选中商品')
        setTimeout(() => {
          floatbarRef.value && floatbarRef.value.handleScroll()
        }, 500)
      } else if (statusCode == 30010 || statusCode == 30020) {
        confirmRef.value.dialogVisible = false
        showErrorTip(message)
      } else {
        $message.error(message)
      }
    }
    const delInvalidGoods = async () => {
      state.tipLoading = true
      const { statusCode, message } = await context.$api.orderCart.delInvalidGoods()
      state.tipLoading = false
      if (statusCode == 200) {
        confirmRef.value.dialogVisible = false
        state.checkedIds = []
        getOrderCartList()
        getGoodsCount()
        $message.success('成功移除购物车内失效商品')
        setTimeout(() => {
          floatbarRef.value && floatbarRef.value.handleScroll()
        }, 500)
      } else if (statusCode == 30010 || statusCode == 30020) {
        confirmRef.value.dialogVisible = false
        showErrorTip(message)
      } else {
        $message.error(message)
      }
    }
    const delAllGoods = async () => {
      state.tipLoading = true
      const { statusCode, message } = await context.$api.orderCart.delAllGoods()
      state.tipLoading = false
      if (statusCode == 200) {
        confirmRef.value.dialogVisible = false
        state.checkedIds = []
        getOrderCartList()
        getGoodsCount()
        $message.success('成功清空购物车商品')
      } else if (statusCode == 30010 || statusCode == 30020) {
        confirmRef.value.dialogVisible = false
        showErrorTip(message)
      } else {
        $message.error(message)
      }
    }

    const showInfo = (row) => {
      if (row.status == '0') {
        return
      }
      // window.open(`/shop/${row.resourceId}`, '_blank')
      window.open(`/shop/goods?id=${encodeURI(row.resourceId)}`, '_blank')
    }

    const sureBuy = async () => {
      if (!state.checkedList.length) {
        return $message.error('未选择结算商品')
      }
      const threadSteel = state.checkedList.filter((res) => res.categoryName == threadSteelName && res.ownerCorpId == threadSteelCompanyID)
      if (!(threadSteel.length == 0 || threadSteel.length == state.checkedList.length)) {
        return $message.error(`${threadSteelName}(南钢)需要单独下单`)
      }
      const resourceIds = state.checkedList.map((res) => res.resourceId).join(',')
      const { statusCode, message } = await context.$api.orderCart.validateBuyNow({
        type: 2,
        resourceIds: resourceIds.split(',')
      })
      if (statusCode == 200) {
        push('/shop/carts', { type: 2, resourceIds })
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }

    const sureConfirm = () => {
      switch (tipState.value) {
        case 0:
          delGoods()
          break
        case 1:
          delInvalidGoods()
          break
        case 2:
          delAllGoods()
          break
        default:
          break
      }
    }

    const handleVisiable = (e) => {
      const isHidden = e.target.hidden
      if (isHidden === false) {
        getOrderCartList()
        getGoodsCount()
      }
    }

    const { exportListAsync } = useExTable({
      query: '',
      exportApi: context.$api.orderCart.getCartExport,
      exportName: '购物车商品明细',
      isMounted: false
    })
    const handleExport = () => {
      exportListAsync()
    }

    const getLocation = (row) => {
      if (row.gylWzstr03 == null) {
        return '-'
      } else {
        return `${row.gylWzstr03 ?? ''} | ${+row.gylWzstr04 ?? ''} | ${+row.gylWzstr05 ?? ''}`
      }
    }

    onMounted(async () => {
      if (!loginCorpId.value) {
        return
      }
      if (!isCartAuth.value) {
        return
      }
      await getOrderCartList()
      await getGoodsCount()
      setTimer()
      document.addEventListener('visibilitychange', handleVisiable)
    })
    onUnmounted(() => {
      clearTimeout(timer.value)
      document.removeEventListener('visibilitychange', handleVisiable)
    })

    const quickEnquiryRef = ref(null)
    const handleLinkToC2m = () => {
      quickEnquiryRef.value.openDialog({})
    }
    return {
      push,
      back,
      ...toRefs(state),
      changeInputNumber,
      cartsNum,
      cartList,
      confirmRef,
      deleteCart,
      sureConfirm,
      tipState,
      tipStateMap,
      handleCheckAllChange,
      handleCheckedShopAllChange,
      handleCheckedShopChange,
      showInfo,
      sureBuy,
      loginCorpId,
      isCartAuth,
      blurNumber,
      handleCheckedShopAddressChange,
      formatNumber,
      handleExport,
      floatbarRef,
      getLocation,
      quickEnquiryRef,
      handleLinkToC2m
    }
  }
})
