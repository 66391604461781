import { useCssVars as _useCssVars } from 'vue'
import { ref, reactive, nextTick, watch, inject, computed } from '@nuxtjs/composition-api'
import { math } from '@/utils/mathAll'


export default {
  __name: 'cuttingGraphic',
  props: {
  width: {
    type: Number,
    default: 800
  },
  height: {
    type: Number,
    default: 300
  },
  thick: {
    type: Number,
    default: 0
  },
  widthLines: {
    type: Array,
    default: () => []
  },
  heightLines: {
    type: Array,
    default: () => []
  }
},
  emits: ['change'],
  setup(__props, { expose, emit }) {

const props = __props;

_useCssVars((_vm, _setup) => ({
  "f9a6cb86": (_setup.oppositeScale)
}))





const $message = inject('$message')

const graphicWrapperWidth = ref(438)
const graphicWrapperHeight = ref(525)

const graphicStyle = reactive({
  width: '',
  height: ''
})
const graphicScale = ref(1)
const oppositeScale = computed(() => 1 / graphicScale.value)

const inputValue = ref('')
const heightTags = ref([])
const inputVisible = ref(false)
const inputRef = ref(null)

const inputValue2 = ref('')
const widthTags = ref([])
const inputVisible2 = ref(false)
const inputRef2 = ref(null)

const xsubline = reactive({
  visible: false,
  left: 50
})

const ysubline = reactive({
  visible: false,
  top: 50
})

const handleClose = (tag, type) => {
  if (type == 1) {
    heightTags.value.splice(heightTags.value.indexOf(tag), 1)
    xAxis.value.splice(xAxis.value.indexOf(tag), 1)
  } else {
    widthTags.value.splice(widthTags.value.indexOf(tag), 1)
    yAxis.value.splice(yAxis.value.indexOf(tag), 1)
  }
  calcGraphic()
}

const showInput = (type) => {
  if (type == 1) {
    inputVisible.value = true
    nextTick(() => {
      inputRef.value.focus()
    })
  } else {
    inputVisible2.value = true
    nextTick(() => {
      inputRef2.value.focus()
    })
  }
}

const handleInputConfirm = (type) => {
  if (type == 1) {
    if (heightTags.value.includes(inputValue.value)) {
      $message.warning('该位置已存在切割线')
    }
    if (inputValue.value && inputValue.value > 0 && inputValue.value < props.width && !heightTags.value.includes(inputValue.value)) {
      heightTags.value.push(inputValue.value)
      lineClass(heightTags.value[heightTags.value.length - 1], type)
    }
    inputVisible.value = false
    inputValue.value = ''

    xsubline.visible = false
    // xsubline.left = 50
  } else {
    if (widthTags.value.includes(inputValue2.value)) {
      $message.warning('该位置已存在切割线')
    }
    if (inputValue2.value && inputValue2.value > 0 && inputValue2.value < props.height && !widthTags.value.includes(inputValue2.value)) {
      widthTags.value.push(inputValue2.value)
      lineClass(widthTags.value[widthTags.value.length - 1], type)
    }
    inputVisible2.value = false
    inputValue2.value = ''

    ysubline.visible = false
    // ysubline.top = 50
  }
  calcGraphic()
}

const handleInputFocus = (type) => {
  if (type == 1) {
    inputValue.value = Math.floor(props.width / 2)
    xsubline.left = Math.floor(props.width / 2)
    xsubline.visible = true
  } else {
    inputValue2.value = Math.floor(props.height / 2)
    ysubline.top = Math.floor(props.height / 2)
    ysubline.visible = true
  }
}

const handleInputChange = (val, type) => {
  if (type == 1) {
    xsubline.left = val
    xsubline.visible = val > 0 && val < props.width
  } else {
    ysubline.top = val
    ysubline.visible = val > 0 && val < props.height
  }
}

const xAxis = ref([])
const yAxis = ref([])
const lineClass = (axis, type) => {
  if (type == 1) {
    if (xAxis.value.includes(axis)) {
      return
    }
    xAxis.value.push(axis)
  } else {
    if (yAxis.value.includes(axis)) {
      return
    }
    yAxis.value.push(axis)
  }
}

const graphics = ref([])
const calcGraphic = () => {
  graphics.value = []
  const coordX = [0, ...xAxis.value, props.width].sort((a, b) => math('-', a, b))
  const coordY = [0, ...yAxis.value, props.height].sort((a, b) => math('-', a, b))
  if (coordX.length == 2 && coordY.length == 2) {
    return emit('change', graphics.value, xAxis.value, yAxis.value)
  }
  coordY.forEach((item, index) => {
    coordX.forEach((item2, index2) => {
      if (index < coordY.length - 1 && index2 < coordX.length - 1) {
        const graphic = {
          coord1: [item2, item],
          coord2: [coordX[index2 + 1], item],
          coord3: [coordX[index2 + 1], coordY[index + 1]],
          coord4: [item2, coordY[index + 1]],
          width: math('-', coordX[index2 + 1], item2),
          height: math('-', coordY[index + 1], item),
          thick: props.thick
        }
        graphics.value.push(graphic)
      }
    })
  })
  emit('change', graphics.value, xAxis.value, yAxis.value)
}

const selectGraphic = reactive({
  coord1: [],
  coord2: [],
  coord3: [],
  coord4: [],
  width: '',
  height: '',
  thick: ''
})
const mouseenter = (val) => {
  Object.assign(selectGraphic, val)
}
const mouseleave = () => {
  selectGraphic.coord1 = []
  selectGraphic.coord2 = []
  selectGraphic.coord3 = []
  selectGraphic.coord4 = []
  selectGraphic.width = ''
  selectGraphic.height = ''
  selectGraphic.thick = ''
}

watch(
  () => [props.width, props.height],
  ([width, height]) => {
    if (width && height) {
      const maxWidth = graphicWrapperWidth.value - 70
      const maxHeight = graphicWrapperHeight.value - 70
      graphicStyle.width = width
      graphicStyle.height = height
      if (width > maxWidth || height > maxHeight) {
        if (width / height > maxWidth / maxHeight) {
          graphicStyle.width = maxWidth
          graphicStyle.height = Math.round(maxWidth * (height / width))
          graphicScale.value = maxWidth / width
        } else {
          graphicStyle.height = maxHeight
          graphicStyle.width = Math.round(maxHeight * (width / height))
          graphicScale.value = maxHeight / height
        }
      }
    }
  },
  {
    immediate: true
  }
)

watch(
  () => [props.widthLines, props.heightLines],
  ([widthValue, heightValue]) => {
    xAxis.value = heightValue
    yAxis.value = widthValue
    calcGraphic()
  },
  {
    immediate: true
  }
)

const init = () => {
  widthTags.value = []
  heightTags.value = []
  xAxis.value = []
  yAxis.value = []
}

expose({
  mouseenter,
  mouseleave,
  init
})

return { __sfc: true,props, emit, $message, graphicWrapperWidth, graphicWrapperHeight, graphicStyle, graphicScale, oppositeScale, inputValue, heightTags, inputVisible, inputRef, inputValue2, widthTags, inputVisible2, inputRef2, xsubline, ysubline, handleClose, showInput, handleInputConfirm, handleInputFocus, handleInputChange, xAxis, yAxis, lineClass, graphics, calcGraphic, selectGraphic, mouseenter, mouseleave, init }
}

}