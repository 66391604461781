
import { defineComponent, onMounted, ref, useContext, useRoute, watch } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'

export default defineComponent({
  props: {
    currentRadio: {
      type: String,
      default: '0'
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const { replace } = useLink()
    const route = useRoute()
    const tabIndex = ref(0)
    const menuList = ref([
      { label: '现货商城', count: 0, type: 1, value: '0' },
      { label: '单条竞价', count: 0, type: 2, value: '1' }
    ])
    const currentSv = ref(route.value.query.sv)
    const handleClick = ({ name }) => {
      const { sv = '', sk = '', matchList = '', sr = '', keyWord = '' } = route.value.query
      const params = {}
      if (sk) {
        params[sk] = sv
      }
      if (keyWord) {
        params.keyWord = keyWord
      }
      if (name == 1) {
        replace('/bid/single', { sv, sk, sr, matchList, ...params })
      } else {
        replace('/shop', { sv, sk, sr, matchList, ...params })
      }
    }

    const getParams = () => {
      const { origin = '', categoryId = '', material = '', spec = '', keyWord = '' } = route.value.query
      return {
        origin,
        categoryId,
        material,
        spec,
        keyWord
      }
    }
    const getTabQuantity = async () => {
      const { data, statusCode } = await context.$api.goodsType.getTabQuantity(getParams())
      if (statusCode == 200) {
        data.forEach((_) => {
          menuList.value.forEach((menu) => {
            if (_.type == menu.type) {
              menu.count = _.cnt
            }
          })
        })
      }
    }
    watch(
      () => [route.value.query.sv, route.value.path, route.value.query.keyWord],
      ([newVal, newPath, newKeyWord]) => {
        if (newVal !== currentSv.value) {
          getTabQuantity()
          currentSv.value = newVal
        }
        if (newKeyWord !== currentSv.value) {
          getTabQuantity()
          currentSv.value = newKeyWord
        }
        tabIndex.value = newPath == '/bid/single' ? '1' : '0'
      }
    )
    onMounted(() => {
      if (route.value.query.sr == 0) {
        getTabQuantity()
      }
    })
    watch(
      () => props.currentRadio,
      (newVal) => {
        tabIndex.value = newVal
      },
      { immediate: true }
    )
    return {
      tabIndex,
      menuList,
      handleClick
    }
  }
})
