import { computed, onMounted, ref, useContext, useStore } from '@nuxtjs/composition-api'
import sellerCorpItem from '@/components/home/components/sellerCorpItem.vue'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'centerAdvert',
  setup(__props) {

const context = useContext()
const store = useStore()
const advertList = computed(() => store.getters.mainBanners)
const sellerCorpList = ref([])

const getShopListAsync = async () => {
  const { data, statusCode } = await context.$api.home.getShopAdvertList()
  if (statusCode == 200) {
    sellerCorpList.value = splitArray(data, 4)
  }
}

const splitArray = (arr, len) => {
  const ret = []
  for (let i = 0; i < len; i++) {
    if (arr[i]) {
      ret.push(arr[i])
    } else {
      ret.push({})
    }
  }
  return ret
}

const { pushBcs } = useLink()

const handleLink = (item) => {
  if (item.link) {
    pushBcs(item.link, false)
  }
}

onMounted(() => {
  sellerCorpList.value = splitArray([], 4)
  getShopListAsync()
  store.dispatch('home/GET_ADVERTLIST', context)
})

return { __sfc: true,context, store, advertList, sellerCorpList, getShopListAsync, splitArray, pushBcs, handleLink, sellerCorpItem }
}

}