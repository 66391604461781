import { reactive, toRefs } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'shortcut',
  setup(__props, { expose }) {

const { userInfo, loginCorpInfo, fixedMenus } = useLoginInfo()
const { push } = useLink()
const { showErrorTip } = useMsgDialog()
const state = reactive({
  current: 'buyer'
})
const handleLink = (item) => {
  if (!userInfo.value) {
    return push('/login', { redirect: item.path })
  }
  if (!userInfo.value.loginCorpId) {
    return showErrorTip('', 6)
  }
  if (!item.isAuth) {
    if (loginCorpInfo.value.memberType == 0 && item.path.includes('/sellercenter')) {
      return showErrorTip('', 4)
    } else {
      return showErrorTip('', 1)
    }
  } else {
    push(item.path)
  }
}
expose({
  ...toRefs(state)
})

return { __sfc: true,userInfo, loginCorpInfo, fixedMenus, push, showErrorTip, state, handleLink }
}

}