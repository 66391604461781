import { reactive, toRefs } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'tipDialog',
  props: {
  message: {
    type: String,
    default: '该商品正在调整中，请稍后再试'
  },
  link: {
    type: String,
    default: '/shop'
  }
},
  setup(__props, { expose }) {

const props = __props;


const state = reactive({
  dialogVisible: false
})
const { push } = useLink()
const handlePush = () => {
  push(props.link)
}

expose({
  ...toRefs(state)
})

return { __sfc: true,props, state, push, handlePush }
}

}