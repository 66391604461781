
import { defineComponent, ref, watch } from '@nuxtjs/composition-api'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    prefixIcon: {
      type: String,
      default: ''
    },
    placeholder: {
      type: Array,
      default: () => []
    },
    decimals: {
      type: Number,
      default: 2
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const rangeValue = ref([])
    const changeRangeValue = () => {
      const [first = '', second = ''] = rangeValue.value
      const tempValue = [getNumber(first), getNumber(second)]
      emit('change', tempValue)
    }
    const getNumber = (value) => {
      let num = value?.toString()
      if (num) {
        let temp = num
        if (temp.includes('.')) {
          const str_ = temp.substr(temp.indexOf('.') + 1)
          if (str_.includes('.')) {
            temp = temp.substr(0, temp.indexOf('.') + str_.indexOf('.') + 1)
          }
        }
        temp = temp?.split('.')
        if (num.indexOf('.') == 0 && num) {
          // ‘首位小数点情况‘
          if (num.length == 1) {
            num = num.replace(/[^$#$]/g, '0.')
          } else {
            num = ''
          }
        } else if (temp[1]?.length >= props.decimals) {
          const dot = String(num).indexOf('.')
          num = String(num).substring(0, dot + props.decimals + 1)
        }
        if (props.decimals == 3) {
          num = num.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1') // 小数点后只能输 3 位
        } else if (props.decimals == 2) {
          num = num.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, '$1') // 小数点后只能输 2 位
        }
      }
      return num
    }
    watch(
      () => props.value,
      () => {
        rangeValue.value = props.value
      },
      {
        immediate: true,
        deep: true
      }
    )
    return {
      rangeValue,
      changeRangeValue
    }
  }
})
