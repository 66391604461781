
import { defineComponent, onMounted, reactive, toRefs } from '@nuxtjs/composition-api'

export default defineComponent({
  name: '',
  components: {},
  setup() {
    const state = reactive({ list: [], requireComponent: [] })
    onMounted(() => {
      state.requireComponent = require.context('~/svg/', false, /.svg$/).keys()
      state.list = []
      state.requireComponent.forEach((items) => {
        state.list.push(items.split('./')[1].split('.svg')[0])
      })
    })
    return { ...toRefs(state) }
  }
})
