
import { defineComponent, reactive, toRefs, watch, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    total: {
      type: Number
    },
    pageNo: {
      type: Number
    },
    pageSize: {
      type: Number
    },
    pageSizes: {
      type: Array,
      default: () => [10, 15, 30, 50]
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      currentPage: 1,
      limit: props.pageSize
    })
    watch(
      () => props.pageNo,
      () => {
        state.currentPage = props.pageNo
      },
      { immediate: true }
    )
    watch(
      () => props.pageSize,
      () => {
        state.limit = props.pageSize
      },
      { immediate: true }
    )
    const handleSizeChange = () => {
      emit('size-change', state.limit)
    }
    const handleCurrentChange = (val) => {
      emit('current-change', val)
    }
    const hideOnSinglePage = computed(() => {
      // return props.total <= props.pageSizes[0]
      return !props.total
    })
    return {
      handleSizeChange,
      handleCurrentChange,
      ...toRefs(state),
      hideOnSinglePage
    }
  }
})
