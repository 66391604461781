
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    unit: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: undefined
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 999
    }
  },
  setup(props, { emit }) {
    const change = (val) => {
      emit('change', val)
    }
    return {
      change
    }
  }
})
