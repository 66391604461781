
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    warrantyUrl: {
      type: String,
      required: true
    }
  },
  setup() {
    const dialogVisible = ref(false)
    const directrecordwpRef = ref(null)
    onMounted(() => {})
    return {
      dialogVisible,
      directrecordwpRef
    }
  }
})
