import { render, staticRenderFns } from "./depositDialog.vue?vue&type=template&id=5f4cb33c"
import script from "./depositDialog.vue?vue&type=script&lang=js"
export * from "./depositDialog.vue?vue&type=script&lang=js"
import style0 from "./depositDialog.vue?vue&type=style&index=0&id=5f4cb33c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GbPasswordNumber: require('/usr/src/app/components/common/PasswordNumber.vue').default,PotocolDialog: require('/usr/src/app/components/basic/PotocolDialog.vue').default})
