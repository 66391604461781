import { reactive, toRefs, ref, useContext, onMounted } from '@nuxtjs/composition-api'
import recommendItem from '@/components/home/components/recommendItem.vue'


export default {
  __name: 'recommend',
  setup(__props, { expose }) {

const context = useContext()
const state = reactive({
  buttonFlag: false,
  swiperOption: {
    slidesPerView: 4,
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false
    }
  }
})
const goodsList = ref([])
const getListAsync = async () => {
  const { data, statusCode } = await context.$api.home.getRecommendList()
  if (statusCode == 200) {
    goodsList.value = data
  }
}
onMounted(() => {
  getListAsync()
})

expose({
  ...toRefs(state)
})

return { __sfc: true,context, state, goodsList, getListAsync, recommendItem }
}

}