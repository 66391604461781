import { computed, onMounted, ref, useRoute, watch } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { helpcenterMenus } from '@/utils/menu'


export default {
  __name: 'contact',
  setup(__props) {

const { push } = useLink()
const route = useRoute()
const menuList = computed(() => {
  const contactMenu = helpcenterMenus.find((_) => _.key == 'contact').children
  const menus = contactMenu.map((_) => {
    return {
      label: _.name,
      path: '/helpcenter' + _.path
    }
  })
  return menus
})
const tabIndex = ref(0)
const handleChange = (index) => {
  push(`${menuList.value[index].path}`)
}
watch(
  () => route.value.path,
  (newPath) => {
    tabIndex.value = menuList.value.findIndex((_) => _.path == newPath)
  }
)
onMounted(() => {
  tabIndex.value = menuList.value.findIndex((_) => _.path == route.value.path)
})

return { __sfc: true,push, route, menuList, tabIndex, handleChange }
}

}