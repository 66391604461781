import contactItem from '@/components/helpcenter/contactItem.vue'
import { salesman } from '@/constant/helpContact'

export default {
  __name: 'salesman',
  setup(__props) {


return { __sfc: true,contactItem, salesman }
}

}