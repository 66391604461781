
import { defineComponent, inject, computed, onMounted, reactive, ref, toRefs, useContext, useRoute, watch } from '@nuxtjs/composition-api'
import tableSearchBar from '@/components/spotMall/tableSearchBar.vue'
import countdown from '@/components/bid/countdown.vue'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { enquiryBidStatusMap } from '@/constant/bid'
import { throttle, formatNumber, formatDate } from '@/utils/tool'
export default defineComponent({
  components: {
    tableSearchBar,
    countdown
  },
  props: {
    currentTime: {
      type: [String, Number]
    },
    condition: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const $message = inject('$message')
    const route = useRoute()
    const { push } = useLink()
    const state = reactive({
      tableLoading: true,
      tableEmpty: true,
      tipMessage: '',
      rowObj: {}
    })
    const { pushQuery } = useLink()
    const { userInfo, loginCorpId, transEnquiryAuth } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
    const tablePage = reactive({
      total: 0
    })
    const editForm = reactive({
      page: 1,
      limit: 50
    })
    const tableData = ref([])
    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }
    const handleSizeChange = (val) => {
      editForm.limit = val
      editForm.page = 1
      pushQuery(route.value.path, transParams(editForm))
      emit('change', { ...transParams(editForm) })
      Object.assign(editForm, { ...transParams(editForm) })
      getListAsync()
    }
    const handleCurrentChange = (val) => {
      editForm.page = val
      pushQuery(route.value.path, transParams(editForm))
      emit('change', { ...transParams(editForm) })
      Object.assign(editForm, { ...transParams(editForm) })
      getListAsync()
    }
    const handleDetail = (row) => {
      window.open(`${route.value.path}/${row.auctionNo}`, '_blank')
    }
    const handleOffer = (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!transEnquiryAuth.value) {
        judgeIsAuth()
        return
      }
      push('/buyercenter/transportEnquiry')
    }
    const handleJoin = (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!transEnquiryAuth.value) {
        judgeIsAuth()
        return
      }
      state.tipMessage = '是否参与该询价？'
      confirmRef.value.dialogVisible = true
      state.rowObj = row
    }

    const getListAsync = throttle(async (params = {}) => {
      state.tableLoading = true
      try {
        const { data, statusCode } = await context.$api.transportEnquiry.getTransBidList(transParams(editForm))
        state.tableLoading = false
        if (statusCode == 200) {
          const { rows, total } = data
          tablePage.total = total
          tableData.value = rows
          state.tableEmpty = rows.length == 0
        } else {
          tablePage.total = 0
          tableData.value = []
          state.tableEmpty = true
        }
      } catch {
        tablePage.total = 0
        tableData.value = []
        state.tableEmpty = true
      }
    }, 500)

    const handleTimout = () => {
      Object.assign(editForm, {
        bidStartTime: '',
        bidEndTime: ''
      })
      handleCurrentChange(1)
      setTimeout(() => {
        emit('timeout')
      }, 3 * 1000)
    }

    const confirmRef = ref(null)
    const sureTipConfirm = async () => {
      // 运输招标参加不需要输入密码操作，直接调用参加接口
      const { statusCode, message } = await context.$api.transportEnquiry.joinAuction({
        auctionNo: state.rowObj.auctionNo
      })
      if (statusCode == 200) {
        confirmRef.value.dialogVisible = false
        $message.success('操作成功')
        getListAsync()
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }

    watch(
      () => props.condition,
      () => {
        Object.assign(editForm, { ...props.condition })
        getListAsync()
      },
      {
        deep: true
      }
    )

    watch(
      () => [props.currentTime],
      ([currentTimeNewVal]) => {
        const time = currentTimeNewVal / 1000 / 60 / 30
        if (typeof time === 'number' && time % 1 === 0) {
          getListAsync()
          setTimeout(() => {
            getListAsync()
          }, 1000 * 3)
        }
      }
    )

    const handleTabSearch = (obj) => {
      Object.assign(editForm, { ...obj })
      getListAsync()
      emit('change', obj)
    }

    return {
      ...toRefs(state),
      ...toRefs(tablePage),
      tableData,
      handleSizeChange,
      handleCurrentChange,
      handleDetail,
      handleOffer,
      handleJoin,
      enquiryBidStatusMap,
      judgeIsLogin,
      userInfo,
      getListAsync,
      loginCorpId,
      confirmRef,
      sureTipConfirm,
      handleTabSearch,
      editForm,
      handleTimout,
      formatNumber,
      formatDate
    }
  }
})
