import { computed } from '@nuxtjs/composition-api'


export default {
  __name: 'strongSpan',
  props: {
  text: String,
  strongText: String
},
  setup(__props) {

const props = __props;


const showLabel = computed(() => {
  const text = props.text
  const oRegExp = new RegExp('(' + props.strongText + ')', 'gi')
  return text.replace(oRegExp, `<strong>$1</strong>`)
})

return { __sfc: true,props, showLabel }
}

}