
import { defineComponent, computed, ref, watchEffect } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    value: {
      type: String
    }
  },
  setup(props, { emit }) {
    const temp = ref('')

    const currentValue = computed({
      get() {
        return temp.value?.replace(/\D/g, '').replace(/....(?!$)/g, '$& ')
      },
      set(val) {
        temp.value = val
      }
    })

    const inputChange = (val) => {
      temp.value = val
      emit('input', val.replace(/\s/g, ''))
    }

    watchEffect(() => {
      temp.value = props.value
    })

    return {
      currentValue,
      inputChange
    }
  }
})
