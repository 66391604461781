import { computed, useStore } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'waistBanner',
  setup(__props) {

const store = useStore()
const waistBanners = computed(() => store.getters.waistBanners)
const { pushBcs } = useLink()
const handleLink = (item) => {
  if (item.link) {
    pushBcs(item.link, false)
  }
}

return { __sfc: true,store, waistBanners, pushBcs, handleLink }
}

}