
import { defineComponent } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { HOT_LINE } from '@/constant'
export default defineComponent({
  props: {
    error: {
      type: Object
    }
  },
  setup() {
    const { push } = useLink()
    return {
      push,
      HOT_LINE
    }
  }
})
