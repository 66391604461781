
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  useContext,
  useRoute,
  computed,
  onUnmounted,
  watch,
  useMeta,
  inject,
  useStore
} from '@nuxtjs/composition-api'
import depositDialog from '@/components/bid/depositDialog.vue'
import countdown from '@/components/bid/countdown.vue'
import tipDialog from '@/components/spotMall/tipDialog.vue'
import { useExTable } from '@/hooks/useExTable'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { useSyncTime } from '@/hooks/useSyncTime'
import { formatNumber, formatDate } from '@/utils/tool'
import handleBatchDownload from '@/utils/download'
import { bidTypeMap, bidRefreshSecond } from '@/constant/bid'

export default defineComponent({
  components: {
    depositDialog,
    countdown,
    tipDialog
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const store = useStore()
    const $message = inject('$message')
    const router = useRoute()
    const { push } = useLink()
    const auctionNo = computed(() => router.value.params.id)
    const { currentTime } = useSyncTime()
    const state = reactive({
      isSkeleton: true,
      detailInfo: {},
      startMonth: '',
      startDay: '',
      startTime: '',
      intervalTime: null
    })
    useMeta(() => ({
      title: state.detailInfo.auctionName
    }))
    const { userInfo, loginCorpId, bidPackAuth, loginCorpAuth } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
    const columns = ref([
      {
        label: '商品编号',
        align: 'left',
        prop: 'resourceId',
        showOverflowTooltip: true
      },
      {
        label: '品名/材质',
        align: 'left',
        width: '140',
        render: (row) => {
          return (
            <div>
              <div>{row.categoryName}</div>
              <div>{row.material}</div>
            </div>
          )
        }
      },
      {
        label: '产地/规格',
        align: 'left',
        width: '140',
        render: (row) => {
          return (
            <div>
              <div>{row.origin}</div>
              <div>{row.spec}</div>
            </div>
          )
        }
      },
      {
        label: '仓库/质量信息',
        align: 'left',
        width: '140',
        render: (row) => {
          return (
            <div>
              <div>{row.repositoryName}</div>
              <div>{row.qualityInfo}</div>
            </div>
          )
        }
      },
      {
        label: '尺寸信息/计量方式',
        align: 'left',
        width: '140',
        render: (row) => {
          return (
            <div>
              <div>{row.sizeInfo}</div>
              <div>{row.measure}</div>
            </div>
          )
        }
      },
      {
        label: '数量/总重量',
        align: 'left',
        width: '140',
        render: (row) => {
          return (
            <div>
              <div>{formatNumber(row.quantity)}件</div>
              <div>{formatNumber(row.weight, 3)}吨</div>
            </div>
          )
        }
      },
      {
        label: '出库费',
        align: 'left',
        width: '140',
        render: (row) => {
          return <div>{formatNumber(row.outboundCost ?? 0, 2)}元/吨</div>
        }
      },
      {
        label: '备注',
        align: 'left',
        prop: 'remark',
        showOverflowTooltip: true
      }
    ])
    const query = reactive({
      auctionNo: auctionNo.value
    })

    const { data, total, getListAsync, exportListAsync, handleCurrentChange, handleSizeChange } = useExTable({
      query,
      queryApi: context.$api.bid.getPackageBidGoods,
      exportApi: context.$api.bid.exportPackageBidGoods,
      exportName: '商品明细',
      limit: 10
    })

    const handleOffer = async () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!bidPackAuth.value) {
        judgeIsAuth()
        return
      }
      const { statusCode, message } = await context.$api.bid.getValidateBid({ auctionNo: auctionNo.value })
      if (statusCode == 200) {
        push('/buyercenter/pack')
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }
    const handleJoin = async () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!bidPackAuth.value) {
        judgeIsAuth()
        return
      }
      !loginCorpAuth.value && (await store.dispatch('app/GET_CORPAUTH', context))
      if (!loginCorpAuth.value) {
        return showErrorTip('', 7)
      }
      depositRef.value.dialogVisible = true
      depositRef.value.getPayBalance()
    }
    const depositRef = ref(null)
    const tipRef = ref(null)
    const getPackageBidDetail = async () => {
      const { data, statusCode } = await context.$api.bid.getPackageBidDetail({ auctionNo: auctionNo.value })
      if (statusCode == 200) {
        if (data) {
          state.isSkeleton = false
          state.detailInfo = data
          state.startMonth = data.bidStartTime ? formatDate(data.bidStartTime, 'MM') : ''
          state.startDay = data.bidStartTime ? formatDate(data.bidStartTime, 'dd') : ''
          state.startTime = data.bidStartTime ? formatDate(data.bidStartTime, 'hh:mm') : ''
        } else {
          tipRef.value.dialogVisible = true
        }
      }
    }

    const handleDownload = (type) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (type == 'goods') {
        exportListAsync()
      } else {
        const fileData = state.detailInfo.files.map((_) => _.fileUrl)
        const fileDataName = state.detailInfo.files.map((_) => _.fileName)
        handleBatchDownload(fileData, '附件下载', false, fileDataName)
      }
    }

    const setTimer = () => {
      clearTimeout(state.intervalTime)
      state.intervalTime = setTimeout(() => {
        getListAsync()
        getPackageBidDetail()
        setTimer()
      }, 1000 * bidRefreshSecond)
    }

    watch(
      () => currentTime.value,
      (newVal) => {
        const time = newVal / 1000 / 60 / 30
        if (typeof time === 'number' && time % 1 === 0) {
          getPackageBidDetail()
          setTimeout(() => {
            getPackageBidDetail()
          }, 1000 * 3)
        }
      }
    )

    watch(
      () => userInfo.value,
      () => {
        getPackageBidDetail()
      }
    )
    const handleVisiable = (e) => {
      const isHidden = e.target.hidden
      if (isHidden === false) {
        getListAsync()
        getPackageBidDetail()
        setTimer()
      }
    }
    onMounted(() => {
      getPackageBidDetail()
      setTimer()
      document.addEventListener('visibilitychange', handleVisiable)
    })
    onUnmounted(() => {
      clearTimeout(state.intervalTime)
      document.removeEventListener('visibilitychange', handleVisiable)
    })

    return {
      ...toRefs(state),
      query,
      columns,
      data,
      total,
      handleCurrentChange,
      handleSizeChange,
      exportListAsync,
      handleOffer,
      handleJoin,
      depositRef,
      currentTime,
      bidTypeMap,
      getPackageBidDetail,
      userInfo,
      loginCorpId,
      handleDownload,
      push,
      judgeIsLogin,
      tipRef
    }
  },
  head: {
    title: '打包竞价详情-金陵钢宝网'
  }
})
