import { reactive, watch, useRoute, onMounted, computed, useStore, toRefs } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import searchBarItem from '~/components/spotMall/searchBar/searchBarItem.vue'
import searchTag from '~/components/spotMall/searchBar/searchTag.vue'
import searchRange from '~/components/spotMall/searchBar/searchRange.vue'


export default {
  __name: 'searchModel',
  props: {
  condition: {
    type: Object,
    default: () => {}
  },
  isOther: {
    type: Boolean,
    default: true
  }
},
  emits: ['change', 'refresh-search'],
  setup(__props, { expose, emit }) {

const props = __props;


const store = useStore()
const route = useRoute()
const { replace } = useLink()
const state = reactive({
  searchCategoryId: false,
  searchOrigin: false,
  searchMaterial: false,
  searchSpec: false,
  initMaterial: computed(() => store.getters.material),
  initRepoDtos: computed(() => store.getters.condition.repositorySimpleDtos)
})
const getInitSearchForm = () => {
  return {
    variety: '',
    purpose: '',
    categoryId: '',
    material: '',
    quality: '',
    repositoryId: '',
    origin: '',
    deliveryPlace: '',
    spec: '',
    widthStart: '',
    widthEnd: '',
    lengthStart: '',
    lengthEnd: '',
    thicknessStart: '',
    thicknessEnd: '',
    measure: '',
    qualityInfo: '',
    sizeInfo: '',
    specialPrice: '',
    invoiceList: '',
    type: '',
    sortName: '',
    sortType: '',
    repositoryName: '',
    materialLike: '',
    designation: '',
    keyWord: '',
    matchList: '',
    listedPriceStart: '',
    listedPriceEnd: ''
  }
}

const searchForm = reactive(getInitSearchForm())

const searchItems = reactive({
  materialList: computed(() => store.getters.material),
  oiginDescs: computed(() => store.getters.oiginDescs), // 产地
  repositorySimpleDtos: computed(() => store.getters.repoDtos), // 仓库
  measure: computed(() => store.getters.measure),
  qualityInfo: computed(() => store.getters.qualityInfo)
})

const getFilterQualityInfo = (data) => {
  const item = data
  const array = ['正品', '分割正品', '船板', '分割船板']
  return searchForm.purpose ? item.filter((res) => array.includes(res.value)) : item
}

const transParams = (object) => {
  const params = {}
  for (const i in object) {
    if (object[i]) {
      params[i] = object[i]
    }
  }
  return params
}



const changeBarItem = (refresh = false) => {
  const params = transParams(searchForm)
  delete params.orderFields
  params.page = 1
  replace(route.value.path, { ...params })
  emit('change', { ...params })
  if (refresh) {
    emit('refresh-search')
  }
}

const changeMaterial = (data) => {
  searchForm.materialLike = ''
  searchForm.material = data
  changeBarItem(true)
}
const changeRepository = (data) => {
  searchForm.repositoryId = data
  searchForm.repositoryName = ''
  changeBarItem(true)
}

const changeOrigin = () => {
  changeBarItem(true)
}

const filterRepoDtos = () => {
  const deliveryPlace = searchForm.deliveryPlace.split(',')
  const fitRepoDtos = []
  state.initRepoDtos?.forEach((dtos) => {
    if (deliveryPlace.includes(dtos.regionCode)) {
      fitRepoDtos.push(dtos)
    }
  })
  store.commit('spotMall/SET_REPODTOS', fitRepoDtos.length ? fitRepoDtos : state.initRepoDtos)
}

const handleInitRepoDtos = () => {
  store.commit('spotMall/SET_REPODTOS', state.initRepoDtos)
}

const likeSearch = ({ searchKey, value, labelKey }) => {
  searchForm[searchKey] = value
  if (labelKey) searchForm[labelKey] = ''
  changeBarItem(true)
}

watch(
  () => [searchForm.deliveryPlace],
  ([newDeliveryPlace]) => {
    if (newDeliveryPlace) {
      filterRepoDtos()
    } else {
      handleInitRepoDtos()
    }
  }
)

watch(
  () => state.initRepoDtos,
  (newVal) => {
    filterRepoDtos()
  }
)

const hanleSearchMatchList = () => {
  const { matchList = '' } = props.condition
  if (matchList) {
    const _matchList = JSON.parse(matchList)
    const { categoryId = '', origin = '', material = '', spec = '' } = _matchList
    state.searchCategoryId = !!categoryId
    state.searchOrigin = !!origin
    state.searchMaterial = !!material
    state.searchSpec = !!spec
  } else {
    state.searchCategoryId = false
    state.searchOrigin = false
    state.searchMaterial = false
    state.searchSpec = false
  }
}

const changeSpec = (obj) => {
  Object.assign(searchForm, getInitSearchForm(), { ...obj })
  changeBarItem(true)
}

const handleTagChange = (obj) => {
  Object.assign(searchForm, getInitSearchForm(), { ...obj })
  changeBarItem(true)
}
watch(
  () => props.condition,
  (newQuery) => {
    Object.assign(searchForm, getInitSearchForm(), { ...newQuery })
    hanleSearchMatchList()
  },
  { deep: true }
)

onMounted(() => {
  Object.assign(searchForm, getInitSearchForm(), { ...props.condition })
  hanleSearchMatchList()
})

expose({
  ...toRefs(state)
})

return { __sfc: true,props, store, route, replace, state, getInitSearchForm, searchForm, searchItems, getFilterQualityInfo, transParams, emit, changeBarItem, changeMaterial, changeRepository, changeOrigin, filterRepoDtos, handleInitRepoDtos, likeSearch, hanleSearchMatchList, changeSpec, handleTagChange, searchBarItem, searchTag, searchRange }
}

}