
import { defineComponent, ref, watch, nextTick, reactive, toRefs } from '@nuxtjs/composition-api'
export default defineComponent({
  name: '',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    // chrome设置autocomplete="new-password"是生效的
    // 其它浏览器不生效
    const temp = ref('')

    const state = reactive({
      readonly: true,
      passwordType: 'password'
    })

    const showPwd = () => {
      state.passwordType = state.passwordType === 'password' ? '' : 'password'
    }
    const inputChange = (val) => {
      nextTick(() => {
        temp.value = val.replace(/\s/g, '')
        emit('input', temp.value)
      })
    }

    watch(
      () => props.value,
      (newVal) => {
        temp.value = newVal
      }
    )

    return {
      ...toRefs(state),
      showPwd,
      inputChange,
      temp
    }
  }
})
