import loginInfo from '@/components/home/components/loginInfo.vue'
import shortcut from '@/components/home/components/shortcut.vue'
import notice from '@/components/home/components/notice.vue'

export default {
  __name: 'centerRightInfo',
  setup(__props) {


return { __sfc: true,loginInfo, shortcut, notice }
}

}