import { ref, computed, onMounted } from '@nuxtjs/composition-api'

export default {
  __name: 'scrollTool',
  setup(__props) {

const contactList = [
  { name: '范经理', mobile: '13814095862' },
  { name: '易经理', mobile: '15805145505' },
  { name: '曹经理', mobile: '19524294424' }
]
const formatPhone = (phone) => {
  return phone.replace(/^(.{3})(.*)(.{4})$/, '$1\xA0$2\xA0$3')
}
const whitePath = ['/']

const totop = ref(false)
const tobottom = ref(false)
const speed = 100 * 1000
const scrolltotop = (e) => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 400) {
    totop.value = true
  } else {
    totop.value = false
  }
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
  const scrollHeight = document.documentElement.scrollHeight
  const rollheight = scrollHeight - clientHeight
  const top = e.srcElement.scrollingElement.scrollTop // 获取页面滚动高度
  if (rollheight <= top + 400) {
    tobottom.value = false
  } else {
    tobottom.value = true
  }
  if (top == 0) {
    tobottom.value = false
  }
}
const backTOP = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

// 点击回到页面底部
const toBottom = (i) => {
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
  const scrollHeight = document.documentElement.scrollHeight
  const rollheight = scrollHeight - clientHeight // 超出窗口上界的值就是底部的scrolTop的值
  document.documentElement.scrollTop += speed
  let c
  if (document.documentElement.scrollTop + 1 <= rollheight) {
    // 之所以+1，可以打印这两个值的日志就知道了，下面+1也是同理
    c = setTimeout(() => toBottom(i), 10) // 调用setTimeout是为了“回到底部”这过程不是一瞬间
  } else {
    clearTimeout(c)
  }
}

onMounted(() => {
  window.addEventListener('scroll', scrolltotop)
})

return { __sfc: true,contactList, formatPhone, whitePath, totop, tobottom, speed, scrolltotop, backTOP, toBottom }
}

}