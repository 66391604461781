
import { defineComponent, onMounted, reactive, ref, toRefs, watch, watchEffect, useRoute } from '@nuxtjs/composition-api'
import sessionItem from '@/components/bid/sessionItem.vue'
import { useLink } from '@/hooks/useLink'
export default defineComponent({
  components: {
    sessionItem
  },
  props: {
    session: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const route = useRoute()
    const { pushQuery } = useLink()
    const state = reactive({
      swiperOption: {
        slidesPerView: 5,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperBtn: false
    })
    const sessionList = ref([])
    const activeIndex = ref({})
    const changeSession = (data) => {
      activeIndex.value = data
      const { beginTime, endTime } = data
      pushQuery(route.value.path, { ...transParams(route.value.query), bidStartTime: beginTime, bidEndTime: endTime, page: 1 })
      emit('change', { ...transParams(route.value.query), bidStartTime: beginTime, bidEndTime: endTime, page: 1 })
      scrollToCmtList()
    }
    watchEffect(() => {
      sessionList.value = props.session
    })
    watch(
      () => route.value.query,
      (newVal) => {
        const { bidStartTime = '', bidEndTime = '' } = newVal
        activeIndex.value = { beginTime: bidStartTime, endTime: bidEndTime }
      }
    )
    const handleMouse = (status) => {
      if (status == 1 && sessionList.value.length > 5) {
        state.swiperBtn = false
      } else if (status == 0 && sessionList.value.length > 5) {
        state.swiperBtn = true
      }
    }
    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }
    const getAllSession = () => {
      activeIndex.value = {}
      const { bidStartTime = '', bidEndTime = '', page = 1, ...query } = route.value.query
      pushQuery(route.value.path, { ...transParams(query), page: 1 })
      emit('change', { ...transParams(query) })
    }

    const scrollToCmtList = () => {
      const now = window.scrollY
      let dist = document.getElementById('sessionDisplay').offsetTop
      const avalibleHeight = document.documentElement.scrollHeight - window.innerHeight
      if (dist > avalibleHeight) {
        dist = avalibleHeight
      }
      const step = (dist - now) / 10
      setTimeout(() => {
        if (Math.abs(step) <= 1) {
          return window.scrollTo(0, dist)
        }
        window.scrollTo(0, now + step)
        scrollToCmtList()
      }, 10)
    }

    onMounted(() => {
      const { bidStartTime = '', bidEndTime = '' } = route.value.query
      activeIndex.value = { beginTime: bidStartTime, endTime: bidEndTime }
    })
    return {
      ...toRefs(state),
      activeIndex,
      changeSession,
      sessionList,
      handleMouse,
      getAllSession,
      scrollToCmtList
    }
  }
})
