
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    timeList: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {}
  }
})
