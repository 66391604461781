import { computed, onMounted, reactive, toRefs, useRoute, useStore } from '@nuxtjs/composition-api'

export default {
  __name: 'topAdvert',
  setup(__props, { expose }) {

const store = useStore()
const state = reactive({
  timer: null,
  secondCount: 5
})
const topBanners = computed(() => store.getters.topBanners)
const route = useRoute()
const isHomePath = computed(() => route.value.path == '/')
const closeAdvert = () => {
  clearTimeout(state.timer)
  state.secondCount = 0
}
const setTimer = () => {
  state.timer = setTimeout(() => {
    if (state.secondCount > 0) {
      state.secondCount--
      setTimer()
    }
  }, 1000)
}
onMounted(() => {})

expose({
  ...toRefs(state)
})

return { __sfc: true,store, state, topBanners, route, isHomePath, closeAdvert, setTimer }
}

}