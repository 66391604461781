
import { defineComponent, ref, toRefs, reactive, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    obj: {
      type: Object,
      default: () => {}
    },
    goods: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const context = useContext()
    const goodsInfo = reactive({
      performanceObj: {
        flag1: '',
        flag2: '',
        flag3: '',
        flag4: '',
        flag5: '',
        flag6: '',
        flag7: '',
        flag8: '',
        flag9: '',
        flag10: '',
        flag11: '',
        flag12: '',
        flag13: '',
        flag14: '',
        flag15: '',
        flag16: '',
        flag17: '',
        flag18: '',
        flag19: '',
        flag20: '',
        flag21: '',
        flag22: ''
      }
    })
    const chemicalElements = ref([
      { name: 'C', value: 'flag1' },
      { name: 'Si', value: 'flag2' },
      { name: 'Mn', value: 'flag3' },
      { name: 'P', value: 'flag4' },
      { name: 'S', value: 'flag5' },
      { name: 'Cu', value: 'flag6' },
      { name: 'Cr', value: 'flag7' },
      { name: 'Ni', value: 'flag8' },
      { name: 'Nb', value: 'flag9' },
      { name: 'Mo', value: 'flag10' },
      { name: 'Ti', value: 'flag11' },
      { name: 'As', value: 'flag12' },
      { name: 'V', value: 'flag13' }
    ])
    const performances = ref([
      { name: '屈服(MPA)', value: 'flag14' },
      { name: '抗拉(MPA)', value: 'flag15' },
      { name: '伸长(%)', value: 'flag16' }
    ])
    const impacts = ref([
      { name: '单值1', value: 'flag17' },
      { name: '单值2', value: 'flag18' },
      { name: '单值3', value: 'flag19' }
    ])

    const getPerformance = async (row) => {
      const { statusCode, data } = await context.$api.goods.getPerformance({ resourceId: props.goods.resourceId })
      if (statusCode == 200) {
        goodsInfo.performanceObj = { ...data }
      }
    }
    return {
      chemicalElements,
      performances,
      impacts,
      ...toRefs(goodsInfo),
      getPerformance
    }
  }
})
